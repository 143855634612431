<template>
  <ElRadioGroup class="mi-radio-group" v-bind="$props" v-model="value">
    <slot v-if="$slots.default" />
  </ElRadioGroup>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ElRadioGroup, RadioGroupProps } from 'element-plus';

export interface MiRadioGroupProps extends /* @vue-ignore */ Partial<RadioGroupProps> {}

const props = withDefaults(defineProps<MiRadioGroupProps>(), {
  validateEvent: true,
});

const emit = defineEmits<{
  'update:modelValue': [e: MiRadioGroupProps['modelValue']];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val: MiRadioGroupProps['modelValue']) {
    emit('update:modelValue', val);
  },
});
</script>

<style lang="scss" src="./index.scss" />
