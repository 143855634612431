import { computed } from 'vue';

import { SeverityOfReactionEnum, SymptomsOfReactionEnum } from '~shared/api/gen/v2';
import { I18nService } from '~shared/lib';

export const allergyReactionOptions = computed<{ id: SeverityOfReactionEnum; title: string }[]>(
  () => [
    { id: 'mild', title: I18nService.t('Recipes.AllergyReactionOptions.mild') },
    { id: 'moderate', title: I18nService.t('Recipes.AllergyReactionOptions.moderate') },
    { id: 'severe', title: I18nService.t('Recipes.AllergyReactionOptions.severe') },
  ]
);

export const allergySymptomsOptions = computed<{ id: SymptomsOfReactionEnum; title: string }[]>(
  () => [
    { id: 'skin_reaction', title: I18nService.t('Recipes.AllergySymptomsOptions.skin_reaction') },
    {
      id: 'respiratory_symptoms',
      title: I18nService.t('Recipes.AllergySymptomsOptions.respiratory_symptoms'),
    },
    {
      id: 'gastrointestinal_symptoms',
      title: I18nService.t('Recipes.AllergySymptomsOptions.gastrointestinal_symptoms'),
    },
    {
      id: 'cardiovascular_symptoms',
      title: I18nService.t('Recipes.AllergySymptomsOptions.cardiovascular_symptoms'),
    },
    { id: 'anaphylaxis', title: I18nService.t('Recipes.AllergySymptomsOptions.anaphylaxis') },
  ]
);
