import { ElNotification } from 'element-plus';

import { QueryParams } from '~shared/types';
import { apiService, MetaDto } from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';

const urlPath = 'appointments';

export async function getSourceList(query: QueryParams) {
  try {
    const response = await apiService.api.instance.get<{
      data: { id: number; name: string }[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}/source`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
}
