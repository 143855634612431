import { ElNotification } from 'element-plus';
import { AxiosError, GenericAbortSignal } from 'axios';

import {
  GetDepositListByUserResponseData,
  GetDepositListResponseData,
  InvoiceFullDto,
} from '~shared/api';
import { getApiErrorMessage, joinQueryParams, findCurrentEnv, useAbortable } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'g2g/deposits';

export const exportDepositsDataURL = findCurrentEnv('API_URL') + `${urlPath}/export`;

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  try {
    const response = await apiService.api.instance.get<GetDepositListResponseData>(
      joinQueryParams({
        url: `${urlPath}/list`,
        query,
      }),
      {
        signal,
      }
    );
    return {
      ...response.data,
    };
  } catch (err) {
    const axiosError: AxiosError = err as AxiosError;

    if (axiosError.code === 'ERR_CANCELED') return;
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const changeAmount = async (userId: number | string, amount: number) => {
  try {
    const response = await apiService.api.instance.post<{ data: InvoiceFullDto }>(
      `${urlPath}/${userId}/change`,
      {
        amount,
      }
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getListByUserId = async (id: string | number) => {
  try {
    const response = await apiService.api.instance.get<GetDepositListByUserResponseData>(
      `${urlPath}/details/${id}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const abortableGetList = useAbortable(getList);
