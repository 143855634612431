import { UserRoleEnum } from '~shared/api';

export const rolesForBIRedirect: UserRoleEnum[] = [
  'district-health-department',
  'city-health-department',
  'ministry-health-department',
];

export const rolesForFundRedirect: UserRoleEnum[] = [
  'insurance-fund-administrator',
  'insurance-fund-employee',
];
