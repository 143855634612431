import {
  ROUTE_PATH,
  TEMPLATES_PATH_PREFIX,
  USERS_PATH_PREFIX,
  ACCOUNTING_PATH_PREFIX,
  WAREHOUSE_PATH_PREFIX,
  PATIENTS_PATH_PREFIX,
  ANALYTICS_PATH_PREFIX,
  APPOINTMENTS_PATH_PREFIX,
  PATRONAGE_PATH_PREFIX,
  CASH_REGISTER_PATH_PREFIX,
  REIMBURSMENT_PATH_PREFIX,
} from '~shared/config';
import { SidebarMenuItem, GetSidebarMenuItemsParams } from '~shared/types';
import { joinQueryParams, I18nService } from '~shared/lib';

export const getB2gNavbarItems = (params: GetSidebarMenuItemsParams): SidebarMenuItem[] => {
  const items: SidebarMenuItem[] = [
    {
      roles: ['director', 'doctor', 'manager', 'nurse'],
      hidden: !params.isCKB,
      routePath: joinQueryParams({
        url: ROUTE_PATH.emergencyRoom.list,
        query: {
          status: 'approved',
        },
      }),
      icon: 'HOSPITAL',
      title: I18nService.t('Hospitalization.EmergencyRoom'),
      key: ROUTE_PATH.emergencyRoom.list,
    },
    {
      roles: ['director', 'doctor', 'minister'],
      icon: 'LINE_CHART',
      title: I18nService.t('Analytics.Analytics'),
      prefix: ANALYTICS_PATH_PREFIX,
      key: ANALYTICS_PATH_PREFIX,
      submenu: [
        {
          roles: ['director', 'doctor'],
          routePath: ROUTE_PATH.analyticByAppointments,
          title: I18nService.t('Appointments.Appointments'),
          key: ROUTE_PATH.analyticByAppointments,
        },
        {
          roles: ['director', 'doctor'],
          routePath: ROUTE_PATH.attachedPopulation,
          title: I18nService.t('Analytics.AttachedPopulation'),
          key: ROUTE_PATH.attachedPopulation,
        },
        // TODO для оптимизации нагрузки
        // {
        //   roles: ['director', 'doctor'],
        //   routePath: ROUTE_PATH.analyticBySickness,
        //   title: I18nService.t('Analytics.SicknessRate'),
        //   key: ROUTE_PATH.analyticBySickness,
        // },
        {
          roles: ['director'],
          routePath: ROUTE_PATH.directions,
          title: I18nService.t('Analytics.Directions'),
          key: ROUTE_PATH.directions,
        },
        // TODO скрыть страницы аналитики, выпилить или оставить пока не понятно;

        //   {
        //     roles: ['director'],
        //     routePath: ROUTE_PATH.healthGroup.mainList,
        //     title: I18nService.t('Analytics.HealthGroup'),
        //     key: ROUTE_PATH.healthGroup.mainList,
        //   },
        //   {
        //     roles: ['doctor'],
        //     hidden: params.userRole === 'doctor' && !params.microdistrictId,
        //     routePath: joinQueryParams({
        //       url: ROUTE_PATH.healthGroup.codeList,
        //       query: { microdistrict_id: params.microdistrictId },
        //     }),
        //     title: I18nService.t('Analytics.HealthGroup'),
        //     key: ROUTE_PATH.healthGroup.codeList,
        //   },
        //   {
        //     roles: ['director'],
        //     routePath: ROUTE_PATH.dispensarization.mainList,
        //     title: I18nService.t('Analytics.Dispensarization'),
        //     key: ROUTE_PATH.dispensarization.mainList,
        //   },
        //   {
        //     roles: ['doctor'],
        //     hidden: params.userRole === 'doctor' && !params.microdistrictId,
        //     routePath: joinQueryParams({
        //       url: ROUTE_PATH.dispensarization.codeList,
        //       query: { microdistrict_id: params.microdistrictId },
        //     }),
        //     title: I18nService.t('Analytics.Dispensarization'),
        //     key: ROUTE_PATH.dispensarization.codeList,
        //   },
        //   {
        //     roles: ['director'],
        //     routePath: ROUTE_PATH.pregnancy.mainList,
        //     title: I18nService.t('Analytics.Pregnants'),
        //     key: ROUTE_PATH.pregnancy.mainList,
        //   },
        //   {
        //     roles: ['doctor'],
        //     hidden: params.userRole === 'doctor' && !params.microdistrictId,
        //     routePath: joinQueryParams({
        //       url: ROUTE_PATH.pregnancy.microdistrics,
        //       query: { microdistrict_id: params.microdistrictId, is_pregnancy: 1 },
        //     }),
        //     title: I18nService.t('Analytics.Pregnants'),
        //     key: ROUTE_PATH.pregnancy.mainList,
        //   },
        //   {
        //     roles: ['director', 'doctor'],
        //     hidden: params.userRole === 'doctor' && !params.microdistrictId,
        //     routePath: ROUTE_PATH.vaccination.mainList,
        //     title: I18nService.t('Analytics.Vaccination'),
        //     key: ROUTE_PATH.vaccination.mainList,
        //   },
        //   {
        //     roles: ['director', 'doctor'],
        //     routePath: ROUTE_PATH.demography.mainList,
        //     title: I18nService.t('Analytics.Demography'),
        //     key: ROUTE_PATH.demography.mainList,
        //   },
        //   {
        //     roles: ['director', 'doctor'],
        //     hidden: params.userRole === 'doctor' && !params.microdistrictId,
        //     routePath: ROUTE_PATH.disabledPeople.mainList,
        //     title: I18nService.t('Analytics.DisabledPeople'),
        //     key: ROUTE_PATH.disabledPeople.mainList,
        //   },
        //   {
        //     roles: ['minister'],
        //     routePath: ROUTE_PATH.womenDashboard,
        //     title: I18nService.t('Analytics.Women'),
        //     key: ROUTE_PATH.womenDashboard,
        //   },
        //
        //   {
        //     roles: ['minister', 'director', 'doctor'],
        //     routePath: ROUTE_PATH.fertility,
        //     title: I18nService.t('Analytics.Fertility'),
        //     key: ROUTE_PATH.fertility,
        //   },
        //   {
        //     routePath: ROUTE_PATH.survey.mainList,
        //     title: I18nService.t('Analytics.Survey'),
        //     key: ROUTE_PATH.survey.mainList,
        //   },
      ],
    },
    {
      roles: ['doctor'],
      routePath: ROUTE_PATH.reportsOnForms,
      icon: 'DOCUMENT',
      title: I18nService.t('Common.Documents'),
      key: ROUTE_PATH.reportsOnForms,
    },
    {
      roles: ['director', 'manager', 'doctor', 'nurse'],
      routePath: PATIENTS_PATH_PREFIX,
      icon: 'FORM',
      title: I18nService.t('Common.Patients'),
      key: PATIENTS_PATH_PREFIX,
    },
    {
      roles: ['director', 'doctor'],
      routePath: ROUTE_PATH.hospitalization.list,
      icon: 'AMBULANCE',
      title: I18nService.t('Hospitalization.Hospitalization'),
      key: ROUTE_PATH.hospitalization.list,
    },
    {
      roles: ['director', 'manager', 'accountant'],
      hidden: !params.isCashRegister,
      icon: 'STRONGBOX',
      title: I18nService.t('CashRegister.CashRegister'),
      prefix: CASH_REGISTER_PATH_PREFIX,
      key: CASH_REGISTER_PATH_PREFIX,
      submenu: [
        {
          roles: ['director', 'manager', 'accountant'],
          routePath: ROUTE_PATH.cashRegister.invoices,
          title: I18nService.t('CashRegister.Invoices'),
          key: ROUTE_PATH.cashRegister.invoices,
        },
        {
          roles: ['director', 'manager', 'accountant'],
          routePath: ROUTE_PATH.cashRegister.depositList,
          title: I18nService.t('CashRegister.Deposit'),
          key: ROUTE_PATH.cashRegister.depositList,
        },
      ],
    },
    {
      roles: ['director', 'doctor', 'nurse', 'manager'],
      routePath: ROUTE_PATH.inpatientCare.list,
      icon: 'HOSPITAL_BED',
      title: I18nService.t('InpatientCare.InpatientCare'),
      key: ROUTE_PATH.inpatientCare.list,
    },
    {
      roles: ['director'],
      icon: 'GROUP',
      title: I18nService.t('User.Users'),
      prefix: USERS_PATH_PREFIX,
      key: USERS_PATH_PREFIX,
      submenu: [
        {
          roles: ['director'],
          routePath: ROUTE_PATH.users.staffList,
          title: I18nService.t('User.Employees'),
          key: ROUTE_PATH.users.staffList,
        },
        {
          roles: ['director'],
          routePath: ROUTE_PATH.users.employeeDoctorList,
          title: I18nService.t('Common.Doctors'),
          key: ROUTE_PATH.users.employeeDoctorList,
        },
      ],
    },
    {
      roles: ['doctor', 'nurse'],
      hidden: !params.isPatronage,
      icon: 'STETHOSCOPE',
      title: I18nService.t('Analytics.Patronage'),
      prefix: PATRONAGE_PATH_PREFIX,
      key: PATRONAGE_PATH_PREFIX,
      submenu: [
        {
          routePath: ROUTE_PATH.patronages.planning,
          title: I18nService.t('Analytics.Planning'),
          key: ROUTE_PATH.patronages.planning,
        },
        {
          routePath: ROUTE_PATH.patronages.list,
          title: I18nService.t('Analytics.Planned'),
          key: ROUTE_PATH.patronages.list,
        },
      ],
    },
    {
      roles: ['director', 'manager', 'doctor', 'nurse'],
      icon: 'FORM_V2',
      title: I18nService.t('Appointments.Appointments'),
      key: APPOINTMENTS_PATH_PREFIX,
      prefix: APPOINTMENTS_PATH_PREFIX,
      submenu: [
        {
          roles: ['director', 'manager', 'doctor', 'nurse'],
          routePath: ROUTE_PATH.appointments.activeList,
          title: I18nService.t('Appointments.ActiveAppointments'),
          key: ROUTE_PATH.appointments.activeList,
        },
        {
          roles: ['director', 'doctor', 'nurse'],
          routePath: ROUTE_PATH.appointments.providedList,
          title: I18nService.t('Appointments.Provided'),
          key: ROUTE_PATH.appointments.providedList,
        },
        {
          roles: ['director', 'manager', 'doctor', 'nurse'],
          routePath: ROUTE_PATH.appointments.canceledList,
          title: I18nService.t('Appointments.Cancelled'),
          key: ROUTE_PATH.appointments.canceledList,
        },
      ],
    },
    {
      roles: ['manager', 'doctor'],
      routePath: ROUTE_PATH.appointmentSchedule,
      icon: 'SQUARE_MATRIX_4',
      title: I18nService.t('Appointments.ReceptionCalendar'),
      key: ROUTE_PATH.appointmentSchedule,
    },
    {
      roles: ['doctor'],
      icon: 'TEMPLATE',
      title: I18nService.t('Templates.Templates'),
      prefix: TEMPLATES_PATH_PREFIX,
      key: TEMPLATES_PATH_PREFIX,
      submenu: [
        {
          routePath: ROUTE_PATH.templates.checkups,
          title: I18nService.t('Templates.CheckupTemplates'),
          key: ROUTE_PATH.templates.checkups,
        },
        {
          routePath: ROUTE_PATH.templates.checkupHeaderTemplateTable,
          title: I18nService.t('Templates.CheckupHeaders'),
          key: ROUTE_PATH.templates.checkupHeaderTemplateTable,
        },
      ],
    },
    {
      roles: ['doctor'],
      hidden: params.doctorId === undefined,
      icon: 'DONUT_CHART',
      routePath: params.doctorId
        ? ROUTE_PATH.users.employeeDoctorProfile.byId(params.doctorId)
        : undefined,
      title: I18nService.t('Common.Profile'),
      key: ROUTE_PATH.users.employeeDoctorProfile.rawPath,
    },
    {
      roles: ['director', 'manager', 'laboratory'],
      routePath: ROUTE_PATH.laboratory.orders,
      icon: 'SYRINGE',
      title: I18nService.t('Common.Orders'),
      key: ROUTE_PATH.laboratory.orders,
    },
    {
      roles: ['laboratory'],
      routePath: ROUTE_PATH.analysisList,
      icon: 'LIST_BOX_OUTLINE',
      title: I18nService.t('Laboratory.Analysis.Analyzes'),
      key: ROUTE_PATH.analysisList,
    },
    {
      roles: ['laboratory'],
      routePath: ROUTE_PATH.laboratory.referencesGroups,
      icon: 'LIST_BOX_OUTLINE',
      title: I18nService.t('Laboratory.ReferencesGroups'),
      key: ROUTE_PATH.laboratory.referencesGroups,
    },
    {
      roles: ['accountant', 'director'],
      icon: 'BRIEFCASE',
      hidden: !params.isCashRegister,
      title: I18nService.t('Accounting.Accounting'),
      prefix: ACCOUNTING_PATH_PREFIX,
      key: ACCOUNTING_PATH_PREFIX,
      submenu: [
        {
          routePath: ROUTE_PATH.accounting.outcomes,
          title: I18nService.t('Outcome.Expenses'),
          key: ROUTE_PATH.accounting.outcomes,
        },
        {
          routePath: ROUTE_PATH.accounting.services,
          title: I18nService.t('Common.Services'),
          key: ROUTE_PATH.accounting.services,
        },
        {
          routePath: ROUTE_PATH.accounting.refSource,
          title: I18nService.t('Bookkeeping.ReferralSources'),
          key: ROUTE_PATH.accounting.refSource,
        },
        {
          routePath: ROUTE_PATH.accounting.refAccrual,
          title: I18nService.t('Bookkeeping.ReferralAccruals'),
          key: ROUTE_PATH.accounting.refAccrual,
        },
      ],
    },
    {
      roles: ['director', 'warehouse', 'laboratory'],
      icon: 'STOCK',
      title: I18nService.t('Warehouse.Warehouse'),
      prefix: WAREHOUSE_PATH_PREFIX,
      key: WAREHOUSE_PATH_PREFIX,
      submenu: [
        {
          roles: ['director', 'warehouse'],
          routePath: ROUTE_PATH.warehouse.nomenclature,
          title: I18nService.t('Nomenclature.Nomenclature'),
          key: ROUTE_PATH.warehouse.nomenclature,
        },
        {
          roles: ['director', 'warehouse'],
          routePath: ROUTE_PATH.warehouse.warehouses,
          title: I18nService.t('Warehouse.Warehouses'),
          key: ROUTE_PATH.warehouse.warehouses,
        },
        {
          roles: ['director', 'warehouse'],
          routePath: ROUTE_PATH.warehouse.suppliers,
          title: I18nService.t('Supplier.Suppliers'),
          key: ROUTE_PATH.warehouse.suppliers,
        },
        {
          roles: ['director', 'warehouse'],
          routePath: ROUTE_PATH.warehouse.incomes,
          title: I18nService.t('Income.Incomes'),
          key: ROUTE_PATH.warehouse.incomes,
        },
        {
          roles: ['director', 'warehouse'],
          routePath: ROUTE_PATH.warehouse.outcomes,
          title: I18nService.t('Outcome.Expenses'),
          key: ROUTE_PATH.warehouse.outcomes,
        },
        {
          roles: ['director', 'laboratory', 'warehouse'],
          routePath: ROUTE_PATH.warehouse.goodsStock,
          title: I18nService.t('Product.GoodsStock'),
          key: ROUTE_PATH.warehouse.goodsStock,
        },
      ],
    },
    {
      roles: ['pharmacist'],
      routePath: ROUTE_PATH.pharmacy,
      icon: 'PHARMACY_V2',
      title: I18nService.t('MedicalCard.Recipes'),
      key: ROUTE_PATH.pharmacy,
    },
    {
      roles: ['doctor'],
      routePath: ROUTE_PATH.recipes,
      icon: 'PILL',
      title: I18nService.t('Recipes.Recipes'),
      key: ROUTE_PATH.recipes,
    },
    {
      roles: ['prescription-reviewer', 'director'],
      icon: 'PILL',
      title: I18nService.t('Recipes.Reimbursement'),
      prefix: REIMBURSMENT_PATH_PREFIX,
      key: REIMBURSMENT_PATH_PREFIX,
      submenu: [
        {
          roles: ['prescription-reviewer', 'director'],
          routePath: ROUTE_PATH.reimbursement.recipeList,
          title: I18nService.t('Recipes.Recipes'),
          key: ROUTE_PATH.reimbursement.recipeList,
        },
      ],
    },
  ];

  return items.filter((item) => {
    if (Array.isArray(item.submenu)) {
      item.submenu = item.submenu.filter(
        (subItem) =>
          (!subItem?.roles || subItem.roles?.includes(params.userRole)) && !subItem.hidden
      );
    }
    return item.roles?.includes(params.userRole) && !item.hidden;
  });
};
