import { ElNotification } from 'element-plus';

import { apiService } from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { ReceiverResource, SalaryDetailResource, SalaryResource } from '~shared/api/common';
import { QueryParams } from '~shared/types';

const urlPath = '/salaries';

export const paidSalary = async (salaryId: number) => {
  try {
    const response = await apiService.api.instance.put<{
      data: SalaryResource & {
        receiver: ReceiverResource;
      };
    }>(`${urlPath}/${salaryId}/paid`);

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getDetailsForSalary = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.get<{
      data: SalaryDetailResource[];
    }>(
      joinQueryParams({
        url: `${urlPath}/details`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getSalary = async (salaryId: number) => {
  try {
    const response = await apiService.api.instance.get<{
      data: SalaryResource & {
        receiver: ReceiverResource;
        salary_details: SalaryDetailResource[];
      };
    }>(`${urlPath}/${salaryId}`);

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createSalary = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.post<{
      data: SalaryResource & {
        receiver: ReceiverResource;
        salary_details: SalaryDetailResource[];
      };
    }>(`${urlPath}/create`, query);

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
