import { rolesForBIRedirect } from '~entities/session';
import { ROUTE_PATH } from '~shared/config';
import { I18nService, onlyUserHasRoleMiddleware } from '~shared/lib';

export const REDIRECT_TO_BI_ROUTE = {
  name: 'RedirectToBI',
  path: ROUTE_PATH.redirectToBI,
  component: () => import('./ui'),
  beforeEnter: [onlyUserHasRoleMiddleware(rolesForBIRedirect)],
  meta: {
    title: I18nService.t('Common.ViewStatistics'),
  },
};
