<template>
  <div class="layout-user-dropdown">
    <div class="user-info">
      <MiTypography weight="bold">{{ formatFullnameToShort(user?.name) }}</MiTypography>
      <MiTypography class="user-info__clinic" color="secondary">{{
        user?.clinic?.title
      }}</MiTypography>
    </div>
    <ElDropdown trigger="click" max-height="700">
      <div class="user-avatar-box">
        <MenuItemRoleIcon :role="user?.role" />
      </div>
      <template #dropdown>
        <ElDropdownMenu>
          <ElDropdownItem v-if="user?.email" disabled>
            <template #default>
              <MiInfoField label="Email">
                <div class="user-info__email">{{ user?.email }}</div>
              </MiInfoField>
            </template>
          </ElDropdownItem>

          <ElDropdownItem
            v-for="(item, idx) in availableRoles"
            :divided="idx === 0"
            :disabled="item.id === user?.id"
            @click="handleAvailableRoleClick(item.id)"
            :key="item.id">
            <template #default>
              <div class="user-dropdown-item">
                <MenuItemRoleIcon :role="item.role" />
                <span class="role-info">
                  <MiTypography>{{ $t(`User.Roles.${item.role}`) }}</MiTypography>
                  <MiTypography class="role-info__clinic" size="small" color="secondary">
                    {{ item.clinic }}
                  </MiTypography>
                </span>
                <MiIcon v-if="item.id === user?.id" icon="CHECK_MARK" />
              </div>
            </template>
          </ElDropdownItem>

          <ElDropdownItem divided @click="handleLogout">
            <template #default>
              <div class="user-dropdown-item">
                <MiIcon icon="LOGOUT" />
                <span>{{ $t('Common.Logout') }}</span>
              </div>
            </template>
          </ElDropdownItem>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import {
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElNotification,
  ElLoading,
} from 'element-plus';

import { MenuItemRoleIcon } from './MenuItemRoleIcon';

import { useAppConfigStore, useSessionStore } from '~entities/session';
import { I18nService, formatFullnameToShort, getApiErrorMessage } from '~shared/lib';
import { MiIcon, MiInfoField, MiTypography } from '~shared/ui';
import { ROUTE_PATH } from '~shared/config';
import { UserFullDto } from '~shared/api';

const router = useRouter();

const sessionStore = useSessionStore();
const appConfigStore = useAppConfigStore();
const { user, availableRoles } = storeToRefs(sessionStore);

const handleAvailableRoleClick = async (id: number) => {
  const loading = ElLoading.service({
    lock: true,
    text: I18nService.t('Common.Loading'),
    background: 'rgba(0, 0, 0, 0.7)',
  });

  try {
    const response = await sessionStore.loginAsRoleJwt({ id });
    await appConfigStore.getFeatureFlags();

    if (!appConfigStore.featureFlags) {
      sessionStore.logout();
      throw new Error('Get feature flags error');
    }

    const userRole = (response.data?.user as UserFullDto | undefined)?.role;

    if (userRole) {
      if (sessionStore.checkRolesForRedirectToBI(userRole)) {
        sessionStore.redirectToBI();
      }
      if (sessionStore.checkRolesForRedirectToFund(userRole)) {
        sessionStore.redirectToFund();
      }
    }

    await router.push(ROUTE_PATH.root);
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  } finally {
    loading.close();
  }
};

const handleLogout = async () => {
  await sessionStore.logout();
  await router.push(ROUTE_PATH.auth.login);
};
</script>

<style lang="scss" scoped>
.layout-user-dropdown {
  display: flex;
  gap: 12px;
  align-items: center;

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: end;

    &__clinic {
      max-width: 210px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .user-avatar-box {
    padding: 2px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
  }

  .user-avatar-box:focus,
  .user-avatar-box:hover {
    border-color: var(--primary-color);
    background-color: var(--primary-color-light-9);
  }
}

.user-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.role-info {
  &__clinic {
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.user-info__email {
  text-wrap: wrap;
  line-height: 24px;
  max-width: 260px;
  color: var(--text-secondary-color);
  font-size: var(--text-font-size-small);
}
</style>
