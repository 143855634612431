import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const ACCOUNTING_REFERRAL_SOURCE_LIST_ROUTE = {
  name: 'AccountingReferralSources',
  path: ROUTE_PATH.accounting.refSource,
  component: () => import('./ReferralSourceList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'accountant', 'manager']),
  ],
  meta: {
    title: 'Bookkeeping.ReferralSources',
  },
};

export const ACCOUNTING_REFERRAL_ACCRUAL_LIST_ROUTE = {
  name: 'AccountingReferralAccrual',
  path: ROUTE_PATH.accounting.refAccrual,
  component: () => import('./ReferralAccrualList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'accountant', 'manager']),
  ],
  meta: {
    title: 'Bookkeeping.ReferralAccruals',
  },
};

export const REFERRAL_COMMISSION_LIST_ROUTE = {
  name: 'ReferralCommission',
  path: ROUTE_PATH.accounting.refCommission.rawPath,
  component: () => import('./ReferralCommissionList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'accountant', 'manager']),
  ],
  meta: {
    title: 'Bookkeeping.ReferralSources',
  },
};
