import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { getDocumentTypeName } from '../lib';

import { DeathReporter, misApi, PatientDocumentType, PatientFullDto } from '~shared/api';
import {
  BloodGroupEnum,
  EducationTypeEnum,
  FamilyStatusEnum,
  RhFactorEnum,
  SeverityOfReactionEnum,
  SymptomsOfReactionEnum,
} from '~shared/api/gen/v2';
import { DrugTradeNameResource, INNResource } from '~shared/api/gen/prescriptions';

export type OptionsContingentCategory = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type PatientProfileCrudFormValues = Partial<PatientFullDto> & {
  last_name?: string;
  first_name?: string;
  middle_name?: string;
  document_type_id?: PatientDocumentType;
  organization_id?: number;
  organization_position_id?: number;
  organization_type_id?: number;
  working_place?: string;
  city_id?: number;
  district_id?: number;
  registration_city_id?: number;
  registration_district_id?: number;
  doctor_id?: number;
  microdistrict_id?: number;
  avatar_id?: number;
  file_ids?: number[];
  death_reported?: DeathReporter;
  optionsCategory: OptionsContingentCategory;
  blood_type?: BloodGroupEnum;
  rh_factor?: RhFactorEnum;
  family_status?: FamilyStatusEnum;
  education_type?: EducationTypeEnum;
  allergies?: {
    inn_id: number;
    drug_trade_name_id: number;
    inn: INNResource;
    drug_trade_name: DrugTradeNameResource;
    severity_of_reaction: SeverityOfReactionEnum;
    symptoms_of_reaction: SymptomsOfReactionEnum[];
    _inn: unknown;
    _tradename: unknown;
    _severity_of_reaction_obj: unknown;
    _symptoms_of_reaction_obj: unknown;
  }[];
};

export const usePatientProfileStore = defineStore('patientProfile', () => {
  const crudFormInitialValues = ref<PatientProfileCrudFormValues | null | undefined>(null);
  const currentPatient = ref<PatientFullDto | null | undefined>(null);

  const loading = ref(false);
  const crudModalVisible = ref(false);

  const editMode = computed(() => !!crudFormInitialValues.value?.id);
  const patientDeathInfo = computed(
    (): {
      date: string;
      reason: string;
      age?: number | null;
      death_reported: DeathReporter;
    } | null =>
      currentPatient.value?.died_at
        ? {
            date: currentPatient.value.died_at,
            age: currentPatient.value.age,
            death_reported: currentPatient.value.death_reported,
            reason: currentPatient.value.disease_codes.map((el) => el.title).join(', '),
          }
        : null
  );

  function setCrudModalVisible(value: boolean) {
    crudModalVisible.value = value;
  }

  function setCurrentPatient(payload: PatientFullDto | null | undefined) {
    currentPatient.value = payload;
  }

  function setCrudFormInitialValues(payload: Partial<PatientFullDto> = {}) {
    const [surname, name, ...patronymicParts] =
      payload.name?.split(' ').filter((f: string) => !!f) ?? [];

    const formValues = {
      ...payload,
      last_name: surname,
      first_name: name,
      middle_name: patronymicParts.join(' '),
      document_type_id: getDocumentTypeName(payload),
      organization_id: payload.organization?.id,
      organization_position_id: payload.organization_position?.id,
      organization_type_id: payload.organization_type?.id,
      city_id: payload.city?.id,
      district_id: payload.district?.id,
      registration_city_id: payload.registration_city?.id,
      registration_district_id: payload.registration_district?.id,
      doctor_id: payload.doctor?.id,
      microdistrict_id: payload.microdistrict?.id,
      price_list_id: payload.price_list?.id,
      avatar_id: payload.avatar?.id,
      working_place: payload.working_place,
      file_ids: payload.files?.map(({ id }) => id),
      death_reported: payload.death_reported,
      optionsCategory: payload.category ?? (0 as OptionsContingentCategory),
    };
    crudFormInitialValues.value = formValues;
  }

  function resetFormData() {
    crudFormInitialValues.value = null;
  }

  async function getPatientById(id: PatientFullDto['id']) {
    loading.value = true;

    const response = await misApi.g2g.patients.getById(id);
    setCurrentPatient(response?.data);

    loading.value = false;
  }

  return {
    editMode,
    currentPatient,
    patientDeathInfo,
    loading,
    crudModalVisible,
    crudFormInitialValues,
    setCrudModalVisible,
    setCurrentPatient,
    setCrudFormInitialValues,
    resetFormData,
    getPatientById,
  };
});
