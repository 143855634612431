import { ElNotification } from 'element-plus';

import { QueryParams } from '~shared/types';
import { apiService, MetaDto } from '~shared/api';
import {
  CommissionResource,
  ReceiverResource,
  ReferrerListResource,
  ReferrerResponseType,
  SalaryResource,
} from '~shared/api/common';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';

const urlPath = '/referrers';

export const getReferralSalaries = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{
      data: (SalaryResource & {
        receiver: ReceiverResource;
      })[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}/salaries`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getReferrers = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{
      data: ReferrerListResource[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: `${urlPath}/`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const createReferrer = async (query: QueryParams) => {
  try {
    const response = await apiService.api.instance.post<{
      data: ReferrerResponseType;
    }>(
      joinQueryParams({
        url: `${urlPath}/create`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const updateReferrer = async (referrerId: number, query: QueryParams) => {
  try {
    const response = await apiService.api.instance.put<{
      data: ReferrerResponseType;
    }>(`${urlPath}/${referrerId}`, query);

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getReferrer = async (referrerId: number, query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{
      data: ReferrerResponseType;
    }>(
      joinQueryParams({
        url: `${urlPath}/${referrerId}`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const deleteReferrer = async (referrerId: number) => {
  try {
    const response = await apiService.api.instance.delete<{
      data: ReferrerResponseType;
    }>(`${urlPath}/${referrerId}`);

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getReferrersCommissions = async (referrerId: number, query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<{
      data: CommissionResource[];
      meta: MetaDto;
      amount_sum: number;
    }>(
      joinQueryParams({
        url: `${urlPath}/${referrerId}/commissions`,
        query,
      })
    );

    return response;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
