export const TEMPLATES_PATH_PREFIX = '/templates';
export const APPOINTMENTS_PATH_PREFIX = '/appointments';
export const INPATIENT_CARE_PATH_PREFIX = '/inpatient-care';
export const CASH_REGISTER_PATH_PREFIX = '/cash-register';
export const ACCOUNTING_PATH_PREFIX = '/accounting';
export const USERS_PATH_PREFIX = '/users';
export const WAREHOUSE_PATH_PREFIX = '/warehouse';
export const DISPENSARIZATION_PATH_PREFIX = '/dispensarization';
export const DISABLED_PEOPLE_PATH_PREFIX = '/disabled_people';
export const SETTINGS_PATH_PREFIX = '/settings';
export const CONTINGENT_PATH_PREFIX = '/contingent';
export const AUTH_PATH_PREFIX = '/auth';
export const TREATMENT_COURSE_PATH_PREFIX = '/treatment-course';

export const PATIENTS_PATH_PREFIX = '/patients';
export const ANALYTICS_PATH_PREFIX = '/analytics';
export const HEALTH_GROUP_PATH_PREFIX = '/health_group';
export const PREGNANCY_PATH_PREFIX = '/pregnancy';
export const VACCINATION_PATH_PREFIX = '/vaccination';
export const DEMOGRAPHY_PATH_PREFIX = '/demography';
export const PATRONAGE_PATH_PREFIX = '/patronages';
export const SCREENING_PATH_PREFIX = '/screenings';
export const REPORTS_ON_FORMS = '/reports-on-forms';
export const REIMBURSMENT_PATH_PREFIX = '/reimbursement';

export const ROUTE_PATH = {
  root: '/',
  iconList: '/icon-list',
  notFound: '/404',
  noAccess: '/403',
  redirect: '/redirect-page',
  redirectToBI: '/redirect-to-BI',
  oauth: '/oauth',
  contingent: {
    active: `${CONTINGENT_PATH_PREFIX}/active`,
    archived: `${CONTINGENT_PATH_PREFIX}/archived`,
  },
  patronages: {
    list: PATRONAGE_PATH_PREFIX,
    planning: `${PATRONAGE_PATH_PREFIX}/planning`,
    patronage: {
      rawPath: `${PATRONAGE_PATH_PREFIX}/:id`,
      byId: (id: number | string) => `${PATRONAGE_PATH_PREFIX}/${id}`,
    },
  },
  appointmentSchedule: '/appointment-schedule',
  paidPatients: '/paid-patients',
  hospitalization: {
    list: '/hospitalization-list',
    referral: '/hospitalization-referral',
    referralMedicalCase: '/hospitalization-referral-medical-case',
    editReferral: {
      rawPath: '/edit-hospitalization-referral/:id',
      byId: (id: number | string) => `/edit-hospitalization-referral/${id}`,
    },
    viewingReferral: {
      rawPath: '/viewing-hospitalization-referral/:id',
      byId: (id: number | string) => `/viewing-hospitalization-referral/${id}`,
    },
  },
  dashboard: '/dashboard',
  directorDashboard: '/director-dashboard',
  auth: {
    login: `${AUTH_PATH_PREFIX}/login`,
    roleSelection: `${AUTH_PATH_PREFIX}/role-selection`,
    forgotPassword: `${AUTH_PATH_PREFIX}/forgot-password`,
    waitingConfirm: `${AUTH_PATH_PREFIX}/waiting-confirm`,
  },
  cashRegister: {
    invoices: `${CASH_REGISTER_PATH_PREFIX}/invoices`,
    depositList: `${CASH_REGISTER_PATH_PREFIX}/deposits`,
  },
  accounting: {
    outcomes: `${ACCOUNTING_PATH_PREFIX}/outcomes`,
    services: `${ACCOUNTING_PATH_PREFIX}/services`,
    refSource: `${ACCOUNTING_PATH_PREFIX}/referral-source`,
    refAccrual: `${ACCOUNTING_PATH_PREFIX}/referral-accrual`,
    refCommission: {
      rawPath: `${ACCOUNTING_PATH_PREFIX}/referral-commission/:id`,
      byId: (id: number | string) => `${ACCOUNTING_PATH_PREFIX}/referral-commission/${id}`,
    },
  },
  users: {
    employeeDoctorList: `${USERS_PATH_PREFIX}/employee-doctor-list`,
    employeeDoctorProfile: {
      rawPath: `${USERS_PATH_PREFIX}/employee-doctor-list/:id`,
      byId: (id: number | string) => `${USERS_PATH_PREFIX}/employee-doctor-list/${id}`,
    },
    staffList: `${USERS_PATH_PREFIX}/staff-list`,
    employeeProfile: {
      rawPath: `${USERS_PATH_PREFIX}/staff-list/:id`,
      byId: (id: number | string) => `${USERS_PATH_PREFIX}/staff-list/${id}`,
    },
  },
  patients: {
    g2gProfiles: {
      rawPath: '/g2g-patients/profiles/:id',
      byId: (id: number | string) => `/g2g-patients/profiles/${id}`,
    },
    b2gProfiles: {
      rawPath: '/b2g-patients/profiles/:id',
      byId: (id: number | string) => `/b2g-patients/profiles/${id}`,
    },
    ambulatoryCards: {
      rawPath: '/patients/:id/ambulatory-card',
      byId: (id: number | string) => `/patients/${id}/ambulatory-card`,
    },
    medicalCard: {
      rawPath: '/patients/:id/medical-card',
      byId: (id: number | string) => `/patients/${id}/medical-card`,
    },
    checkup: {
      rawPath: '/patients/:id/checkup',
      byId: (id: number | string) => `/patients/${id}/checkup`,
    },
    list: PATIENTS_PATH_PREFIX,
  },
  appointments: {
    activeList: `${APPOINTMENTS_PATH_PREFIX}/active`,
    providedList: `${APPOINTMENTS_PATH_PREFIX}/provided`,
    canceledList: `${APPOINTMENTS_PATH_PREFIX}/canceled`,
    appointment: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/:id`,
      byId: (id: number | string) => `${APPOINTMENTS_PATH_PREFIX}/${id}`,
    },
    medicalCase: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/medical-case/:medicalCaseId/:mode`,
      byId: (medicalCaseId: number | string, mode: 'view' | 'edit') =>
        `${APPOINTMENTS_PATH_PREFIX}/medical-case/${medicalCaseId}/${mode}`,
    },
    unscheduledMedicalCase: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/unscheduled-medical-case/:medicalCaseId/:mode`,
      byId: (medicalCaseId: number | string, mode: 'view' | 'edit') =>
        `${APPOINTMENTS_PATH_PREFIX}/unscheduled-medical-case/${medicalCaseId}/${mode}`,
    },
    create: `${APPOINTMENTS_PATH_PREFIX}/create`,
    examinationPlan: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/examination-plan/:appointmentId/:medicalCaseId/:patientId`,
      byId: (
        appointmentId: number | string,
        medicalCaseId: number | string,
        patientId: number | string
      ) =>
        `${APPOINTMENTS_PATH_PREFIX}/examination-plan/${appointmentId}/${medicalCaseId}/${patientId}`,
    },
    writeOutRecipe: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/write-out-recipe/:patientId`,
      byId: (patientId: number) => `${APPOINTMENTS_PATH_PREFIX}/write-out-recipe/${patientId}`,
    },
  },
  inpatientCare: {
    list: INPATIENT_CARE_PATH_PREFIX,
  },
  statistic: {
    dashboard: '/statistic/dashboard',
  },
  laboratory: {
    orders: '/laboratory/orders',
    referencesGroups: '/laboratory/references-groups',
  },
  analysisList: '/analysis-list',
  warehouse: {
    nomenclature: `${WAREHOUSE_PATH_PREFIX}/nomenclature`,
    warehouses: `${WAREHOUSE_PATH_PREFIX}/warehouses`,
    goodsStock: `${WAREHOUSE_PATH_PREFIX}/goods-stock`,
    suppliers: `${WAREHOUSE_PATH_PREFIX}/suppliers`,
    incomes: `${WAREHOUSE_PATH_PREFIX}/incomes`,
    outcomes: `${WAREHOUSE_PATH_PREFIX}/outcomes`,
  },
  templates: {
    checkups: `${TEMPLATES_PATH_PREFIX}/checkups`,
    checkupEditor: {
      rawPath: `${TEMPLATES_PATH_PREFIX}/checkups/:id`,
      byId: (id: number | string) => `${TEMPLATES_PATH_PREFIX}/checkups/${id}`,
    },
    checkupHeaderTemplateTable: `${TEMPLATES_PATH_PREFIX}/checkups-header-table`,
    checkupHeaderTemplate: {
      rawPath: `${TEMPLATES_PATH_PREFIX}/checkup-header`,
      byId: (id: number | string) => `${TEMPLATES_PATH_PREFIX}/checkup-header/${id}`,
    },
  },
  dispensarization: {
    mainList: `${ANALYTICS_PATH_PREFIX}${DISPENSARIZATION_PATH_PREFIX}/main-list`,
    patientList: `${ANALYTICS_PATH_PREFIX}${DISPENSARIZATION_PATH_PREFIX}/patient-list`,
    deregisteredPatientList: `${ANALYTICS_PATH_PREFIX}${DISPENSARIZATION_PATH_PREFIX}/deregistered-patient-list`,
    newPatientList: `${ANALYTICS_PATH_PREFIX}${DISPENSARIZATION_PATH_PREFIX}/new-patient-list`,
    codeList: `${ANALYTICS_PATH_PREFIX}${DISPENSARIZATION_PATH_PREFIX}/code-list`,
  },
  pharmacy: '/pharmacy',
  recipes: `/recipes`,
  disabledPeople: {
    mainList: `${ANALYTICS_PATH_PREFIX}${DISABLED_PEOPLE_PATH_PREFIX}`,
    codeList: {
      rawPath: `${ANALYTICS_PATH_PREFIX}${DISABLED_PEOPLE_PATH_PREFIX}/:microdistrict_id`,
      byId: (id: number | string) => `${ANALYTICS_PATH_PREFIX}${DISABLED_PEOPLE_PATH_PREFIX}/${id}`,
    },
    changeList: `${ANALYTICS_PATH_PREFIX}${DISABLED_PEOPLE_PATH_PREFIX}/change`,
  },
  womenDashboard: `${ANALYTICS_PATH_PREFIX}/women-dashboard`,
  survey: {
    mainList: `${ANALYTICS_PATH_PREFIX}/survey`,
    codeList: {
      rawPath: `${ANALYTICS_PATH_PREFIX}/survey/:id`,
      byId: (id: number | string) => `${ANALYTICS_PATH_PREFIX}/survey/${id}`,
    },
    patient: {
      rawPath: `${ANALYTICS_PATH_PREFIX}/survey/:id/:patientId`,
      byId: (id: number | string, patientId: number | string) =>
        `${ANALYTICS_PATH_PREFIX}/survey/${id}/${patientId}`,
    },
  },
  analyticByAppointments: `${ANALYTICS_PATH_PREFIX}/appointments`,
  analyticBySickness: `${ANALYTICS_PATH_PREFIX}/sickness`,
  attachedPopulation: `${ANALYTICS_PATH_PREFIX}/attached-population`,
  fertility: `${ANALYTICS_PATH_PREFIX}/fertility`,
  directions: `${ANALYTICS_PATH_PREFIX}/directions`,
  settings: {
    main: SETTINGS_PATH_PREFIX,
    organizationsTypes: `${SETTINGS_PATH_PREFIX}/organizations-types`,
    organizations: `${SETTINGS_PATH_PREFIX}/organizations`,
    positions: `${SETTINGS_PATH_PREFIX}/positions`,
  },
  healthGroup: {
    mainList: `${ANALYTICS_PATH_PREFIX}${HEALTH_GROUP_PATH_PREFIX}/main-list`,
    patientList: `${ANALYTICS_PATH_PREFIX}${HEALTH_GROUP_PATH_PREFIX}/patient-list`,
    codeList: `${ANALYTICS_PATH_PREFIX}${HEALTH_GROUP_PATH_PREFIX}/code-list`,
  },
  pregnancy: {
    mainList: `${ANALYTICS_PATH_PREFIX}${PREGNANCY_PATH_PREFIX}/main-list`,
    microdistrics: `${ANALYTICS_PATH_PREFIX}${PREGNANCY_PATH_PREFIX}/microdistrics`,
    groups: `${ANALYTICS_PATH_PREFIX}${PREGNANCY_PATH_PREFIX}/groups`,
  },
  vaccination: {
    mainList: `${ANALYTICS_PATH_PREFIX}${VACCINATION_PATH_PREFIX}/main-list`,
    microdistrictList: `${ANALYTICS_PATH_PREFIX}${VACCINATION_PATH_PREFIX}/vaccine-by-microdistricts`,
    groupList: `${ANALYTICS_PATH_PREFIX}${VACCINATION_PATH_PREFIX}/vaccine-by-groups`,
  },
  demography: {
    mainList: `${ANALYTICS_PATH_PREFIX}${DEMOGRAPHY_PATH_PREFIX}/main-list`,
  },
  documents: '/documents',
  treatmentCourse: {
    rawPath: `${TREATMENT_COURSE_PATH_PREFIX}/:patientId/:courseId`,
    byId: (patientId: number | string, courseId: number | string) =>
      `${TREATMENT_COURSE_PATH_PREFIX}/${patientId}/${courseId}`,
  },
  emergencyRoom: {
    list: `/emergency-room-list`,
    referral: {
      raw: '/emergency-room-referral/:id',
      byId: (id: string | number) => `/emergency-room-referral/${id}`,
    },
    patientCheckup: {
      raw: '/emergency-room-patient-checkup/:id',
      byId: (id: string | number) => `/emergency-room-patient-checkup/${id}`,
    },
    patientCheckupEdit: {
      raw: '/emergency-room-patient-checkup-edit/:id/:patientId/:currentMedicalCaseId/:emergencyRoomId',
      byId: (
        id: string | number,
        patientId: string | number,
        currentMedicalCaseId: string | number,
        emergencyRoomId: string | number
      ) =>
        `/emergency-room-patient-checkup-edit/${id}/${patientId}/${currentMedicalCaseId}/${emergencyRoomId}`,
    },
    withoutReferral: '/emergency-room-without-referral',
    form003: {
      raw: '/emergency-room-form-003/:id',
      byId: (id: string | number) => `/emergency-room-form-003/${id}`,
    },
    form066: {
      raw: '/emergency-room-patient-checkup/:id/form-066',
      byId: (id: string | number) => `/emergency-room-patient-checkup/${id}/form-066`,
    },
    examinationPlan: {
      raw: '/emergency-room-examination-plan/:medicalCaseId/:patientId/:hospitalizationId',
      byId: (
        medicalCaseId: string | number,
        patientId: string | number,
        hospitalizationId: string | number
      ) => `/emergency-room-examination-plan/${medicalCaseId}/${patientId}/${hospitalizationId}`,
    },
    surgeryEdit: {
      raw: '/emergency-room-surgery-edit/:id?',
      byId: (id?: string | number) => `/emergency-room-surgery-edit/${id}`,
    },
    birthProtocol: {
      raw: '/emergency-room-birth-protocol',
    },
  },
  editEpicrisis: {
    raw: '/epicrisis/:id',
    byId: (id: string | number) => `/epicrisis/${id}`,
  },
  createEpicrisis: '/epicrisis',
  screening: {
    CVD: {
      rawPath: `${SCREENING_PATH_PREFIX}/cvd/:patientId/`,
      byId: (patientId: number | string) => `${SCREENING_PATH_PREFIX}/cvd/${patientId}/`,
    },
    endocrine: {
      rawPath: `${SCREENING_PATH_PREFIX}/endocrine/:patientId/`,
      byId: (patientId: number | string) => `${SCREENING_PATH_PREFIX}/endocrine/${patientId}/`,
    },
  },
  reportsOnForms: REPORTS_ON_FORMS,
  reimbursement: {
    recipeList: `${REIMBURSMENT_PATH_PREFIX}/recipe-list`,
  },
};

export const ROUTE_NAMES = {
  patientMedicalCard: 'PatientMedicalCard',
  appointment: 'Appointment',
};
