<template>
  <component
    :is="currentNode"
    :class="[
      'mi-typography',
      {
        'mi-typography--heading-1': type === 'heading-1',
        'mi-typography--heading-2': type === 'heading-2',
        'mi-typography--heading-3': type === 'heading-3',
        'mi-typography--paragraph': type === 'paragraph',
        'mi-typography--text': type === 'text',

        'mi-typography--disable-color': color === 'disable',
        'mi-typography--placeholder-color': color === 'placeholder',
        'mi-typography--primary-color': color === 'primary',
        'mi-typography--purple-color': color === 'purple',
        'mi-typography--regular-color': color === 'regular',
        'mi-typography--secondary-color': color === 'secondary',
        'mi-typography--danger-color': color === 'danger',

        'mi-typography--text-align-left': textAlign === 'left',
        'mi-typography--text-align-center': textAlign === 'center',
        'mi-typography--text-align-justify': textAlign === 'justify',
        'mi-typography--text-align-right': textAlign === 'right',

        'mi-typography--font-weight-bold': weight === 'bold',
        'mi-typography--font-weight-medium': weight === 'medium',
        'mi-typography--font-size-small': size === 'small',
        'mi-typography--font-size-large': size === 'large',
      },
    ]">
    <slot></slot>
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

type Props = {
  type?: 'heading-1' | 'heading-2' | 'heading-3' | 'paragraph' | 'text';
  size?: 'small' | 'large';
  weight?: 'medium' | 'bold';
  color?: 'primary' | 'regular' | 'secondary' | 'placeholder' | 'disable' | 'purple' | 'danger';
  textAlign?: 'center' | 'justify' | 'left' | 'right';
};

const props = withDefaults(defineProps<Props>(), {
  type: 'paragraph',
  color: 'primary',
  textAlign: 'left',
});

const currentNode = computed(() => {
  switch (props.type) {
    case 'heading-1':
      return 'h1';
    case 'heading-2':
      return 'h2';
    case 'heading-3':
      return 'h3';
    case 'text':
      return 'span';
    default:
      return 'p';
  }
});
</script>

<style lang="scss">
.mi-typography {
  font-family: 'Inter', sans-serif;
}

.mi-typography--text-align-right {
  text-align: right;
}
.mi-typography--text-align-justify {
  text-align: justify;
}
.mi-typography--text-align-left {
  text-align: left;
}
.mi-typography--text-align-center {
  text-align: center;
}

.mi-typography--disable-color {
  color: var(--text-disable-color);
}
.mi-typography--placeholder-color {
  color: var(--text-placeholder-color);
}
.mi-typography--primary-color {
  color: var(--text-primary-color);
}
.mi-typography--purple-color {
  color: var(--text-purple-color);
}
.mi-typography--regular-color {
  color: var(--text-regular-color);
}
.mi-typography--secondary-color {
  color: var(--text-secondary-color);
}
.mi-typography--danger-color {
  color: var(--el-color-danger);
}

.mi-typography--heading-1 {
  font-size: var(--text-font-size-heading-1);
  font-weight: var(--text-weight-medium);
  line-height: var(--text-line-height-heading-1);

  padding-bottom: 16px;
}

.mi-typography--heading-2 {
  font-size: var(--text-font-size-heading-2);
  font-weight: var(--text-weight-medium);
  line-height: var(--text-line-height-heading-2);

  padding-bottom: 16px;
}

.mi-typography--heading-3 {
  font-size: var(--text-font-size-heading-3);
  font-weight: var(--text-weight-medium);
  line-height: var(--text-line-height-heading-3);

  padding-bottom: 16px;
}

.mi-typography--paragraph {
  font-size: var(--text-font-size-base);
  font-weight: var(--text-weight-regular);
  line-height: var(--text-line-height-base);
}

.mi-typography--text {
  font-size: var(--text-font-size-base);
  font-weight: var(--text-weight-regular);
  line-height: var(--text-line-height-base);
}

.mi-typography--font-weight-bold {
  font-weight: var(--text-weight-bold);
}

.mi-typography--font-weight-medium {
  font-weight: var(--text-weight-medium);
}

.mi-typography--font-size-small.mi-typography--paragraph,
.mi-typography--font-size-small.mi-typography--text {
  font-size: var(--text-font-size-extra-small);
  line-height: var(--text-line-height-extra-small);
}

.mi-typography--font-size-large.mi-typography--paragraph,
.mi-typography--font-size-large.mi-typography--text {
  font-size: var(--text-font-size-large);
  line-height: var(--text-line-height-large);
}
</style>
