/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type MotherChildBlockEnum =
  | 'gynecologist_examinations'
  | 'previous_pregnancies'
  | 'family_sexual_history'
  | 'first_pregnancy_examinations'
  | 'first_gynecological_examinations'
  | 'pregnancy_progress'
  | 'pregnancy_laboratory_analyses';

/** Как закончилась беременность (Аборт/Рождение ребенка) */
export type MotherChildPregnancyOutcomeEnum = 'abort' | 'birth';

/** Рождение ребенка (Доношенный/Недоношенный) */
export type MotherChildChildBirthStatusEnum = 'preterm' | 'term';

/** Как родился ребенок (Живорожденный/Мертворождение-Антенатальная смерть/Мертворождение-интранатальная смерть) */
export type MotherChildChildBirthConditionEnum =
  | 'live_birth'
  | 'stillbirth_antenatal'
  | 'stillbirth_intranatal';

/** Виды родов (Роды через естественные родовые пути/Акушерско-хирургическая практика) */
export type MotherChildBirthTypeEnum = 'vaginal_delivery' | 'surgical_delivery';

/** Пол (Женский/Мужской) */
export type MotherChildNewbornSexEnum = 'male' | 'female';

/** Тип контрацепции (Хирургическая/Нехирургическая/Не пользуется контрацепцией) */
export type MotherChildContraceptionTypeEnum = 'surgical' | 'non_surgical' | 'none';

/** Состояние груди (Без патологий/Патологии присутствуют) */
export type MotherChildBreastConditionEnum = 'no_pathology' | 'pathology_present';

/** Показатели сосудистой системы беременной (Норма/Патология) */
export type MotherChildVascularSystemStatusEnum = 'normal' | 'pathology';

/** Предлежание плода (Головное/Ягодичное/Не определено) */
export type MotherChildFetalPresentationEnum = 'cephalic' | 'breech' | 'undetermined';

/** Общее состояние беременной (Удовлетворительное/Неудовлетворительное) */
export type MotherChildGeneralConditionEnum = 'satisfactory' | 'unsatisfactory';

/** Уровень риска (Низкий/Умеренный/Высокий) */
export type MotherChildRiskLevelEnum = 'low' | 'moderate' | 'high';

/** Неделя беременности (16, 24, 30, etc) */
export type MotherChildPregnancyTargetWeekEnum = '16' | '24' | '30' | '34' | '36' | '38';

/** Разновидность контрацепции */
export type MotherChildContraceptionVarietyEnum =
  | 'natural'
  | 'barrier'
  | 'chemical'
  | 'intrauterine'
  | 'hormonal'
  | 'emergency'
  | 'female_sterilization'
  | 'vasectomy';

/** Вредные привычки */
export type MotherChildBadHabitsEnum =
  | 'drug_addiction'
  | 'alcoholism'
  | 'smoking'
  | 'poor_diet'
  | 'sedentary_lifestyle'
  | 'gambling_addiction'
  | 'internet_addiction'
  | 'uncontrolled_self_medication'
  | 'promiscuous_sexual_life'
  | 'criminal_dependency';

/** Социально-биологические факторы */
export type MotherChildParentalRiskFactorEnum =
  | 'maternal_age_under_18_or_over_35'
  | 'paternal_age_over_40'
  | 'professional_hazards_of_parents'
  | 'smoking_alcoholism_drug_addiction'
  | 'mass_height_indicators_height_less_153cm'
  | 'mass_height_indicators_weight_25_percent_above_or_below_normal';

/** Акушерско-гинекологический анамнез */
export type MotherChildObstetricGynecologicalHistoryEnum =
  | 'number_of_births_4_or_more'
  | 'multiple_or_complicated_abortions'
  | 'surgical_interventions_on_uterus_or_appendages'
  | 'uterine_malformations'
  | 'infertility'
  | 'pregnancy_loss'
  | 'non_developing_pregnancy'
  | 'premature_birth'
  | 'stillbirth'
  | 'neonatal_death'
  | 'birth_of_children_with_genetic_diseases_or_developmental_anomalies'
  | 'birth_of_children_with_low_or_high_birth_weight'
  | 'complicated_previous_pregnancy'
  | 'bacterial_viral_gynecological_diseases';

/** Экстрагенитальные заболевания */
export type MotherChildExtragenitalDiseaseEnum =
  | 'urinary_system_diseases'
  | 'endocrinopathy'
  | 'blood_diseases'
  | 'liver_diseases'
  | 'lung_diseases'
  | 'connective_tissue_diseases'
  | 'acute_and_chronic_infections'
  | 'hemostasis_disorders'
  | 'alcoholism_drug_addiction';

/** Осложнения беременности */
export type MotherChildPregnancyComplicationEnum =
  | 'vomiting_during_pregnancy'
  | 'threat_of_pregnancy_termination'
  | 'bleeding_in_first_and_second_half_of_pregnancy'
  | 'gestosis'
  | 'polyhydramnios'
  | 'oligohydramnios'
  | 'multiple_pregnancy'
  | 'placental_insufficiency'
  | 'anemia'
  | 'rh_and_abo_isoimmunization'
  | 'exacerbation_of_viral_infection'
  | 'anatomically_narrow_pelvis'
  | 'incorrect_fetal_position'
  | 'prolonged_pregnancy'
  | 'induced_pregnancy';

export type MotherChildCreateOrUpdateGynecologistExaminationRequest = {
  diagnosis: string;
  pregnancy_complications?: string | null;
} & MotherChildGynecologistExaminationResource;

export interface MotherChildGynecologistExaminationResource {
  /**
   * Дата взятия на учет
   * @format date
   */
  registration_date: string;
  /**
   * Дата снятия с учета
   * @format date
   */
  deregistration_date?: string | null;
  deregistration_reason?: MotherChildContraceptionVarietyEnum;
  /** Женщина поступила на учет из числа наблюдавшихся другим учреждением (Да/Нет) */
  referred_from_other_institution: boolean;
  /**
   * Наименование учреждения
   * @maxLength 255
   */
  referring_institution?: string | null;
  /** Гинекологические операции (Да/Нет) */
  gynecological_surgeries: boolean;
  /**
   * Дата гинекологических операций
   * @format date
   */
  gynecological_surgeries_date?: string | null;
  /** Хирургические операции (Да/Нет) */
  surgical_operations: boolean;
  /**
   * Дата хирургических операций
   * @format date
   */
  surgical_operations_date?: string | null;
  /** Гемотрансфузии (Да/Нет) */
  blood_transfusions: boolean;
  /**
   * Дата гемотрансфузий
   * @format date
   */
  blood_transfusions_date?: string | null;
  /** Получала ли АРВ (антиретровирусную) терапию (Да/Нет) */
  arv_therapy: boolean;
  /**
   * Дата получения АРВ терапии
   * @format date
   */
  arv_therapy_date?: string | null;
}

export interface MotherChildPreviousPregnancyResource {
  /** Осложнения после родов (Отсутствуют/Присутствуют) */
  complications_after_birth: boolean;
  /**
   * Какая была беременность по счету
   * @format int32
   */
  pregnancy_count?: number | null;
  /** Как закончилась беременность (Аборт/Рождение ребенка) */
  pregnancy_outcome: MotherChildPregnancyOutcomeEnum;
  /** Рождение ребенка (Доношенный/Недоношенный) */
  child_birth_status: MotherChildChildBirthStatusEnum;
  /**
   * Медицинская организация
   * @maxLength 255
   */
  medical_organization?: string | null;
  /**
   * Срок беременности (недель)
   * @format int32
   */
  pregnancy_weeks?: number | null;
  /**
   * Количество новорожденных
   * @format int32
   */
  number_of_newborns?: number | null;
  /** Как родился ребенок (Живорожденный/Мертворождение-Антенатальная смерть/Мертворождение-интранатальная смерть) */
  child_birth_condition: MotherChildChildBirthConditionEnum;
  /** Виды родов (Роды через естественные родовые пути/Акушерско-хирургическая практика) */
  birth_type: MotherChildBirthTypeEnum;
  /**
   * Дата родов
   * @format date
   */
  birth_date?: string | null;
  /**
   * Дата поступления в больницу
   * @format date
   */
  hospital_admission_date?: string | null;
  /**
   * Срок беременности (недель) при родах
   * @format int32
   */
  birth_weeks?: number | null;
  /** Пол (Женский/Мужской) */
  newborn_sex: MotherChildNewbornSexEnum;
  /**
   * Масса тела (г)
   * @format int32
   */
  newborn_weight?: number | null;
  /**
   * Рост (см)
   * @format int32
   */
  newborn_height?: number | null;
  /** Сейчас живой (Да/Нет) */
  newborn_alive: boolean;
  /** Примечание */
  newborn_notes?: string | null;
  /** Осложнения у плода (Отсутствуют/Присутствуют) */
  newborn_complications: boolean;
  /** Осложнения */
  complications_details?: string | null;
}

export type MotherChildCreateOrUpdatePreviousPregnancyRequest = {
  complications?: string | null;
} & MotherChildPreviousPregnancyResource;

export interface MotherChildFamilySexualHistoryResource {
  /**
   * Менструации с (лет)
   * @format int32
   */
  menstruation_since?: number | null;
  /**
   * Дата последней менструации
   * @format date
   */
  last_menstruation_date: string;
  /**
   * Половая жизнь с (лет)
   * @format int32
   */
  sexual_activity_since?: number | null;
  /**
   * Период применения контрацепции (начало)
   * @format date
   */
  contraception_period_start?: string | null;
  /**
   * Период применения контрацепции (конец)
   * @format date
   */
  contraception_period_end?: string | null;
  contraception_type?: MotherChildContraceptionTypeEnum;
  /**
   * Разновидность контрацепции
   * @maxLength 255
   */
  contraception_details?: string | null;
  /** Вредные привычки супруга (партнера) (Есть/Нет) */
  partner_bad_habits: boolean;
  patient_bad_habits?: MotherChildBadHabitsEnum[];
  partner_blood_group?: BloodGroupEnum;
  partner_rhesus_factor?: RhFactorEnum;
  /**
   * Номер телефона супруга (партнера)
   * @maxLength 12
   */
  partner_phone_number?: string | null;
  /**
   * Ф.И.О супруга (партнера)
   * @maxLength 255
   */
  partner_full_name?: string | null;
  /**
   * Место работы супруга (партнера)
   * @maxLength 255
   */
  partner_workplace?: string | null;
}

export type MotherChildCreateOrUpdateFamilySexualHistoryRequest = {
  gynecological_diseases?: string | null;
} & MotherChildFamilySexualHistoryResource;

export interface MotherChildFirstPregnancyExaminationResource {
  /**
   * Первое УЗИ
   * @format date
   */
  first_ultrasound?: string | null;
  /**
   * Рост (см)
   * @format int32
   */
  height?: number | null;
  /**
   * Вес (кг)
   * @format int32
   */
  weight?: number | null;
  /** Особенности строения тела */
  body_features?: string | null;
  breast_condition?: MotherChildBreastConditionEnum;
  vascular_system_status?: MotherChildVascularSystemStatusEnum | null;
  /**
   * Артериальное давление в правой руке
   * @maxLength 50
   */
  blood_pressure_right_arm?: string | null;
  /**
   * Артериальное давление в левой руке
   * @maxLength 50
   */
  blood_pressure_left_arm?: string | null;
  fetal_presentation?: MotherChildFetalPresentationEnum;
  /**
   * Обхват таза (см)
   * @format int32
   */
  pelvic_circumference?: number | null;
  /**
   * Высота дна матки (см)
   * @format int32
   */
  uterine_fundus_height?: number | null;
  /**
   * Обхват живота (см)
   * @format int32
   */
  abdominal_circumference?: number | null;
  /**
   * Частота сердечных сокращений плода (ударов в минуту)
   * @format int32
   */
  fetal_heart_rate?: number | null;
  /**
   * Первое шевеление плода
   * @format date
   */
  first_fetal_movement?: string | null;
  files?: FileResource[];
}

export type MotherChildCreateOrUpdateFirstPregnancyExaminationRequest =
  MotherChildFirstPregnancyExaminationResource;

export interface MotherChildFirstGynecologicalExaminationResource {
  /** Наружные половые органы (Норма/Патология) */
  external_genitalia_status?: MotherChildVascularSystemStatusEnum;
  /** Примечание (для наружных половых органов) */
  external_genitalia_notes?: string | null;
  /** Влагалище (Норма/Патология) */
  vagina_status?: MotherChildVascularSystemStatusEnum;
  /** Примечание (для влагалища) */
  vagina_notes?: string | null;
  /** Шейка матки (Норма/Патология) */
  cervix_status?: MotherChildVascularSystemStatusEnum;
  /** Примечание (для шейки матки) */
  cervix_notes?: string | null;
  /** Тело матки (Норма/Патология) */
  uterus_status?: MotherChildVascularSystemStatusEnum;
  /** Примечание (для тела матки) */
  uterus_notes?: string | null;
  /** Яичник, трубы (Норма/Патология) */
  ovaries_tubes_status?: MotherChildVascularSystemStatusEnum;
  /** Примечание (для яичника и труб) */
  ovaries_tubes_notes?: string | null;
  /**
   * Особые характеристики (Нет/Да)
   * @default false
   */
  special_characteristics: boolean;
  /** Примечание (для особых характеристик) */
  special_characteristics_notes?: string | null;
}

export type MotherChildCreateOrUpdateFirstGynecologicalExaminationRequest = {
  diagnosis_icd10?: string | null;
} & MotherChildFirstGynecologicalExaminationResource;

export interface MotherChildPregnancyProgressResource {
  /** Неделя беременности (16, 24, 30, etc) */
  pregnancy_target_week: MotherChildPregnancyTargetWeekEnum;
  /**
   * Жалобы беременной (Нет/Есть)
   * @default false
   */
  complaints: boolean;
  /** Примечание (для жалоб) */
  complaints_notes?: string | null;
  general_condition?: MotherChildGeneralConditionEnum;
  /**
   * Вес беременной (кг)
   * @format int32
   */
  weight?: number | null;
  /**
   * Артериальное давление в правой руке
   * @maxLength 50
   */
  blood_pressure_right?: string | null;
  /**
   * Артериальное давление в левой руке
   * @maxLength 50
   */
  blood_pressure_left?: string | null;
  fetal_presentation?: MotherChildFetalPresentationEnum;
  /**
   * Пульс
   * @format int32
   */
  heart_rate?: number | null;
  /**
   * Высота дна матки (см)
   * @format int32
   */
  uterine_fundus_height?: number | null;
  /**
   * Обхват живота (см)
   * @format int32
   */
  abdominal_circumference?: number | null;
  /**
   * Шевеление плода (Нет/Да)
   * @default false
   */
  fetal_movements: boolean;
  /**
   * Частота сердечных сокращений плода (ударов в минуту)
   * @format int32
   */
  fetal_heart_rate?: number | null;
  /**
   * Количество белка в моче беременной
   * @maxLength 50
   */
  protein_in_urine?: string | null;
  /**
   * Уровень гемоглобина в крови
   * @maxLength 50
   */
  hemoglobin_level?: string | null;
  /**
   * Присутствуют ли патологические изменения (Нет/Да)
   * @default false
   */
  pathological_changes: boolean;
  /** Показания, советы врача */
  doctor_recommendations?: string | null;
  /**
   * Была ли госпитализация (Нет/Да)
   * @default false
   */
  hospitalization: boolean;
  /** Даты госпитализации */
  hospitalization_date_from?: string | null;
  /** Даты госпитализации */
  hospitalization_date_to?: string | null;
  /**
   * Название ЛПУ
   * @maxLength 255
   */
  medical_facility_name?: string | null;
  /**
   * Был ли введен анатоксин стафилококка (Нет/Да)
   * @default false
   */
  staphylococcal_anatoxin_administered?: boolean;
  /** Примечание (для анатоксин стафилококка) */
  staphylococcal_anatoxin_notes?: string | null;
  /**
   * Дата следующего визита
   * @format date
   */
  next_visit_date?: string | null;
  /**
   * Анализ мочи (Нет/Да)
   * @default false
   */
  urine_analysis: boolean;
  /**
   * АЛТ (Нет/Да)
   * @default false
   */
  alt: boolean;
  /**
   * Общий анализ крови (Нет/Да)
   * @default false
   */
  blood_analysis: boolean;
  /**
   * Мазок на микрофлору (Нет/Да)
   * @default false
   */
  microflora_swab: boolean;
  /**
   * Скрининг плода на врожденные пороки развития (Нет/Да)
   * @default false
   */
  congenital_malformations_screening: boolean;
  /**
   * Патология плода (Нет/Да)
   * @default false
   */
  congenital_malformations_pathology: boolean;
  /**
   * УЗИ (Нет/Да)
   * @default false
   */
  ultrasound: boolean;
  /**
   * Допплерография (Нет/Да)
   * @default false
   */
  dopplerography: boolean;
  /**
   * Проведение физической культуры
   * @format date
   */
  physical_culture?: string | null;
  /**
   * Срок беременности (недель) для физической культуры
   * @format int32
   */
  physical_culture_pregnancy_weeks?: number | null;
  /**
   * Проведение облучения ультрафиолетовыми лучами
   * @format date
   */
  uv_radiation?: string | null;
  /**
   * Срок беременности (недель) для облучения
   * @format int32
   */
  uv_radiation_pregnancy_weeks?: number | null;
  /**
   * Проведение школы матери
   * @format date
   */
  mother_school?: string | null;
  /**
   * Срок беременности (недель) для школы матери
   * @format int32
   */
  mother_school_pregnancy_weeks?: number | null;
  /**
   * Проведение психопрофилактической подготовки
   * @format date
   */
  psychological_preparation?: string | null;
  /**
   * Срок беременности (недель) для психопрофилактической подготовки
   * @format int32
   */
  psychological_preparation_pregnancy_weeks?: number | null;
  socio_biological_factors?: MotherChildParentalRiskFactorEnum[];
  extragenital_diseases?: MotherChildExtragenitalDiseaseEnum[];
  risk_level?: MotherChildRiskLevelEnum;
  obstetric_gynecological_history?: MotherChildObstetricGynecologicalHistoryEnum[];
  pregnancy_complications?: MotherChildPregnancyComplicationEnum[];
  /** Оценка состояния плода */
  fetal_condition_assessment?: string | null;
  functional_diagnostics_files?: FileResource[] | null;
}

export type MotherChildCreateOrUpdatePregnancyProgressRequest = {
  icd10_diagnosis?: string | null;
  functional_icd10_diagnosis?: string | null;
} & MotherChildPregnancyProgressResource;

export interface FeatureFlagsResponse {
  'user.appointmentModalFlow': FeatureFlagsFlowClinicEnum;
  'user.interface': FeatureFlagsFlowClinicEnum;
  'clinic.treatmentAppointmentHasCustomPrice'?: boolean;
  'clinic.treatmentCourseHasInvoice'?: boolean;
  'clinic.myIDIntegration'?: boolean;
  'clinic.createHospital'?: boolean;
  'clinic.cashRegister'?: boolean;
  'clinic.patronage'?: boolean;
  'user.aIAssistant'?: boolean;
  'clinic.negativeDeposit'?: boolean;
  'clinic.serviceCategory'?: boolean;
}

export type FeatureFlagsFlowClinicEnum = 'g2g' | 'b2g';

export interface CloudStudyResource {
  url: string;
}

export interface SpecialtiesWithAppointmentsCountResource {
  id: number;
  title: string;
  /** @min 0 */
  doctors_count: number;
  /** @min 0 */
  total_appointments_count: number;
  /** @min 0 */
  prepare_appointments_count: number;
  /** @min 0 */
  waiting_prepare_appointments_count: number;
  /** @min 0 */
  percent_prepare_appointments: number;
}

export interface DoctorWithCountAppointmentsResource {
  id: number;
  name: string;
  /** @min 0 */
  total_count: number;
  /** @min 0 */
  prepare_count: number;
}

export interface UserCreatedAppointmentCountResource {
  id: number;
  surname: string | null;
  name: string;
  patronymic: string | null;
  /** @min 0 */
  created_appointments_count: number;
}

export interface ReferencesValueResource {
  id: number;
  start_value: string | null;
  end_value: string | null;
  norm_text: string | null;
}

export interface ReferencesGroupResource {
  id: number;
  title: string;
  gender: PatientGenderEnum;
  start_age: number | null;
  end_age: number | null;
}

export interface LaboratoryDepartmentResource {
  id: number;
  title: string;
}

export interface HandbookBiomaterialResource {
  id: number;
  title: string;
  biomaterial_type?: BiomaterialType;
}

export interface AbstractBiomaterialResource {
  id: number;
  title: string;
}

export interface StudyResource {
  id: number;
  title: string;
  value: string | null;
  status: StudyStatusEnum;
  /** @format date */
  created_at: string;
  value_type: string;
  measure?: string | null;
  reference_value?: ReferencesValueResource;
}

export type StudyStatusEnum = 'not_assembled' | 'assembled' | 'not_confirmed' | 'confirmed';

export interface BiomaterialResource {
  id: number;
  type: BiomaterialType;
  translated_biomaterial_type: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  sampling_at: string | null;
  /** @format date-time */
  confirmed_at: string | null;
  basic_data: string;
  studys?: StudyResource[];
}

export interface AnalysisResource {
  id: number;
  title: string;
  measure: string | null;
  biomaterial_type_translate: string;
  value_type: AnalysisValueTypeEnum;
}

export interface AnalysisWithOrderResource {
  id: number;
  title: string;
  measure: string | null;
  biomaterial_type: BiomaterialType;
  order: number | null;
  is_active: boolean;
  group_services_count: number;
}

export type AnalysisValueTypeEnum = 'boolean' | 'default' | 'textarea' | 'file';

export type BiomaterialType =
  | 'urine'
  | 'blood'
  | 'feces'
  | 'sperm'
  | 'prostate_secret'
  | 'urethral_smear'
  | 'female_smear'
  | 'nasal_smear'
  | 'any_smear'
  | 'scraping'
  | 'sputum'
  | 'breast_milk'
  | 'spinal_fluid'
  | 'unknown';

export interface DiseaseCodeResource {
  code: string;
  title: string;
}

export interface AppointmentDiseaseCodeResource {
  code: string;
  title: string;
  type?: AppointmentDiseaseCodeType;
}

export type AppointmentDiseaseCodeType = 'main' | 'additional' | 'critical';

export type DiseaseCodeKindEnum = 'preliminary' | 'final';

export interface AppointmentResource {
  id: number;
  /** @format datetime */
  start_at: string;
  type: AppointmentTypeEnum;
  /** @format datetime */
  end_at: string;
  status: AppointmentStatusEnum;
  /** @format date-time */
  created_at: string;
  medical_case_id: number | null;
  is_bedridden_patient: boolean;
  survey_plan: boolean;
}

export interface AppointmentWithDiseaseCodesResource {
  id: number;
  /** @format date-time */
  start_at: string | null;
  /** @format date-time */
  end_at: string | null;
  status: AppointmentStatusEnum;
  at_home: boolean;
  /** @format date-time */
  created_at: string;
  disease_codes_main: DiseaseCodeResource[];
  disease_codes_additional: DiseaseCodeResource[];
  disease_codes_critical: DiseaseCodeResource[];
  reason?: AppointmentReasonEnum;
  diagnosis: string | null;
  group_services: GroupServiceTitleResource[];
  medical_case_id: number | null;
}

export interface AppointmentWithSurveyPlanResource {
  id: number;
  doctor: DoctorResource;
  /** @format datetime */
  created_at: string;
  /** @format date-time */
  start_at: string;
  status: AppointmentStatusEnum;
  services: GroupServiceTitleResource[];
}

export interface AppointmentDataResource {
  complaints_admission: string | null;
  disease_anamnesis: string | null;
  life_anamnesis: string | null;
  objective_condition: string | null;
  epidemiological_and_allergoanamnesis: string | null;
  preliminary_main_diagnosis: string | null;
  preliminary_background_diagnosis: string | null;
  preliminary_additional_diagnosis: string | null;
  preliminary_critical_diagnosis: string | null;
  preliminary_competing_diagnosis: string | null;
  final_main_diagnosis: string | null;
  final_background_diagnosis: string | null;
  final_additional_diagnosis: string | null;
  final_critical_diagnosis: string | null;
  final_competing_diagnosis: string | null;
  examination: string | null;
  recommendations: string | null;
  appointments: string | null;
  neurological_status: string | null;
  survey_plan: string | null;
  treatment_appointments_justification: string | null;
  lab_functional_research: string | null;
}

export interface PrescriptionResource {
  id: number;
  status: PrescriptionStatusEnum;
  /** @format date */
  expiration_date: string;
  cancellation_reason: PrescriptionCancellationReasonEnum;
  cancellation_comment: string | null;
  signed_type: PrescriptionSignedTypeEnum;
  /** @format date */
  created_at: string;
}

export type AppointmentStatusEnum =
  | 'created'
  | 'wait_for_payment'
  | 'wait_for_doctor_approve'
  | 'approved'
  | 'canceled'
  | 'provided'
  | 'in_progress'
  | 'waiting'
  | 'on_the_way';

export type AppointmentReasonEnum = 'medical_examination' | 'disease';

export type AppointmentTypeEnum =
  | 'unplanned'
  | 'primary_examination'
  | 'department_examination'
  | 'specialist_examination';

export type PrescriptionStatusEnum =
  | 'created'
  | 'issued'
  | 'partially_issued'
  | 'expired'
  | 'canceled';

export type PrescriptionCancellationReasonEnum = 'medical_contraindication' | 'other';

export type PrescriptionSignedTypeEnum = 'certificate' | 'code';

export interface UpdateAppointment {
  /** @format date-time */
  start_at?: string;
  at_home?: boolean;
  main_disease_codes?: string[];
  additional_disease_codes?: string[];
  critical_disease_codes?: string[];
  reason?: AppointmentReasonEnum;
  diagnosis?: string | null;
  /** @minItems 1 */
  group_service_ids?: number[];
}

export interface PatientDetailResource {
  id: number;
  blood_type: BloodGroupEnum;
  rh_factor: RhFactorEnum;
  family_status: FamilyStatusEnum;
  education_type: EducationTypeEnum;
}

export interface PatientStatisticsResource {
  /** @min 0 */
  total_count_man: number;
  /** @min 0 */
  total_count_woman: number;
  /** @min 0 */
  filtered_count_man: number;
  /** @min 0 */
  filtered_count_woman: number;
}

export interface PatientResource {
  id: number;
  name: string;
  surname: string | null;
  patronymic: string | null;
  gender: PatientGenderEnum;
  /** @format date */
  birthdate: string | null;
  /**
   * @minLength 14
   * @maxLength 14
   */
  pinfl: string | null;
  number_med_card?: string | null;
  phone: string | null;
  group: PatientGroupEnum;
  is_pregnancy: boolean;
  main_contingent: boolean;
  category: PatientCategoryEnum;
  price_list?: PriceListResource;
  working_place: string | null;
  passport_serial: string | null;
  passport_number: string | null;
  place_residence: string | null;
  /** @format date */
  myid_box_updated_at: string | null;
  active_attached_clinic: ClinicTitleResponse;
}

export interface PatientSearchResource {
  id: number | null;
  name: string | null;
  surname: string | null;
  patronymic: string | null;
  /** @format date */
  myid_box_updated_at: string | null;
  gender: PatientGenderEnum;
  /** @format date */
  birthdate: string | null;
  /**
   * @minLength 14
   * @maxLength 14
   */
  pinfl: string | null;
  phone: string | null;
  group: string | null;
  microdistrict: MicroDistrictResource;
  price_list: PriceListResource;
  is_pregnancy: boolean;
  main_contingent: boolean;
  main_pregnancy?: boolean;
  category: number;
  number_med_card: string | null;
  active_attached_clinic: ClinicTitleResponse;
  active_attached_microdistrict: MicroDistrictResource;
  active_attached_doctor: DoctorNameResource;
  working_place: string | null;
  passport_serial: string | null;
  passport_number: string | null;
  place_residence: string | null;
}

export interface PatientSelectResource {
  id: number;
  name: string;
  surname: string | null;
  patronymic: string | null;
  phone: string | null;
  number_med_card: string | null;
  /** @format date */
  birthdate?: string | null;
  gender?: PatientGenderEnum;
}

export interface PatientSearchResourceNew {
  id: number;
  name: string;
  surname: string | null;
  patronymic: string | null;
  /** @format date */
  birthdate: string | null;
  medcard_number: string | null;
  phone: string | null;
  main_contingent: boolean | null;
  /** @format date */
  myid_box_updated_at: string | null;
  category: PatientCategoryEnum;
  price_list: PriceListResource;
  address: string | null;
  pinfl: string | null;
  gender?: PatientGenderEnum;
  active_attached_clinic: ClinicTitleResponse;
  active_attached_microdistrict: MicroDistrictResource;
  active_attached_doctor: DoctorNameResource;
  passport_serial: string | null;
  passport_number: string | null;
  working_place: string | null;
}

export type PatientGenderEnum = 'man' | 'woman';

export type PatientGroupEnum = 'first_group' | 'second_group' | 'third_group' | 'fourth_group';

export type PatientCategoryEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface MicroDistrictResource {
  id: number;
  title: string;
}

export interface DiseaseCode {
  code: string;
  title: string;
}

export interface BeneficiaryResource {
  id?: number;
  category?: BeneficiaryCategoryEnum;
}

export type BeneficiaryCategoryEnum =
  | 'childhood_disability'
  | 'disabled_groups_i_ii'
  | 'orphans'
  | 'war_veterans_1941_1945'
  | 'unemployed_pensioners'
  | 'labor_front_participants'
  | 'chernobyl_liquidators'
  | 'low_income_families'
  | 'international_soldiers'
  | 'conscripts'
  | 'deceased_military_families'
  | 'children_with_pathology'
  | 'pregnant_women_with_pathology'
  | 'endocrine_disease_patients'
  | 'std_patients'
  | 'tuberculosis_patients'
  | 'cancer_patients'
  | 'hemodialysis_patients'
  | 'medical_workers';

export interface BatchUpdateResource {
  user_group: 'first_group' | 'second_group' | 'third_group' | 'fourth_group';
  pregnancies: PregnancyResource[];
  dispensaries: DispensaryResource[];
  disabilities: DisabilityResource[];
  vaccines: VaccineRecourse[];
  beneficiaries: BatchBeneficiaryResource[];
}

export interface BatchBeneficiaryResource {
  id?: number;
  category: BeneficiaryCategoryEnum;
  files?: number[];
}

export interface PregnancyResource {
  id?: number;
  first_day_menstruation: string;
  planned_date_birth: string;
  count_previous_births: number;
  count_successful_births?: number;
  status?: 'abort' | 'gave_birth' | 'misbirth' | 'child_deathed' | 'mother_deathed';
  closed_at?: string;
}

export interface VaccineRecourse {
  id?: number;
  vaccine_id: number;
  vaccine_date: string;
}

export interface DispensaryResource {
  id?: number;
  registration_date: string;
  deregistration_date?: string;
  disease_code: string;
}

export interface DisabilityResource {
  id?: number;
  disease_code: string;
  group: string;
  registration_date: string;
  deregistration_date?: string;
}

export interface DisabilityResponse {
  id: number;
  group: string;
  registration_date: string;
  deregistration_date: string;
}

export interface ProductResource {
  id: number;
  title: string;
  article: string;
  price: number;
  description: string;
}

export interface DoctorResource {
  id: number;
  name: string;
  cabinet: string | null;
}

export interface DoctorNameResource {
  id: number;
  name: string;
}

export interface SpecialtyResource {
  id: number;
  title: string;
  type: SpecialtyTypeEnum;
  is_diagnostic: boolean | null;
}

export type SpecialtyTypeEnum = 'generic' | 'family_doctor' | 'narrow_specialist';

export interface GroupServiceTitleResource {
  id: number;
  title: string;
  price: number;
  is_active?: boolean;
}

export interface BirthProtocolResource {
  id?: number;
  patient_id?: number;
  hospitalization_id: number;
  amniotic_sac_rupture?: string;
  weeks?: number;
  comment?: string;
  content?: string;
  inspection_card_template_id?: number;
  total_births?: number;
  total_pregnancies?: number;
  gestational_age?: number;
}

export interface NewbornChildrenResource {
  id?: number;
  birth_type: BirthTypeEnum;
  birth_outcome: BirthOutcomeEnum;
  gender: NewbornChildGenderEnum;
  name?: string;
  surname?: string;
  patronymic?: string;
  has_fetal_complications: boolean;
  /** @format datetime */
  birth_date: string;
  weight_in_grams?: number;
  height?: number;
  apgar_score?: number;
  comment?: string;
}

export interface UpdateChildDetailsResource {
  name: string;
  surname: string;
  patronymic?: string;
}

export interface InvoiceStatusResource {
  id: number;
  status: InvoiceStatusEnum;
  price: number;
}

export type InvoiceStatusEnum =
  | 'paid'
  | 'partially_paid'
  | 'not_paid'
  | 'refund'
  | 'canceled'
  | 'overpay';

export interface TreatmentCourseResource {
  id: number;
  title: string;
  /** @format date */
  start_at: string | null;
  /** @format date */
  end_at: string | null;
  count_days: number;
  status: TreatmentCourseStatusEnum;
  source_type: TreatmentCourseSourceTypeEnum;
  source_id: number | null;
  description: string | null;
  editable: boolean;
}

export interface TreatmentAppointmentResource {
  id?: number;
  group_service_id: number | null;
  from_warehouse: boolean | null;
  custom_appointment_title: string | null;
  custom_appointment_price?: number | null;
  procedure_place: TreatmentAppointmentProcedurePlaceEnum;
  /** Порядок проведения */
  order: TreatmentAppointmentOrderEnum;
  description: string | null;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  /** null если даты выбираются через календарь */
  interval: number | null;
  dates: {
    /** @format date */
    date: string;
    completed: boolean;
  }[];
  schedule: TreatmentAppointmentScheduleSlotResource[];
}

export interface TreatmentAppointmentTitleResource {
  id: number;
  title: string;
}

export interface ICHIResource {
  id: number;
  code: string;
  title: string;
  is_leaf: boolean;
}

export interface SurgicalInterventionICHIResource {
  id: number;
  code: string;
  title: string;
  is_main: boolean;
}

export interface SurgicalInterventionMedicalSupplyResource {
  id: number;
  amount: string;
  taking_method: SurgicalInterventionDrugTakingEnum;
}

export interface HospitalizationSurgicalInterventionResource {
  id: number;
  /** @format datetime */
  start_date: string;
  /** @format datetime */
  end_date: string;
}

export interface SurgicalInterventionDoctorResource {
  id: number;
  role: SurgicalInterventionDoctorRoleEnum;
}

export interface SurgicalInterventionDetailResource {
  id: number;
  diagnosis_mkb: string;
  time_type?: SurgicalInterventionTimeEnum;
  goal_type?: SurgicalInterventionGoalEnum;
  goal_condition_type?: SurgicalInterventionGoalConditionEnum;
  standard_type?: SurgicalInterventionStandardEnum;
  special_type?: SurgicalInterventionSpecialTypeEnum;
  equipment: SurgicalInterventionEquipmentEnum;
  infection: SurgicalInterventionInfectionEnum;
  stage: SurgicalInterventionStageEnum;
  intervention_type: SurgicalInterventionTypeEnum;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  room_number: string;
  protocol: string;
}

export interface TreatmentAppointmentScheduleSlotResource {
  /** @format time */
  time: string;
  amount: number;
}

export interface TreatmentAppointmentWithSlotsResource {
  id: number;
  title: string;
  procedure_place: TreatmentAppointmentProcedurePlaceEnum;
  /** Порядок проведения */
  order: TreatmentAppointmentOrderEnum;
  dates: {
    /** @format date */
    date: string;
    slots: TreatmentAppointmentSlotResource[];
  }[];
}

export interface TreatmentAppointmentSlotResource {
  /** @format time */
  time: string;
  amount: number;
  completed: boolean;
}

export type TreatmentCourseStatusEnum = 'in_progress' | 'finished' | 'canceled';

export type TreatmentCourseSourceTypeEnum = 'hospital' | 'appointment' | 'hospitalization';

export type TreatmentAppointmentProcedurePlaceEnum = 'in_clinic' | 'at_home';

export type SurgicalInterventionDoctorRoleEnum = 'surgeon' | 'assistant' | 'anesthetist' | 'os';

export type SurgicalInterventionTimeEnum = 'emergency' | 'urgent' | 'planned';

export type SurgicalInterventionGoalEnum = 'medical' | 'diagnostic';

export type SurgicalInterventionGoalConditionEnum = 'radical' | 'palliative';

export type SurgicalInterventionStandardEnum = 'typical' | 'atypical';

export type SurgicalInterventionEquipmentEnum =
  | 'traditional'
  | 'endoscopic'
  | 'endovascular'
  | 'laser'
  | 'microscopic';

export type SurgicalInterventionInfectionEnum =
  | 'clean'
  | 'conditionally_aseptic'
  | 'conditionally_infected'
  | 'infected';

export type SurgicalInterventionStageEnum =
  | 'one_step'
  | 'multi_moment'
  | 'multi_stage'
  | 'combined'
  | 'simultaneous';

export type SurgicalInterventionSpecialTypeEnum = 'microsurgical' | 'endoscopic' | 'endovascular';

export type SurgicalInterventionDrugTakingEnum =
  | 'intravenous'
  | 'intramuscular'
  | 'inhalation'
  | 'subcutaneous'
  | 'intradermal'
  | 'endotracheal'
  | 'combined'
  | 'oral'
  | 'sublingual'
  | 'transmucosal'
  | 'rectal'
  | 'topical'
  | 'infiltration'
  | 'epidural'
  | 'caudal'
  | 'subarachnoid'
  | 'perineural'
  | 'endoneural'
  | 'intravascular'
  | 'parenteral'
  | 'paracervical';

export type SurgicalInterventionTypeEnum = 'removal' | 'restorative' | 'plastic';

/** Порядок проведения */
export type TreatmentAppointmentOrderEnum =
  | 'before_meal'
  | 'during_meal'
  | 'after_meal'
  | 'any_time';

export interface ClinicTitleResponse {
  id: number;
  title: string;
  GCEO: string | null;
}

export interface ReferrerTitleResource {
  id: number;
  title: string;
}

export interface MassPrintingResource {
  id: number;
  type: MassPrintingTypeEnum;
  /** @format date */
  date: string;
}

export type MassPrintingTypeEnum = 'analysis' | 'appointment';

export interface InspectionCardResource {
  id: number;
  basic_data: string;
  medical_report: string;
  /** @format datetime */
  created_at?: string;
}

export interface InspectionCardTemplateTitleResource {
  id: number;
  title: string;
}

export interface OrderResource {
  id: number;
  status: OrderStatusEnum;
  /** @format date */
  order_date: string | null;
  created_at: string;
  creator?: CreatorResource;
  group_services?: GroupServiceTitleResource[];
}

export type OrderStatusEnum = 'created' | 'assemble' | 'awaiting_confirm' | 'ready';

export interface AnthropometryResource {
  height: number | null;
  weight: number | null;
  bmi: number | null;
  temperature: number | null;
  systolic_pressure: number | null;
  diastolic_pressure: number | null;
  sugar_level: number | null;
  sugar_level_after_two_hours: number | null;
  pulse: number | null;
  description: string | null;
  created_at: string | null;
  waist_circumference?: number | null;
  head_circumference?: number | null;
  chest_circumference?: number | null;
  breathing_rate?: number | null;
  blood_oxygen_level?: number | null;
  information_about_treatment?: string | null;
}

export interface CreatorResource {
  id: number;
  name: string;
  class: string;
}

export interface SickLeaveResource {
  id: number;
  patient_id: number;
  serial: string | null;
  number: string | null;
  generated_number: string;
  /**
   * Дата закрытия больничного. null, если он не закрыт.
   * @format date
   */
  closed_at: string | null;
  /**
   * Дата выхода на работу. null, если при закрытии не указали дату.
   * @format date
   */
  closing_date: string | null;
  order: SickLeaveOrderEnum;
  disability_reason: SickLeaveDisabilityReasonEnum;
}

export interface SickLeaveListResource {
  id: number;
  serial: string;
  number: string;
  disease_code: string;
  status: SickLeaveStatusEnum;
  /** @format date */
  start_at: string | null;
  /** @format date */
  end_at: string | null;
}

export interface SickLeaveDocumentListResource {
  id: number;
  serial: string;
  number: string;
  /** @format date */
  start_at: string;
  /** @format date */
  end_at: string | null;
}

export interface SickLeavePeriodResource {
  id: number;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
}

export type SickLeaveStatusEnum = 'in_progress' | 'closed';

export interface CreateSickLeaveRequestBody {
  patient: number;
  appointment: number | null;
  /** Поле обязательное, если передан number */
  serial?: string | null;
  /** Поле обязательное, если передан serial */
  number?: string | null;
  disease_code: string;
  /** @maxItems 3 */
  leave_periods: {
    /** @format date */
    start_date: string;
    /** @format date */
    end_date: string;
    doctor: number;
  }[];
  files: number[];
  order: SickLeaveOrderEnum;
  disability_reason: SickLeaveDisabilityReasonEnum;
}

/**
 * Нужно валидировать, что serial и number оба заполнены или оба null,
 * но отправить для редактирования можно один из них
 */
export interface UpdateSickLeaveRequestBody {
  serial?: string | null;
  number?: string | null;
  disease_code?: string;
  /** @maxItems 3 */
  leave_periods?: {
    /**
     * ID периода освобождения от работы, который нужно изменить
     * или null, если нужно создать новый.
     */
    id: number | null;
    /** @format date */
    start_date: string;
    /** @format date */
    end_date: string;
    doctor: number;
  }[];
  files?: number[];
  order?: SickLeaveOrderEnum;
  disability_reason?: SickLeaveDisabilityReasonEnum;
}

export interface CloseSickLeaveRequestBody {
  /** @format date */
  closing_date: string | null;
}

export type SickLeaveOrderEnum =
  | 'ambulatory'
  | 'stationary'
  | 'sanatorium_resort'
  | 'home_stationary'
  | 'day_stationary'
  | 'quarantine'
  | 'childcare'
  | 'others';

export type SickLeaveDisabilityReasonEnum =
  | 'disease'
  | 'injury'
  | 'pregnancy'
  | 'family_care_leave'
  | 'prosthetics'
  | 'sanatorium_treatment'
  | 'temporary_transfer'
  | 'quarantine';

export interface UserNameResource {
  id: number;
  surname: string | null;
  name: string;
  patronymic: string | null;
}

export interface DepartmentResource {
  id: number;
  title: string;
}

export interface ChamberResource {
  id: number;
  surname?: string | null;
  capacity: number | null;
  class: string | null;
  location: string | null;
  price: number | null;
  number: string | null;
}

export interface ClinicalDiagnosisResource {
  id: number;
  type: ClinicalDiagnosisTypeEnum;
  diagnosis: string;
  /** @format date */
  registration_date: string;
}

export interface ClinicalDiagnosisForSelectResource {
  id: number;
  diagnosis: string;
}

export type ClinicalDiagnosisTypeEnum =
  | 'main'
  | 'additional'
  | 'background'
  | 'critical'
  | 'competing';

export interface PriceListResource {
  id: number;
  title: string;
}

export interface HospitalResource {
  id: number;
  status: HospitalStatusResource;
  /** @format datetime */
  created_at: string;
  /** @format datetime */
  discharged_at: string;
  chamber?: ChamberResource;
}

export type HospitalStatusResource = 'in_progress' | 'completed' | 'canceled';

export interface HospitalizationResource {
  id: number;
  status: HospitalizationStatusResource;
  /** @format date */
  start_date: string | null;
  /** @format date */
  end_date: string | null;
  /** @format date */
  pre_end_date: string | null;
  /** @format date */
  hospitalization_start_date: string | null;
  /** @format date */
  hospitalization_end_date: string | null;
  /** @format date */
  onset_disease_date: string | null;
  purpose: PurposeEnum[] | null;
  birth_protocol: boolean;
  other_purpose: string | null;
  phone: string | null;
  complaints_anamnesis: string | null;
  clinical_examination: string | null;
  laboratory_order: string | null;
  treatment: string | null;
  recommendations: string | null;
  follow_up_activities: string | null;
  reject_reason: string | null;
  note: string | null;
  chamber: ChamberResource;
  /** @format date */
  created_at: string;
  appointment_ids: number[];
  editable: boolean;
  number: string | null;
  is_beneficiary_direction: boolean;
  has_redirect: boolean;
}

export interface HospitalizationRedirectionsResource {
  id: number;
  reject_reason: string;
  /** @format date */
  date: string;
  sending_clinic: ClinicTitleResponse;
}

export interface HospitalizationDiseaseCodeResource {
  id: number;
  code: string;
  title: string;
  type: HospitalizationDiseaseCodeEnum;
}

export interface HospitalizationClinicalDiagnosisResource {
  id: number;
  type: ClinicalDiagnosisTypeEnum;
  diagnosis: string;
  /** @format date */
  registration_date: string;
}

export type HospitalizationDiseaseCodeEnum =
  | 'main'
  | 'additional'
  | 'critical'
  | 'background'
  | 'clinical'
  | 'competing';

export interface HospitalizationHistoryResource {
  id: number;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  /** @format date */
  created_at: string;
}

export interface HospitalizationEpicrisisResource {
  id: number;
  type: HospitalizationEpicrisisTypeEnum;
  content: string | HospitalizationEpicrisisData;
  hospitalization_id: number | null;
  /** @format date */
  created_at: string;
}

export interface HospitalizationMedicalCaseListResource {
  id: number;
  title: string;
  pinned: boolean;
  has_required_diagnoses: boolean;
}

export interface HospitalizationOrderResource {
  id: number;
  appointment_id: number;
  /** @format date */
  date: string;
}

export interface HospitalizationEpicrisisAppointmentResource {
  id: number;
  medical_report: string;
}

export interface HospitalizationAppointmentForInclusionResource {
  id: number;
  doctor: DoctorNameResource & {
    specialties: SpecialtyResource[];
  };
  /** @format date */
  start_at: string | null;
}

export interface HospitalizationTreatmentAppointmentForInclusionResource {
  id: number;
  title: string;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  count_days: number;
}

export interface HospitalizationEpicrisisData {
  admission_complaints: string | null;
  anamnesis: string | null;
  initial_condition: string | null;
  analysis_and_specialist_appointment_results: string | null;
  main_diagnosis: string | null;
  additional_diagnosis: string | null;
  critical_diagnosis: string | null;
  competing_diagnosis: string | null;
  recommendation: string | null;
  self_care_instruction: string | null;
  provided_treatment?: string | null;
  patient_status_at_discharge?: string | null;
  eradication_therapy?: string | null;
  treatment_appointments_justification?: string | null;
}

export interface HospitalizationPrintingDataResource {
  type: PrintingDataTypeEnum;
  id: number | null;
  doctor: DoctorNameResource;
  specialties: SpecialtyResource[] | null;
  /** @format datetime */
  date_time: string | null;
}

export interface HospitalizationShowPrintingDataResource {
  appointments?: (AppointmentResource & {
    disease_codes: AppointmentDiseaseCodeResource;
    inspection_card: InspectionCardResource;
    data: AppointmentDataResource;
    doctor: DoctorResource & {
      specialties: SpecialtyResource[];
    };
  })[];
  title_pages?: HospitalizationTitlePageResource[];
  epicrises?: HospitalizationEpicrisisResource[];
  treatment_diaries?: (TreatmentDiaryResource & {
    creator: CreatorResource & {
      doctor: DoctorResource & {
        specialties: SpecialtyResource[];
      };
    };
  })[];
  analyses?: (OrderResource & {
    biomaterials?: (BiomaterialResource & {
      abstract_biomaterial: AbstractBiomaterialResource & {
        handbook_biomaterial: HandbookBiomaterialResource & {
          laboratory_department: LaboratoryDepartmentResource;
        };
      };
      studys: (StudyResource & {
        analysis: AnalysisResource;
        referencesValue: ReferencesValueResource & {
          referencesGroup: ReferencesGroupResource;
        };
      })[];
    })[];
  })[];
}

export type PrintingDataTypeEnum =
  | 'title_page'
  | 'primary_examination'
  | 'department_examination'
  | 'specialist_examination'
  | 'undefined_appointment'
  | 'epicrises'
  | 'income'
  | 'clinical_diagnosis_justification'
  | 'landmark'
  | 'outcome'
  | 'diaries'
  | 'diary'
  | 'analyses'
  | 'analysis';

export type HospitalizationEpicrisisTypeEnum =
  | 'income'
  | 'clinical_diagnosis_justification'
  | 'outcome'
  | 'landmark';

export type HospitalizationStatusResource =
  | 'created'
  | 'in_progress'
  | 'provided'
  | 'rejected'
  | 'approved';

export interface TransferPatientHistoryResource {
  id: number;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  note: string;
}

export type PurposeEnum =
  | 'diagnostic'
  | 'consultation'
  | 'treatment'
  | 'inspection'
  | 'dispensary_control'
  | 'childbirth';

export interface HospitalizationTitlePageResource {
  transporting_method?: TransportingMethodEnum;
  blood_group?: BloodGroupEnum;
  rh_factor?: RhFactorEnum;
  side_effects_medications?: string | null;
  height?: number | null;
  weight?: string | null;
  temperature?: number | null;
  working_place?: string | null;
  permanent_residence_areas?: PermanentResidenceAreasEnum;
  permanent_residence_address?: string | null;
  quickly_taken?: boolean | null;
  transport?: string | null;
  hospitalization_reason?: HospitalizationReasonEnum;
  time_to_admission?: number | null;
  readmission_same_reason?: ReadmissionSameReasonEnum;
  count_readmission_same_reason?: number | null;
  surgical_operations?: HospitalizationTitlePageSurgicalOperationsResource[];
  operating_doctor?: string | null;
  sick_leaves?: {
    number?: string | null;
    /** @format date */
    start_date?: string | null;
    /** @format date */
    end_date?: string | null;
  }[];
  analyses?: AnalysesEnum[];
  treatment?: string;
  result_treatment?: ResultTreatmentEnum;
  transferred_another_department?: string | null;
  working_capacity?: WorkingCapacityEnum;
  report?: string | null;
  additional_signs?: string | null;
  other_analyses?: string | null;
  comment?: string | null;
  created_at?: string | null;
}

export interface HospitalizationTitlePageSurgicalOperationsResource {
  title: string | null;
  /** @format date */
  datetime: string | null;
  method_anesthesia: string | null;
  complications: string | null;
}

export interface HospitalizationTitlePageSickLeavesResource {
  number: string | null;
  /** @format date */
  start_date: string | null;
  /** @format date */
  end_date: string | null;
}

export type HospitalizationReasonEnum = 'disease' | 'injury' | 'planned';

export type ReadmissionSameReasonEnum = 'primary' | 'secondary';

export type ResultTreatmentEnum =
  | 'healthy'
  | 'slight_improvement'
  | 'no_change'
  | 'transferred_to_another_facility'
  | 'deceased'
  | 'deceased_in_er'
  | 'deceased_before_28_weeks'
  | 'deceased_after_28_weeks'
  | 'deceased_before_childbirth'
  | 'deceased_after_childbirth';

export type TransportingMethodEnum = 'in_wheelchair' | 'on_stretcher' | 'can_move_independently';

export type WorkingCapacityEnum =
  | 'recovered'
  | 'reduced'
  | 'temporarily_disabled'
  | 'fully_disabled_due_to_illness'
  | 'disabled_for_other_reasons';

export type BloodGroupEnum = 'a' | 'b' | 'ab' | 'o';

export type RhFactorEnum = 'positive' | 'negative';

export type FamilyStatusEnum = 'not_married' | 'married' | 'divorced';

export type EducationTypeEnum = 'elementary' | 'secondary' | 'higher';

export type SymptomsOfReactionEnum =
  | 'skin_reaction'
  | 'respiratory_symptoms'
  | 'gastrointestinal_symptoms'
  | 'cardiovascular_symptoms'
  | 'anaphylaxis';

export type SeverityOfReactionEnum = 'mild' | 'moderate' | 'severe';

export type PermanentResidenceAreasEnum = 'city' | 'village';

export type AnalysesEnum = 'blood' | 'feces_back_seeding' | 'pediculosis' | 'abstract_disease';

export interface HospitalizationStatisticsCardResource {
  region?: string | null;
  city?: string | null;
  mahalla?: string | null;
  street?: string | null;
  house?: string | null;
  apartment?: string | null;
  phone?: string | null;
  email?: string | null;
  emergency_care?: boolean | null;
  delivered_by_ambulance?: boolean | null;
  same_illness_in_last_month?: boolean | null;
  hospitalization_reason?: HospitalizationReasonEnum;
  time_to_admission?: number | null;
  hospitalization_result?: ResultTreatmentEnum;
  /** Причина смерти, код МКБ */
  death_cause?: DiseaseCodeResource;
  /**
   * Причина причины смерти. Например, смерть может быть вызвана инфарктом,
   * который вызван тромбозом. Код МКБ.
   */
  death_cause_cause?: DiseaseCodeResource;
  /** Код МКБ */
  death_main_diagnosis?: DiseaseCodeResource;
  other_diagnoses?: DiseaseCodeResource[] | null;
  aids?: boolean | null;
  /** @format date-time */
  aids_analysis_date?: string | null;
  rw?: boolean | null;
  /** @format date-time */
  rw_analysis_date?: string | null;
  paid_patient?: boolean;
  newborn_weight?: number | null;
  newborn_height?: number | null;
  newborn_mothers_pinfl?: string | null;
  tuberculosis_treatment_resistance?: TuberculosisTreatmentResistanceEnum;
  /** @format date */
  sick_leave_start_date?: string | null;
  /** @format date */
  sick_leave_end_date?: string | null;
}

export type TuberculosisTreatmentResistanceEnum =
  | 'not_resistant'
  | 'monoresistant'
  | 'polyresistant'
  | 'multiresistant'
  | 'extremely_resistant';

export interface ServiceCategoryResource {
  id: number;
  title: string;
}

export interface FileResource {
  id: number;
  link: string;
  filename: string | null;
}

export interface TreatmentDiaryResource {
  id: number;
  content: string;
  /** @format date */
  created_at: string;
  /** @format datetime */
  start_at?: string;
}

export interface CreateTreatmentDiaryRequestBody {
  content: string;
  hospitalization_id: number;
  inspection_card_template_id: number;
  /** @format datetime */
  start_at: string;
}

export interface UpdateTreatmentDiaryRequestBody {
  content: string | null;
  hospitalization_id: number;
  inspection_card_template_id: number;
  /** @format datetime */
  start_at?: string;
}

export interface VacationResource {
  id: number;
  type: VacationTypeEnum;
  reason: VacationReasonEnum;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
}

export type VacationTypeEnum = 'vacation' | 'sick_leave' | 'others';

export type VacationReasonEnum =
  | 'annual_leave'
  | 'social_leave'
  | 'partial_pay_leave'
  | 'unpaid_leave'
  | 'illness_injury'
  | 'maternity_leave'
  | 'family_care_leave'
  | 'sanatorium_treatment'
  | 'temporary_transfer'
  | 'quarantine'
  | 'prosthetics'
  | 'medical_examination'
  | 'training'
  | 'conferences';

export interface CreateVacationRequestBody {
  user_id: number;
  type: VacationTypeEnum;
  reason?: VacationReasonEnum;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
}

export interface UpdateVacationRequestBody {
  type?: VacationTypeEnum;
  reason?: VacationReasonEnum;
  /** @format date */
  start_date?: string;
  /** @format date */
  end_date?: string;
}

export interface CreateEmployeeRequestBody {
  role: string;
  salary?: number;
  phone: string;
  appointment_commission: number;
  doctor_recommendation_commission: number;
  specialties_ids?: number[];
  pinfl: string;
  working_time?: SetWorkingTimeRequestBody;
}

export interface UpdateEmployeeRequestBody {
  salary: number;
  phone: string;
  appointment_commission: number;
  doctor_recommendation_commission: number;
}

export interface WorkingTimeResource {
  /** @format time */
  monday_start: string | null;
  /** @format time */
  monday_end: string | null;
  /** @format time */
  tuesday_start: string | null;
  /** @format time */
  tuesday_end: string | null;
  /** @format time */
  wednesday_start: string | null;
  /** @format time */
  wednesday_end: string | null;
  /** @format time */
  thursday_start: string | null;
  /** @format time */
  thursday_end: string | null;
  /** @format time */
  friday_start: string | null;
  /** @format time */
  friday_end: string | null;
  /** @format time */
  saturday_start: string | null;
  /** @format time */
  saturday_end: string | null;
  /** @format time */
  sunday_start: string | null;
  /** @format time */
  sunday_end: string | null;
}

export interface SetWorkingTimeRequestBody {
  /** @format time */
  monday_start?: string | null;
  /** @format time */
  monday_end?: string | null;
  /** @format time */
  tuesday_start?: string | null;
  /** @format time */
  tuesday_end?: string | null;
  /** @format time */
  wednesday_start?: string | null;
  /** @format time */
  wednesday_end?: string | null;
  /** @format time */
  thursday_start?: string | null;
  /** @format time */
  thursday_end?: string | null;
  /** @format time */
  friday_start?: string | null;
  /** @format time */
  friday_end?: string | null;
  /** @format time */
  saturday_start?: string | null;
  /** @format time */
  saturday_end?: string | null;
  /** @format time */
  sunday_start?: string | null;
  /** @format time */
  sunday_end?: string | null;
}

export interface CreateBirthProtocolRequestBody {
  user_id?: number;
  hospitalization_id: number;
  /** @format date */
  amniotic_sac_rupture?: string;
  comment?: string;
  newborn_children?: NewbornChildrenResource[] | null;
  content?: string;
  inspection_card_template_id?: number;
  gestational_age?: number;
  total_births?: number;
  total_pregnancies?: number;
}

export type NewbornChildGenderEnum = 'man' | 'woman';

export type BirthTypeEnum = 'natural' | 'surgical';

export type BirthOutcomeEnum = 'live_birth' | 'antenatal_stillbirth' | 'intrapartum_stillbirth';

export interface EmployeeResource {
  id: number;
  role: RoleEnum;
  name: string;
  is_working: boolean | null;
  is_active: boolean | null;
  gender: PatientGenderEnum;
  current_status: VacationStatusEnum;
  phone: string | null;
  salary: number | null;
  address: string | null;
  appointment_commission: number | null;
  birthdate: string;
  doctor_recommendation_commission: number | null;
  doctor: DoctorResource;
  specialties: SpecialtyResource[];
  avatar: FileResource;
  working_time: WorkingTimeResource;
}

export interface SearchEmployeeResource {
  id: number | null;
  role: RoleEnum;
  name: string;
  is_working: boolean | null;
  gender: PatientGenderEnum;
  is_active: boolean | null;
  pinfl?: string;
  current_status: VacationStatusEnum;
  phone: string | null;
  salary: number | null;
  address: string | null;
  appointment_commission: number | null;
  birthdate: string;
  doctor_recommendation_commission: number | null;
  doctor: DoctorResource;
  specialties: SpecialtyResource[];
  avatar: FileResource;
  working_time: WorkingTimeResource;
}

export type VacationStatusEnum = 'vacation' | 'sick_leave' | 'others' | 'working';

export type DocumentTypeEnum = 'sick_leave';

export interface ClinicAttachmentResource {
  id: number;
  /** @format date */
  registration_date: string;
  /** @format date */
  deregistration_date: string | null;
}

export interface MedicalCaseResource {
  id: number;
  title: string | null;
  hospitalization_id: number | null;
}

export interface MedicalCaseWithContentResource {
  id: number;
  title: string | null;
  has_analyses: boolean;
  has_sick_leaves: boolean;
  has_hospitalizations: boolean;
  pregnancy_id?: number;
}

export interface MedicalCaseListResource {
  id: number;
  title: string;
  pinned: boolean;
  disease_codes: DiseaseCodeResource[];
  date: string | null;
  doctor: DoctorResource;
  is_pregnancy: boolean;
  is_current: boolean;
}

export interface MedicalCaseAppointmentResource {
  id: number;
  status: AppointmentStatusEnum;
  /** @format date */
  date: string;
  /** @format time */
  time: string;
  type: AppointmentTypeEnum;
  editable: boolean;
}

export interface MedicalCaseDiseaseCodeResource {
  id: number | null;
  source: 'appointment' | 'medical_case' | 'hospitalization';
  disease_code: DiseaseCodeResource;
  creator: CreatorResource;
  /** @format date */
  date: string;
  type: MedicalCaseDiseaseCodeTypeEnum;
  editable: boolean;
  pinned: boolean;
  kind?: DiseaseCodeKindEnum;
}

export interface AppointmentDiseaseCodeForSelectResource {
  id: number;
  code: string;
  title: string;
}

export type MedicalCaseDiseaseCodeTypeEnum =
  | 'main'
  | 'additional'
  | 'critical'
  | 'background'
  | 'competing';

export interface ScreeningStatusResource {
  type: ScreeningTypeEnum;
  title: string;
  status: ScreeningStatusEnum;
  /** @format date-time */
  completed_at: string;
}

export interface CVDScreeningResource {
  id: number;
  patient_id: number;
  anthropometry_id: number;
  alcohol_consumption: boolean | null;
  alcohol_consumption_in_special_cases: boolean | null;
  alcohol_consumption_per_week: '1-2_or_less' | '3-6_or_less' | '6_or_more' | null;
  nicotine_consumption: 'yes' | 'no' | 'stopped';
  nicotine_products: 'cigarettes' | 'nasvai' | 'hookah' | 'electronic_cigarettes' | 'other' | null;
  nicotine_products_other: string | null;
  nicotine_consumption_frequency: 'daily' | '1-3_per_week' | '2-4_per_month' | null;
  stopped_smoking_in: 'last_12_month' | 'more_than_last_12_month' | null;
  poor_diet: boolean | null;
  daily_vegetable_consumption: 'more_than_5_portions' | 'less_than_5_portions' | null;
  passive_lifestyle: boolean | null;
  physical_activity: 'less_than_150_min_per_week' | 'more_than_150_min_per_week' | null;
  has_hereditary_burden: boolean | null;
  hereditary_burden: string | null;
  relationship_degree: ('mother' | 'father' | 'brother' | 'sister' | 'other')[] | null;
  relationship_degree_other: string | null;
  has_congenital_diseases: boolean | null;
  congenital_diseases: string | null;
  working: boolean | null;
  work_type:
    | (
        | 'active'
        | 'passive'
        | 'day_shifts'
        | 'stay_in_draft'
        | 'prolonged_static_tension'
        | 'physical_stress'
        | 'psychological_stress'
      )[]
    | null;
  recommendations: string | null;
  conclusion: string | null;
  notes: string | null;
  result: number;
  risk_group: CVDScreeningRiskGroupEnum;
  /** @format date */
  created_at: string;
}

export interface CVDScreeningResultResource {
  result: number;
  risk_group: CVDScreeningRiskGroupEnum;
  /** @format date-time */
  created_at: string;
}

export interface UpdatePatientsCvdScreeningRequest {
  anthropometry: {
    height: number;
    weight: number;
    temperature?: number | null;
    systolic_pressure: number;
    diastolic_pressure?: number | null;
    sugar_level?: number | null;
    pulse?: number | null;
    waist_circumference?: number | null;
    head_circumference?: number | null;
    chest_circumference?: number | null;
    breathing_rate?: number | null;
    blood_oxygen_level?: number | null;
    information_about_treatment?: string | null;
  };
  screening: {
    alcohol_consumption: boolean | null;
    alcohol_consumption_in_special_cases: boolean | null;
    alcohol_consumption_per_week: '1-2_or_less' | '3-6_or_less' | '6_or_more' | null;
    nicotine_consumption: 'yes' | 'no' | 'stopped';
    nicotine_products:
      | 'cigarettes'
      | 'nasvai'
      | 'hookah'
      | 'electronic_cigarettes'
      | 'other'
      | null;
    nicotine_products_other?: string | null;
    nicotine_consumption_frequency: 'daily' | '1-3_per_week' | '2-4_per_month' | null;
    stopped_smoking_in: 'last_12_month' | 'more_than_last_12_month' | null;
    poor_diet: boolean | null;
    daily_vegetable_consumption: 'more_than_5_portions' | 'less_than_5_portions' | null;
    passive_lifestyle: boolean | null;
    physical_activity: 'less_than_150_min_per_week' | 'more_than_150_min_per_week' | null;
    has_hereditary_burden: boolean | null;
    hereditary_burden?: string | null;
    relationship_degree: ('mother' | 'father' | 'brother' | 'sister' | 'other')[] | null;
    relationship_degree_other?: string | null;
    has_congenital_diseases: boolean | null;
    congenital_diseases?: string | null;
    working: boolean | null;
    work_type:
      | (
          | 'active'
          | 'passive'
          | 'day_shifts'
          | 'stay_in_draft'
          | 'prolonged_static_tension'
          | 'physical_stress'
          | 'psychological_stress'
        )[]
      | null;
    recommendations?: string | null;
    conclusion?: string | null;
    notes?: string | null;
  };
}

export interface UpdatePatientsEndocrineScreeningRequest {
  anthropometry: {
    height?: number | null;
    weight?: number | null;
    waist_circumference: number;
    head_circumference?: number | null;
    chest_circumference?: number | null;
    sugar_level?: number | null;
    sugar_level_after_two_hours?: number | null;
  };
  screening: {
    passive_lifestyle: boolean;
    diabetes_in_relatives: 'immediate_relatives' | 'distant_relatives' | 'none';
    recommendations?: string | null;
    conclusion?: string | null;
    notes?: string | null;
  };
}

export interface EndocrineScreeningResource {
  id: number;
  patient_id: number;
  anthropometry_id: number;
  passive_lifestyle: boolean;
  diabetes_in_relatives: 'immediate_relatives' | 'distant_relatives' | 'none';
  recommendations: string | null;
  conclusion: string | null;
  notes: string | null;
  result: number;
  risk_group: EndocrineScreeningRiskGroupEnum;
  /** @format date */
  created_at: string;
}

export interface EndocrineScreeningResultResource {
  result: number;
  risk_group: EndocrineScreeningRiskGroupEnum;
  /** @format date-time */
  created_at: string;
}

export type ScreeningTypeEnum = 'cvd_screening' | 'endocrine_screening';

export type ScreeningStatusEnum = 'filled' | 'not_filled' | 'partially_filled';

export type CVDScreeningRiskGroupEnum = 'low' | 'moderate' | 'medium' | 'high' | 'very_high';

export type EndocrineScreeningRiskGroupEnum = 'low' | 'medium' | 'high' | 'very_high';

export interface NewDispensaryResource {
  id: number;
  /** @format date */
  registration_date: string;
  /** @format date */
  deregistration_date: string | null;
  /** @format date */
  created_at?: string;
}

export interface ChamberWithOccupiedBedsResource {
  id: number;
  number: string | null;
  capacity: number | null;
  count_occupied_chambers: number;
}

export interface ErrorResource {
  message?: string | null;
}

export interface PatientSearchRequestBody {
  /**
   * @minLength 14
   * @maxLength 14
   */
  pinfl: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  passport_serial: string | null;
  /**
   * @minLength 7
   * @maxLength 7
   */
  passport_number: string | null;
  id_card: string | null;
  /** @format date */
  birthdate: string | null;
  number_med_card: string | null;
  image?: string | null;
}

export interface FindPatientByDocumentsRequest {
  medcard_number?: string;
  /** @format date */
  birthdate?: string;
  /**
   * Серия документа удостоверяющего личность, 2 буквы
   * @minLength 2
   * @maxLength 2
   */
  id_serial?: string;
  /**
   * Номер документ удостоверяющего личность, 7 цифр
   * @minLength 7
   * @maxLength 7
   */
  id_number?: string;
  birth_certificate_serial?: string;
  birth_certificate_number?: string;
  birth_certificate?: string;
  /**
   * @minLength 14
   * @maxLength 14
   */
  pinfl?: string;
}

export interface SearchPatientByImageRequestBody {
  image: string;
}

export interface PatientUpdateImageInMyidBoxRequestBody {
  image: string;
}

export interface FaceVerificationRequestBody {
  image: string;
}

export interface TokenVerificationRequestBody {
  token: string;
}

/** В запросе должен присутствовать ПИНФЛ или паспорт */
export interface RequestAuthorizationCode {
  pinfl?: string;
  passport_serial?: string;
  passport_number?: string;
}

export interface AuthWithOPTResource {
  token: string;
  token_type: string;
}

export interface AuthWithOPTRequestBody {
  pinfl?: string;
  passport_serial?: string;
  passport_number?: string;
  code: string;
}

export interface LoginAsRoleRequestBody {
  id: number;
}

export interface RoleResource {
  id: number;
  role: RoleEnum;
  clinic: string;
  specialties: string[] | null;
}

export type RoleEnum =
  | 'patient'
  | 'admin'
  | 'super-admin'
  | 'manager'
  | 'consultant'
  | 'doctor'
  | 'family-doctor'
  | 'director'
  | 'laboratory'
  | 'nurse'
  | 'warehouse'
  | 'accountant'
  | 'registry'
  | 'pharmacist'
  | 'minister'
  | 'prescription-reviewer';

/** Нужно передавать один из параметров user или user_id передавать не null */
export interface CreateAppointmentRequestBody {
  user_id?: number | null;
  user?: {
    name: string;
    surname: string;
    patronymic: string | null;
    gender: PatientGenderEnum;
    /** @format date */
    birthdate: string | null;
    /**
     * @minLength 14
     * @maxLength 14
     */
    pinfl?: string | null;
  };
  doctor_id?: number;
  /** @format datetime */
  start_at?: string | null;
  clinic_id?: number | null;
  /** Приём, в рамках которого направляют на новый приём или анализ */
  parent_appointment_id?: number;
  /** Прикрепляет прием к эпизоду обращения */
  medical_case_id?: number;
  /**
   * Создаёт новый эпизод обращения для приёма.
   * Не учитывается, если передан medical_case_id
   */
  with_medical_case?: boolean;
  /** true, если это план обследования */
  survey_plan?: boolean;
  /** true, если это стационарный пациент */
  is_inpatient_patient?: boolean;
  type?: AppointmentTypeEnum;
  /** передавать true если лежачий пациент */
  is_bedridden_patient?: boolean;
  doctor_comment?: string | null;
}

export interface UpdateAppointmentDocuments {
  file_ids: number[];
}

export interface UpdateAppointmentDataRequestBody {
  complaints_admission?: string | null;
  disease_anamnesis?: string | null;
  life_anamnesis?: string | null;
  objective_condition?: string | null;
  epidemiological_and_allergoanamnesis?: string | null;
  preliminary_main_diagnosis?: string | null;
  preliminary_background_diagnosis?: string | null;
  preliminary_additional_diagnosis?: string | null;
  preliminary_critical_diagnosis?: string | null;
  preliminary_competing_diagnosis?: string | null;
  final_main_diagnosis?: string | null;
  final_background_diagnosis?: string | null;
  final_additional_diagnosis?: string | null;
  final_critical_diagnosis?: string | null;
  final_competing_diagnosis?: string | null;
  examination?: string | null;
  recommendations?: string | null;
  appointments?: string | null;
  neurological_status?: string | null;
  survey_plan?: string | null;
  survey_plan_included_appointments?: number[];
  treatment_appointments_justification?: string | null;
  treatment_appointments_justification_included_treatment_appointments?: number[];
  lab_functional_research?: string | null;
}

export interface CreateTreatmentCourseRequestBody {
  title: string;
  doctor_id: number;
  description: string | null;
  user_id: number;
  source_id: number | null;
  source_type: TreatmentCourseSourceTypeEnum;
}

export interface UpdateTreatmentCourseRequestBody {
  title: string;
  doctor_id: number;
  description: string | null;
}

export interface FinishTreatmentCourseRequestBody {
  /** @format date */
  end_date: string | null;
}

export interface CreateTreatmentAppointmentRequestBody {
  group_service_id: number | null;
  from_warehouse: boolean | null;
  custom_appointment_title: string | null;
  custom_appointment_price?: number | null;
  procedure_place: TreatmentAppointmentProcedurePlaceEnum;
  /** Порядок проведения */
  order: TreatmentAppointmentOrderEnum;
  description: string | null;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  /** null если даты выбираются через календарь */
  interval: number | null;
  dates: string[];
  schedule: TreatmentAppointmentScheduleSlotResource[];
}

export interface UpdateTreatmentAppointmentRequestBody {
  procedure_place: TreatmentAppointmentProcedurePlaceEnum;
  /** Порядок проведения */
  order: TreatmentAppointmentOrderEnum;
  description: string | null;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  /** null если даты выбираются через календарь */
  interval: number | null;
  dates: string[];
  schedule: TreatmentAppointmentScheduleSlotResource[];
}

export interface ExecuteTreatmentAppointmentRequestBody {
  /** @format date-time */
  date_time: string;
  executed: boolean;
}

export interface CreateAnthropometryRequestBody {
  height: number | null;
  weight: number | null;
  temperature: number | null;
  systolic_pressure: number | null;
  diastolic_pressure: number | null;
  sugar_level: number | null;
  sugar_level_after_two_hours?: number | null;
  pulse: number | null;
  description: string | null;
  waist_circumference?: number | null;
  head_circumference?: number | null;
  chest_circumference?: number | null;
  breathing_rate?: number | null;
  blood_oxygen_level?: number | null;
  information_about_treatment?: string | null;
}

export interface UpdateEmployeeStatusRequestBody {
  is_active: boolean;
}

export interface InProgressStatusHospitalizationRequestBody {
  department_id?: number;
  chamber_id?: number;
  doctor_id: number;
  price_list_id: number;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
}

export interface RedirectToHospitalizationRequestBody {
  clinic_id: number;
  comment?: string | null;
  reject_reason?: string | null;
}

export interface HospitalizationCreateRequestBody {
  patient_id: number;
  clinic_id: number;
  planned_department_id?: number | null;
  /** @format date */
  hospitalization_start_date?: string;
  /** @format date */
  hospitalization_end_date?: string;
  /** @format date */
  onset_disease_date?: string;
  medical_case_id?: number;
  /** @minItems 1 */
  appointment_ids?: number[];
  /** @minItems 1 */
  purpose?: PurposeEnum[];
  other_purpose?: string;
  phone?: string;
  complaints_anamnesis?: string;
  clinical_examination?: string;
  laboratory_order?: string;
  treatment?: string;
  recommendations?: string;
  follow_up_activities?: string;
  note?: string;
  income_epicrisis: string;
  /** @minItems 1 */
  file_ids?: number[];
  status?: HospitalizationStatusResource;
  main_clinical_diagnosis_id?: number;
  clinical_diagnoses_ids?: {
    additional?: number[];
    background?: number[];
    critical?: number[];
    competing?: number[];
  };
  main_disease_code: string;
  disease_codes: {
    additional?: string[];
    background?: string[];
    critical?: string[];
    competing?: string[];
  };
  is_beneficiary_direction?: boolean;
}

export interface CreateHospitalizationWithoutDirectionRequestBody {
  patient_id: number;
  primary_disease_code: string;
  /** @minItems 1 */
  purpose?: PurposeEnum[];
  other_purpose?: string;
  phone?: string;
  complaints_anamnesis?: string;
  clinical_examination?: string;
  laboratory_order?: string;
  treatment?: string;
  recommendations?: string;
  follow_up_activities?: string;
  note?: string;
  income_epicrisis: string;
  /** @minItems 1 */
  file_ids?: number[];
  department_id?: number;
  chamber_id?: number;
  doctor_id: number;
  price_list_id: number;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  disease_codes: {
    additional?: string[];
    background?: string[];
    critical?: string[];
    competing?: string[];
  };
  main_clinical_diagnosis_id?: number;
  clinical_diagnoses_ids?: {
    additional?: number[];
    background?: number[];
    critical?: number[];
    competing?: number[];
  };
}

export interface OptionalUpdateHospitalizationRequestBody {
  status?: 'created' | 'approved' | 'rejected';
  doctor_id?: number;
  price_list_id?: number;
  /** @format date */
  start_date?: string;
  /** @format date */
  end_date?: string;
  department_id?: number | null;
  chamber_id?: number | null;
}

export interface RejectHospitalizationRequestBody {
  reject_reason?: string;
}

export interface ProvideHospitalizationRequestBody {
  end_date: string;
}

export interface HospitalizationUpdateRequestBody {
  clinic_id: number;
  planned_department_id?: number | null;
  /** @format date */
  hospitalization_start_date?: string;
  /** @format date */
  hospitalization_end_date?: string;
  /** @format date */
  onset_disease_date?: string;
  medical_case_id?: number;
  /** @minItems 1 */
  appointment_ids?: number[];
  /** @minItems 1 */
  purpose?: PurposeEnum[];
  other_purpose?: string;
  phone?: string;
  complaints_anamnesis?: string;
  clinical_examination?: string;
  laboratory_order?: string;
  treatment?: string;
  recommendations?: string;
  follow_up_activities?: string;
  note?: string;
  income_epicrisis?: string;
  /** @minItems 1 */
  file_ids?: number[];
  status: HospitalizationStatusResource;
  main_clinical_diagnosis_id?: number;
  clinical_diagnoses_ids?: {
    additional?: number[];
    background?: number[];
    critical?: number[];
    competing?: number[];
  };
  main_disease_code: string;
  disease_codes: {
    additional?: string[];
    background?: string[];
    critical?: string[];
    competing?: string[];
  };
  is_beneficiary_direction?: boolean;
}

export interface CreateHospitalizationEpicrisisRequestBody {
  hospitalization_id: number;
  type: 'clinical_diagnosis_justification' | 'landmark' | 'outcome';
  content: string | HospitalizationEpicrisisData;
  inspection_card_template_id?: number;
  /** @minItems 1 */
  files_ids?: number[];
  /** ID приёмов, данные которых были включены в content */
  appointment_ids?: number[];
  /** ID анализов, данные которых были включены в content */
  order_ids?: number[];
  /** ID назначений, данные которых были включены в content */
  treatment_appointment_ids?: number[];
}

export interface UpdateHospitalizationEpicrisisRequestBody {
  content: string | HospitalizationEpicrisisData;
  inspection_card_template_id?: number;
  /** @minItems 1 */
  files_ids?: number[];
}

export interface GetHospitalizationPrintingDataRequestBody {
  appointment_ids?: number[];
  title_page_ids?: number[];
  epicrisis_ids?: number[];
  treatment_diary_ids?: number[];
  order_ids?: number[];
}

export interface HospitalizationTransferPatientRequestBody {
  chamber_id: number;
  doctor_id: number;
  note?: string;
}

export interface UpdateHospitalizationTitlePageRequestBody {
  transporting_method?: TransportingMethodEnum;
  blood_group?: BloodGroupEnum;
  rh_factor?: RhFactorEnum;
  side_effects_medications?: string | null;
  height?: number | null;
  weight?: string | null;
  temperature?: number | null;
  working_place?: string | null;
  permanent_residence_areas?: PermanentResidenceAreasEnum;
  permanent_residence_address?: string | null;
  quickly_taken?: boolean | null;
  transport?: string | null;
  hospitalization_reason?: HospitalizationReasonEnum;
  time_to_admission?: number | null;
  readmission_same_reason?: ReadmissionSameReasonEnum;
  count_readmission_same_reason?: number | null;
  surgical_operations?: {
    title?: string | null;
    /** @format date */
    datetime?: string | null;
    method_anesthesia?: string | null;
    complications?: string | null;
  }[];
  operating_doctor?: string | null;
  sick_leaves?: HospitalizationTitlePageSickLeavesResource[];
  analyses?: AnalysesEnum[];
  treatment?: string;
  result_treatment?: ResultTreatmentEnum;
  transferred_another_department?: string | null;
  working_capacity?: WorkingCapacityEnum;
  report?: string | null;
  additional_signs?: string | null;
  other_analyses?: string | null;
  comment?: string | null;
}

export interface UpdateHospitalizationStatisticsCardRequestBody {
  region?: string | null;
  city?: string | null;
  mahalla?: string | null;
  street?: string | null;
  house?: string | null;
  apartment?: string | null;
  phone?: string | null;
  email?: string | null;
  emergency_care?: boolean | null;
  delivered_by_ambulance?: boolean | null;
  same_illness_in_last_month?: boolean | null;
  /** Причина смерти, код МКБ */
  death_cause?: string | null;
  /**
   * Причина причины смерти. Например, смерть может быть вызвана инфарктом,
   * который вызван тромбозом. Код МКБ.
   */
  death_cause_cause?: string | null;
  /** Код МКБ */
  death_main_diagnosis?: string | null;
  other_diagnoses?: string[] | null;
  aids?: boolean | null;
  /** @format date-time */
  aids_analysis_date?: string | null;
  rw?: boolean | null;
  /** @format date-time */
  rw_analysis_date?: string | null;
  paid_patient?: boolean;
  newborn_weight?: number | null;
  newborn_height?: number | null;
  newborn_mothers_pinfl?: string | null;
  tuberculosis_treatment_resistance?: TuberculosisTreatmentResistanceEnum;
  /** @format date */
  sick_leave_start_date?: string | null;
  /** @format date */
  sick_leave_end_date?: string | null;
}

export interface UpdateAnalysisOrderFormRequest {
  order: number;
}

export interface UpdateAnalysisActiveRequest {
  is_active: boolean;
}

export interface HospitalizationSurgicalInterventionsRequest {
  diagnosis_mkb: string;
  protocol: string;
  time_type?: SurgicalInterventionTimeEnum;
  goal_type?: SurgicalInterventionGoalEnum;
  goal_condition_type?: SurgicalInterventionGoalConditionEnum;
  standard_type?: SurgicalInterventionStandardEnum;
  special_type?: SurgicalInterventionSpecialTypeEnum;
  medical_supplies: {
    product_id: number;
    amount: string;
    taking_method: SurgicalInterventionDrugTakingEnum;
    doctor_id: number;
  }[];
  surgical_team: {
    doctor_id: number;
    role: SurgicalInterventionDoctorRoleEnum;
  }[];
  ichi: {
    is_main: boolean;
    ichi_id: number;
  }[];
  /** @format datetime */
  start_date: string;
  /** @format datetime */
  end_date: string;
  room_number: string;
  equipment: SurgicalInterventionEquipmentEnum;
  infection: SurgicalInterventionInfectionEnum;
  stage: SurgicalInterventionStageEnum;
  intervention_type: SurgicalInterventionTypeEnum;
}

export interface StoreClinicAttachmentFormRequest {
  microdistrict_id: number;
  doctor_id: number;
}

export interface CreateMedicalCaseFormRequest {
  title?: string | null;
  patient_id: number;
  current_appointment_id: number;
}

export interface UpdateMedicalCaseFormRequest {
  title: string;
}

export interface AttachAppointmentToMedicalCaseFormRequest {
  appointment_id: number;
}

export interface AttachDiseaseCodeToMedicalCaseFormRequest {
  disease_code: string;
  type: MedicalCaseDiseaseCodeTypeEnum;
  current_appointment_id?: number;
  kind?: DiseaseCodeKindEnum;
}

export interface DetachDiseaseCodeFromMedicalCaseFormRequest {
  /** Поле обязательное, если нет id */
  appointment_disease_code_id?: number;
  /** Поле обязательное, если нет appointment_disease_code_id */
  id?: number;
  /** Поле обязательное, если есть id */
  source?: 'appointment' | 'medical_case';
}

export interface CreateClinicalDiagnosisFormRequest {
  patient_id: number;
  type: ClinicalDiagnosisTypeEnum;
  diagnosis: string;
}

/**
 * Если у пациента есть диагноз, который не передан в запросе, он будет удалён.
 * Если передать диагноз без id, будет создан новый.
 * Если передать диагноз с id и значение будет отличаться, он будет отредактирован.
 */
export interface StoreClinicalDiagnosesRequest {
  patient_id: number;
  main?: {
    id: number | null;
    diagnosis: string;
  }[];
  additional?: {
    id: number | null;
    diagnosis: string;
  }[];
  background?: {
    id: number | null;
    diagnosis: string;
  }[];
  critical?: {
    id: number | null;
    diagnosis: string;
  }[];
  competing?: {
    id: number | null;
    diagnosis: string;
  }[];
}

/** Метаданные для ответов с пагинацией */
export interface PaginationMeta {
  per_page: number;
  total: number;
  current_page: number;
  last_page: number;
  from: number;
  to: number;
}

export interface ProductShortResource {
  id: number;
  title: string;
  article: string;
  price: number;
  description: string;
}

export type AIAssistantCallTypeEnum = 'doctor_examination' | 'anthropometry' | 'screening_cc3';

export type AIAssistantLanguageCodeEnum = 'ru-RU' | 'uz-UZ';

export interface AIAssistantProcessCallRequestBody {
  audio_file_id: number;
  type: AIAssistantCallTypeEnum;
  language_code: AIAssistantLanguageCodeEnum;
}

export type RequestAuthorizationCodeData = any;

export interface OauthAuthorizeParams {
  client_id: number;
  response_type: string;
  /** @format uri */
  redirect_uri: string;
  state?: string;
}

export interface OauthAuthorizeData {
  data: {
    /** @format uri */
    url: string;
  };
}

export interface GetSupersetUrlData {
  /** @format uri */
  url: string;
}

export interface ListAppointmentsParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  search?: string;
  patient?: number;
  main_contingent?: boolean;
  /** @minItems 1 */
  doctors?: number[];
  /** @minItems 1 */
  specialties?: number[];
  /** @minItems 1 */
  specialty_types?: SpecialtyTypeEnum[];
  /**
   * Фильтр по нижней и верхней границе возраста в годах.
   * Возвращает пациентов с возрастом `start_years <= age < end_years`
   * (нижняя граница включена, верхняя исключена). Если одно из значений
   * будет null или будет отсутствовать, то оно убирается из условий
   * (если start_years = null, условие будет age < end_years).
   * Количество условий влияет на скорость запроса. Если можно убрать верхнюю
   * или нижнюю границу не изменив смысл - лучше её убрать. Если можно
   * объединить несколько промежутков в один - лучше объединить их.
   * @minItems 1
   */
  age_ranges?: {
    start_years?: number | null;
    end_years?: number | null;
  }[];
  /** @minItems 1 */
  microdistrict_ids?: number[];
  has_microdistrict?: boolean;
  /** @minItems 1 */
  reasons?: AppointmentReasonEnum[];
  /** @minItems 1 */
  disease_codes?: string[];
  /**
   * Статусы приёмов, которые надо исключить из ответа.
   * Приоритет ниже, чем у `statuses`. Т.е. статусы из `statuses` будут
   * включены независимо от этого поля.
   * @minItems 1
   */
  excluded_statuses?: AppointmentStatusEnum[];
  /**
   * Статусы приёмов, которые надо включить в ответ.
   * Приоритет выше, чем у `excluded_statuses`. Т.е. статусы из этого поля
   * будут включены независимо от `excluded_statuses`
   * @minItems 1
   */
  statuses?: AppointmentStatusEnum[];
  at_home?: boolean;
  unplanned?: boolean;
  gender?: (PatientGenderEnum & 'undefined')[];
}

export interface GetPatientListParams {
  /**
   * Фильтр по нижней и верхней границе возраста в годах.
   * Возвращает пациентов с возрастом `start_years <= age < end_years`
   * (нижняя граница включена, верхняя исключена). Если одно из значений
   * будет null или будет отсутствовать, то оно убирается из условий
   * (если start_years = null, условие будет age < end_years).
   * Количество условий влияет на скорость запроса. Если можно убрать верхнюю
   * или нижнюю границу не изменив смысл - лучше её убрать. Если можно
   * объединить несколько промежутков в один - лучше объединить их.
   * @minItems 1
   */
  age_ranges?: {
    start_years?: number | null;
    end_years?: number | null;
  }[];
  microdistrict_ids?: number[];
  dispensaries?: (string | 'undefined' | 'defined')[];
  group?: (PatientGroupEnum | 'undefined')[];
  disabilities?: (string | 'undefined')[];
  gender?: (PatientGenderEnum | 'undefined')[];
  vaccines?: number[];
  pregnancy?: boolean;
  /** @minItems 1 */
  cvd_risk_groups?: CVDScreeningRiskGroupEnum[];
  /** @minItems 1 */
  endocrine_risk_groups?: EndocrineScreeningRiskGroupEnum[];
  /**
   * @min 1
   * @max 7
   */
  category?: number;
  main_contingent?: boolean;
  archived?: boolean;
  contingent?: boolean;
  /** @minItems 1 */
  categories?: number[];
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
}

export interface GetPatientStatisticsParams {
  /**
   * Фильтр по нижней и верхней границе возраста в годах.
   * Возвращает пациентов с возрастом `start_years <= age < end_years`
   * (нижняя граница включена, верхняя исключена). Если одно из значений
   * будет null или будет отсутствовать, то оно убирается из условий
   * (если start_years = null, условие будет age < end_years).
   * Количество условий влияет на скорость запроса. Если можно убрать верхнюю
   * или нижнюю границу не изменив смысл - лучше её убрать. Если можно
   * объединить несколько промежутков в один - лучше объединить их.
   * @minItems 1
   */
  age_ranges?: {
    start_years?: number | null;
    end_years?: number | null;
  }[];
  microdistricts?: (number | 'undefined')[];
  dispensaries?: (string | 'undefined' | 'defined')[];
  group?: (PatientGroupEnum | 'undefined')[];
  disabilities?: (string | 'undefined')[];
  gender?: (PatientGenderEnum | 'undefined')[];
  vaccines?: number[];
  pregnancy?: boolean;
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
}

export interface ExportPatientsParams {
  /**
   * Фильтр по нижней и верхней границе возраста в годах.
   * Возвращает пациентов с возрастом `start_years <= age < end_years`
   * (нижняя граница включена, верхняя исключена). Если одно из значений
   * будет null или будет отсутствовать, то оно убирается из условий
   * (если start_years = null, условие будет age < end_years).
   * Количество условий влияет на скорость запроса. Если можно убрать верхнюю
   * или нижнюю границу не изменив смысл - лучше её убрать. Если можно
   * объединить несколько промежутков в один - лучше объединить их.
   * @minItems 1
   */
  age_ranges?: {
    start_years?: number | null;
    end_years?: number | null;
  }[];
  microdistricts?: (number | 'undefined')[];
  dispensaries?: (string | 'undefined' | 'defined')[];
  group?: (PatientGroupEnum & 'undefined')[];
  disabilities?: (string | 'undefined')[];
  gender?: (PatientGenderEnum & 'undefined')[];
  vaccines?: number[];
  pregnancy?: boolean;
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
}

export type ExportPatientsData = any;

export interface GetPatientsForSelectParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
}

export interface SearchPatientsParams {
  /** Поиск по названию или другим полям */
  search?: string;
}

export interface GetDocumentsParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  userId: number;
}

export type BatchUpdatePatientRecordsData = any;

export type UpdateChildDetailsData = any;

export interface GetSickLeavesParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  closed?: boolean;
  appointment_id?: number | null;
  userId: number;
}

export interface ClinicListForSelectParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
}

export interface ListDoctorsForSelectParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  service_category_id?: number | null;
  microdistrict_id?: number | null;
  department_id?: number;
  is_active?: boolean;
}

export interface GetTreatmentCoursesParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  clinic_id?: number | null;
  user_id: number;
  source_id?: number;
  source_type?: TreatmentCourseSourceTypeEnum;
}

export type ExecuteTreatmentAppointmentData = any;

export interface ListWithOccupiedBedsParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  department_id: number;
}

export interface ListAvailableChamberByDateParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  department_id: number;
}

export interface ListForMassPrintingParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  start_date?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  end_date?: string;
  type?: MassPrintingTypeEnum;
  medical_case_id?: number | null;
  userId: number;
}

export interface GetPrintDataParams {
  appointment_ids?: number[];
  analysis_ids?: number[];
  userId: number;
}

export interface ListServicesParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
  service_category_id?: number;
  doctor_id?: number;
}

export interface ListIchiParams {
  parent_id?: number;
}

export interface ListHospitalizationsParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  start_date?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  end_date?: string;
  status?: HospitalizationStatusResource;
  doctor_id?: number;
  department_id?: number;
  type_list?: 'hospitalizations' | 'emergency_room';
  patient_id?: number;
  clinic_id?: number;
  medical_case_id?: number;
}

export interface ExportHospitalizationsParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  start_date?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  end_date?: string;
  status: HospitalizationStatusResource;
  doctor_id?: number;
  department_id?: number;
  type_list: 'hospitalizations' | 'emergency_room';
}

export type ExportHospitalizationsData = any;

export interface ListHospitalizationEpicrisesParams {
  hospitalization_id: number;
}

export interface GetAppointmentsForHospitalizationEpicrisisParams {
  hospitalization_id: number;
}

export interface GetOrdersForHospitalizationEpicrisisParams {
  hospitalization_id: number;
}

export type DeleteHospitalizationEpicrisisData = any;

export interface ListMedicalCasesForHospitalizationParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /** Поиск по названию или другим полям */
  search?: string;
  patient_id: number;
  hospitalization_id?: number;
}

export type DeleteHospitalizationSurgicalInterventionsData = any;

export type HospitalizationTransferPatientData = any;

export interface ListServiceCategoriesParams {
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
  type: 'parent' | 'children';
  parent_id?: number;
  only_analyses_category?: boolean;
  only_categories_clinic_uses?: boolean;
}

export interface IndexDoctorsWithReferralCountParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  search?: string | null;
  /** @minItems 1 */
  specialty_ids?: number[];
}

export interface ShowDoctorsReferralsParams {
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  start_date?: string;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  end_date?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /** @minItems 1 */
  specialty_ids?: number[];
  userId: number;
}

export interface GetAppointmentsStatisticsParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export interface GetAppointmentCountByGenderParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export interface GetAppointmentCountOnTimeParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export interface GetAppointmentCountByAgeParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export interface GetAppointmentCountByReasonParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export interface ListDiseaseCodesWithCountParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  gender?: PatientGenderEnum;
  start_age?: number;
  end_age?: number;
}

export interface GetDiseaseCodesCountParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export interface GetDiseaseCodesCountByAgeParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export interface GetDiseaseCodesCountByGenderByAgeParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export interface GetDiseaseCodesCountByGenderParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
}

export interface ListDoctorsWithAppointmentsCountParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
}

export interface ListSpecialtiesWithAppointmentsCountParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
}

export interface GetCvdScreeningCompletionStatisticsParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at: string;
}

export interface GetCvdScreeningRiskGroupStatisticsParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at: string;
}

export interface GetCvdScreeningCompletionByDateParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at: string;
}

export interface GetEndocrineScreeningCompletionStatisticsParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at: string;
}

export interface GetEndocrineScreeningRiskGroupStatisticsParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at: string;
}

export interface GetEndocrineScreeningCompletionByDateParams {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at: string;
}

export interface ListAnalysesParams {
  /** Поиск по названию или другим полям */
  search?: string;
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /** @minItems 1 */
  biomaterial_types?: BiomaterialType[];
  /** @minItems 1 */
  group_service_ids?: number[];
  sort_order?: 'asc' | 'desc';
}

export interface GetMedicalCasesParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at?: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at?: string;
  /** Поиск по названию или другим полям */
  search?: string;
  /** ID приёма, который нужно закрепить (текущий приём) */
  appointment_id?: number;
  patientId: number;
}

export type AttachAppointmentToMedicalCaseData = any;

export interface CalculateCvdScreeningResultParams {
  anthropometry?: {
    height: number;
    weight: number;
    systolic_pressure: number;
  };
  screening?: {
    nicotine_consumption: 'no' | 'yes' | 'stopped';
  };
  patientId: number;
}

export interface CalculateEndocrineScreeningResultParams {
  anthropometry?: {
    waist_circumference: number;
  };
  screening?: {
    passive_lifestyle: boolean;
    diabetes_in_relatives: 'immediate_relatives' | 'distant_relatives' | 'none';
  };
  patientId: number;
}

export interface ListDispensariesParams {
  patient_id: number;
  /**
   * @min 0
   * @max 1
   */
  active?: number;
}

export interface GetTreatmentDiariesParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  hospitalization_id?: number | null;
}

export interface ListClinicalDiagnosesParams {
  patient_id: number;
}

export interface ListClinicalDiagnosesByTypeParams {
  patient_id: number;
}

export interface ExportF39Params {
  /**
   * Нижняя граница для фильтрации по дате
   * @format date
   */
  start_at: string;
  /**
   * Верхняя граница для фильтрации по дате
   * @format date
   */
  end_at: string;
}

export type ExportF39Data = any;

export type UpdateEmployeeStatusData = any;

export interface SearchEmployeeByPinflOrPassportParams {
  pinfl?: string;
  passport_serial?: string;
  passport_number?: string;
  birthdate?: string;
}

export interface ListVacationsParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  userId: number;
}

export type DeleteVacationData = any;

export interface MotherChildCreateOrUpdatePregnancyLaboratoryAnalysisPayload {
  content: string;
}

export interface MotherChildCreateOrUpdatePregnancyLaboratoryAnalysisData {
  content: string;
}

export interface MotherChildGetPregnancyLaboratoryAnalysisData {
  content: string;
}

export interface MotherChildUploadPregnancyProgressFilesPayload {
  /** Array of files to be uploaded */
  files: File[];
}

export interface MotherChildUploadPregnancyProgressFilesData {
  uploaded_files: FileResource[];
}

export interface GetProductsListParams {
  /**
   * Номер страницы
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Количество элементов на странице
   * @min 1
   * @default 15
   */
  per_page?: number;
  /** Поиск по названию или другим полям */
  search?: string;
}

export interface AiAssistantProcessCallData {
  id: string;
}

export interface AiAssistantSetCompletedAiAssistantTutorialFlagPayload {
  flag: boolean;
}

export interface AiAssistantSetCompletedAiAssistantTutorialFlagData {
  completed_ai_assistant_tutorial: boolean;
}

export interface AiAssistantGetCompletedAiAssistantTutorialFlagData {
  completed_ai_assistant_tutorial: boolean;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://stage.zordoc.uz/api/mis/v2',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title ZorDoc Api v2
 * @version 1.0.0
 * @baseUrl https://stage.zordoc.uz/api/mis/v2
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags Авторизация
     * @name RequestAuthorizationCode
     * @summary Запросить код для авторизации через приложение
     * @request POST:/auth/request-code
     * @secure
     */
    requestAuthorizationCode: (data: RequestAuthorizationCode, params: RequestParams = {}) =>
      this.request<RequestAuthorizationCodeData, void>({
        path: `/auth/request-code`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name LoginWithOtp
     * @summary Авторизация по one time password
     * @request POST:/auth/login-with-otp
     * @secure
     */
    loginWithOtp: (data: AuthWithOPTRequestBody, params: RequestParams = {}) =>
      this.request<AuthWithOPTResource, void>({
        path: `/auth/login-with-otp`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name ListRoles
     * @summary Получить список ролей авторизованного пользователя
     * @request GET:/auth/list-roles
     * @secure
     */
    listRoles: (params: RequestParams = {}) =>
      this.request<
        {
          data: RoleResource[];
        },
        any
      >({
        path: `/auth/list-roles`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name LoginAsRole
     * @summary Авторизация под выбранной ролью
     * @request POST:/auth/login-as-role
     * @secure
     */
    loginAsRole: (data: LoginAsRoleRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          access_token: string;
          refresh_token: string;
          token_type: string;
          user: object;
        },
        any
      >({
        path: `/auth/login-as-role`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name ListRolesJwt
     * @summary Получить список ролей авторизованного пользователя
     * @request GET:/auth/list-roles-jwt
     * @secure
     */
    listRolesJwt: (params: RequestParams = {}) =>
      this.request<
        {
          data: RoleResource[];
        },
        any
      >({
        path: `/auth/list-roles-jwt`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name LoginAsRoleJwt
     * @summary Авторизация под выбранной ролью
     * @request POST:/auth/login-as-role-jwt
     * @secure
     */
    loginAsRoleJwt: (data: LoginAsRoleRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          access_token: string;
          refresh_token: string;
          token_type: string;
          user: object;
        },
        any
      >({
        path: `/auth/login-as-role-jwt`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  oauth = {
    /**
     * No description
     *
     * @tags Авторизация
     * @name OauthAuthorize
     * @request GET:/oauth/authorize
     * @secure
     */
    oauthAuthorize: (query: OauthAuthorizeParams, params: RequestParams = {}) =>
      this.request<OauthAuthorizeData, any>({
        path: `/oauth/authorize`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  cloudStudy = {
    /**
     * No description
     *
     * @tags Cloud Study
     * @name GetCloudStudyUrl
     * @summary Запросить ссылку для авторизации в Cloud Study
     * @request GET:/cloud-study/get-url
     * @secure
     */
    getCloudStudyUrl: (params: RequestParams = {}) =>
      this.request<CloudStudyResource, any>({
        path: `/cloud-study/get-url`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  superset = {
    /**
     * No description
     *
     * @tags Superset
     * @name GetSupersetUrl
     * @summary Запросить ссылку для авторизации в Superset
     * @request GET:/superset/url
     * @secure
     */
    getSupersetUrl: (params: RequestParams = {}) =>
      this.request<GetSupersetUrlData, any>({
        path: `/superset/url`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  features = {
    /**
     * No description
     *
     * @tags Feature флаги
     * @name GetCurrentFeatures
     * @summary Получить feature флаги авторизованного пользователя
     * @request GET:/features/current
     * @secure
     */
    getCurrentFeatures: (params: RequestParams = {}) =>
      this.request<
        {
          data: FeatureFlagsResponse;
        },
        any
      >({
        path: `/features/current`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  appointments = {
    /**
     * No description
     *
     * @tags Приемы
     * @name ListAppointments
     * @summary Получить список приемов
     * @request GET:/appointments
     * @secure
     */
    listAppointments: (query: ListAppointmentsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (AppointmentResource & {
            invoice: InvoiceStatusResource;
            patient: PatientResource;
            doctor: DoctorResource & {
              specialties: SpecialtyResource[];
              microdistricts: MicroDistrictResource[];
            };
            services: GroupServiceTitleResource[];
            medical_case: MedicalCaseResource;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/appointments`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Приемы
     * @name CreateAppointment
     * @summary Создать прием
     * @request POST:/appointments
     * @secure
     */
    createAppointment: (data: CreateAppointmentRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: AppointmentResource & {
            doctor: DoctorResource & {
              specialties: SpecialtyResource[];
            };
            user: PatientResource;
          };
        },
        ErrorResource
      >({
        path: `/appointments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Приемы
     * @name GetAppointment
     * @summary Получить осмотр
     * @request GET:/appointments/{appointmentId}
     * @secure
     */
    getAppointment: (appointmentId: number, params: RequestParams = {}) =>
      this.request<AppointmentWithDiseaseCodesResource, any>({
        path: `/appointments/${appointmentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Приемы
     * @name UpdateAppointment
     * @summary Редактировать осмотр
     * @request PATCH:/appointments/{appointmentId}
     * @secure
     */
    updateAppointment: (
      appointmentId: number,
      data: UpdateAppointment,
      params: RequestParams = {}
    ) =>
      this.request<AppointmentWithDiseaseCodesResource, any>({
        path: `/appointments/${appointmentId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Приемы
     * @name GetAppointmentDocuments
     * @summary Получить документы прикрепленыу к приему
     * @request GET:/appointments/{appointmentId}/documents
     * @secure
     */
    getAppointmentDocuments: (appointmentId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: FileResource[];
        },
        any
      >({
        path: `/appointments/${appointmentId}/documents`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Приемы
     * @name UpdateAppointmentDocuments
     * @summary Обновить документы прикрепленные к приему
     * @request PUT:/appointments/{appointmentId}/documents
     * @secure
     */
    updateAppointmentDocuments: (
      appointmentId: number,
      data: UpdateAppointmentDocuments,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: FileResource[];
        },
        any
      >({
        path: `/appointments/${appointmentId}/documents`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Приемы
     * @name ShowAppointmentData
     * @summary Получить данные к приему
     * @request GET:/appointments/{appointmentId}/data
     * @secure
     */
    showAppointmentData: (appointmentId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: AppointmentResource & {
            doctor: DoctorResource & {
              specialties: SpecialtyResource[];
            };
            appointment_data?: AppointmentDataResource;
          };
        },
        any
      >({
        path: `/appointments/${appointmentId}/data`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Приемы
     * @name UpdateAppointmentData
     * @summary Обновить данные осмотра
     * @request PUT:/appointments/{appointmentId}/data
     * @secure
     */
    updateAppointmentData: (
      appointmentId: number,
      data: UpdateAppointmentDataRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: AppointmentDataResource;
        },
        any
      >({
        path: `/appointments/${appointmentId}/data`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  patients = {
    /**
     * No description
     *
     * @tags Пациенты
     * @name GetPatientList
     * @summary Получить список пациентов
     * @request GET:/patients
     * @secure
     */
    getPatientList: (query: GetPatientListParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (PatientResource & {
            dispensaries: (DispensaryResource & {
              disease_code?: DiseaseCode;
            })[];
            disabilities: DisabilityResponse[];
            microdistrict: MicroDistrictResource;
            cvd_screening_result: CVDScreeningResultResource;
            endocrine_screening_result: EndocrineScreeningResultResource;
            active_attached_microdistrict?: MicroDistrictResource;
            clinical_diagnoses: ClinicalDiagnosisResource[];
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/patients`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name GetPatientStatistics
     * @summary Получить список пациентов
     * @request GET:/patients/statistics
     * @secure
     */
    getPatientStatistics: (query: GetPatientStatisticsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: PatientStatisticsResource;
        },
        any
      >({
        path: `/patients/statistics`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name ExportPatients
     * @summary Получить список пациентов в Excel
     * @request GET:/patients/export
     * @secure
     */
    exportPatients: (query: ExportPatientsParams, params: RequestParams = {}) =>
      this.request<ExportPatientsData, any>({
        path: `/patients/export`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name GetPatientsForSelect
     * @summary Получить список пациентов для селекта
     * @request GET:/patients/select
     * @secure
     */
    getPatientsForSelect: (query: GetPatientsForSelectParams, params: RequestParams = {}) =>
      this.request<
        {
          data?: (PatientSelectResource & {
            active_attached_clinic: ClinicTitleResponse;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        any
      >({
        path: `/patients/select`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name SearchPatients
     * @summary Поиск пациентов
     * @request GET:/patients/search
     * @secure
     */
    searchPatients: (query: SearchPatientsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: PatientSearchResourceNew[];
        },
        any
      >({
        path: `/patients/search`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Обязательны паспорт + дата рождения, пинфл + дата рождения, idCard + дата рождения, номер медкарты (MED ID), так же если есть фича флаг у клиники то к пинфл и паспорта + фото
     *
     * @tags Пациенты
     * @name SearchPatient
     * @summary Поиск пациенты по базе ZorDoc и МВД
     * @request POST:/patients/search-patient
     * @secure
     */
    searchPatient: (data: PatientSearchRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: PatientSearchResource;
        },
        ErrorResource
      >({
        path: `/patients/search-patient`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Должно присутствовать одна из комбинаций данных: - Номер медкарты - ПИНФЛ - Дата рождения + документ удостоверяющий личность - Дата рождения + свидетельство о рождении
     *
     * @tags Пациенты
     * @name FindPatientByDocuments
     * @summary Поиск пациенты по базе ZorDoc и МВД
     * @request POST:/patients/find-by-documents
     * @secure
     */
    findPatientByDocuments: (data: FindPatientByDocumentsRequest, params: RequestParams = {}) =>
      this.request<
        {
          data: PatientSearchResource & {
            avatar: FileResource;
          };
        },
        ErrorResource
      >({
        path: `/patients/find-by-documents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name GetAnthropometryPatient
     * @summary Получить антропометрию пациента
     * @request GET:/patients/{userId}/anthropometry
     * @secure
     */
    getAnthropometryPatient: (userId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: AnthropometryResource & {
            creator: UserNameResource;
          };
        },
        ErrorResource
      >({
        path: `/patients/${userId}/anthropometry`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name CreateAnthropometryPatient
     * @summary Создать (обновить) антропометрию пациента
     * @request POST:/patients/{userId}/anthropometry
     * @secure
     */
    createAnthropometryPatient: (
      userId: number,
      data: CreateAnthropometryRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: AnthropometryResource & {
            creator: UserNameResource;
          };
        },
        ErrorResource
      >({
        path: `/patients/${userId}/anthropometry`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Больничные
     * @name GetDocuments
     * @summary Получить документы пациента
     * @request GET:/patients/{userId}/documents
     * @secure
     */
    getDocuments: ({ userId, ...query }: GetDocumentsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: SickLeaveDocumentListResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource | void
      >({
        path: `/patients/${userId}/documents`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Реферальные источники
     * @name GetPatientsReferrer
     * @summary Получить реферальный источник, который направил пациента в клинику
     * @request GET:/patients/{userId}/referred-by
     * @secure
     */
    getPatientsReferrer: (userId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: ReferrerTitleResource;
        },
        void
      >({
        path: `/patients/${userId}/referred-by`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Приемы
     * @name GetPatientsCurrentAppointment
     * @summary Получить последний активный приём без записи
     * @request GET:/patients/{userId}/current-appointment
     * @secure
     */
    getPatientsCurrentAppointment: (userId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: AppointmentWithDiseaseCodesResource;
        },
        void
      >({
        path: `/patients/${userId}/current-appointment`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Учеты
     * @name BatchUpdatePatientRecords
     * @summary Массово заполнить учеты пациентов
     * @request PATCH:/patients/{userId}/records/batch-update
     * @secure
     */
    batchUpdatePatientRecords: (
      userId: number,
      data: BatchUpdateResource,
      params: RequestParams = {}
    ) =>
      this.request<BatchUpdatePatientRecordsData, ErrorResource>({
        path: `/patients/${userId}/records/batch-update`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Привязка к клиникам
     * @name CreatePatientsClinicAttachments
     * @summary Прикрепить пациента к клинике
     * @request POST:/patients/{userId}/clinic-attachments
     * @secure
     */
    createPatientsClinicAttachments: (
      userId: number,
      data: StoreClinicAttachmentFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: ClinicAttachmentResource & {
            clinic: ClinicTitleResponse;
            microdistrict: MicroDistrictResource;
            doctor: DoctorNameResource;
          };
        },
        any
      >({
        path: `/patients/${userId}/clinic-attachments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Привязка к клиникам
     * @name GetPatientsClinicAttachmentsHistory
     * @summary Получить все прикрепления пациента к клиникам
     * @request GET:/patients/{userId}/clinic-attachments/history
     * @secure
     */
    getPatientsClinicAttachmentsHistory: (userId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: (ClinicAttachmentResource & {
            clinic: ClinicTitleResponse;
            microdistrict: MicroDistrictResource;
            doctor: DoctorNameResource;
          })[];
        },
        any
      >({
        path: `/patients/${userId}/clinic-attachments/history`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name SearchPatientByImage
     * @summary Поиск пациента по фото
     * @request POST:/patients/search-by-image
     * @secure
     */
    searchPatientByImage: (data: SearchPatientByImageRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: PatientResource;
        },
        ErrorResource
      >({
        path: `/patients/search-by-image`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name PatientUpdateImageInMyidBox
     * @summary Обновить фото пациента в MyId Box
     * @request POST:/patients/{patientId}/update-image
     * @secure
     */
    patientUpdateImageInMyidBox: (
      patientId: number,
      data: PatientUpdateImageInMyidBoxRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: PatientResource;
        },
        any
      >({
        path: `/patients/${patientId}/update-image`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name FaceVerification
     * @summary Верификация пациента по изображению лица
     * @request POST:/patients/{patientId}/face-verification
     * @secure
     */
    faceVerification: (
      patientId: number,
      data: FaceVerificationRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          result: boolean;
        },
        ErrorResource
      >({
        path: `/patients/${patientId}/face-verification`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name TokenVerification
     * @summary Верификация пациента по одноразовому токену
     * @request POST:/patients/{patientId}/token-verification
     * @secure
     */
    tokenVerification: (
      patientId: number,
      data: TokenVerificationRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          result: boolean;
        },
        ErrorResource
      >({
        path: `/patients/${patientId}/token-verification`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name ListPatientBeneficiaries
     * @summary Получить льготные категории пациента
     * @request GET:/patients/{patientId}/beneficiaries
     * @secure
     */
    listPatientBeneficiaries: (patientId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: (BeneficiaryResource & {
            files: FileResource[];
          })[];
        },
        any
      >({
        path: `/patients/${patientId}/beneficiaries`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты
     * @name UpdateChildDetails
     * @summary Изменить ФИО новорожденного
     * @request PATCH:/patients/newborn-children/{childId}
     * @secure
     */
    updateChildDetails: (
      childId: number,
      data: UpdateChildDetailsResource,
      params: RequestParams = {}
    ) =>
      this.request<UpdateChildDetailsData, any>({
        path: `/patients/newborn-children/${childId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Больничные
     * @name GetSickLeaves
     * @summary Получить больничные пациента
     * @request GET:/patients/{userId}/sick-leaves
     * @secure
     */
    getSickLeaves: ({ userId, ...query }: GetSickLeavesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: SickLeaveListResource[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        void
      >({
        path: `/patients/${userId}/sick-leaves`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Массовые распечатки
     * @name ListForMassPrinting
     * @summary Получить доступные записи пациента для листинга распечатки
     * @request GET:/patients/{user_id}/list-for-printing
     * @secure
     */
    listForMassPrinting: (
      { userId, ...query }: ListForMassPrintingParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: (MassPrintingResource & {
            doctor: DoctorResource;
            group_services: GroupServiceTitleResource[];
          })[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/patients/${userId}/list-for-printing`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Массовые распечатки
     * @name GetPrintData
     * @summary Получить доступные записи пациента для массовой распечатки
     * @request GET:/patients/{user_id}/print-data
     * @secure
     */
    getPrintData: ({ userId, ...query }: GetPrintDataParams, params: RequestParams = {}) =>
      this.request<
        {
          data?: (MassPrintingResource & {
            doctor: DoctorResource & {
              specialties: SpecialtyResource[];
            };
            group_services: GroupServiceTitleResource[];
            inspection_card?: InspectionCardResource;
            disease_codes?: AppointmentDiseaseCodeResource[];
            order?: OrderResource & {
              biomaterials: (BiomaterialResource & {
                abstract_biomaterial: AbstractBiomaterialResource & {
                  handbook_biomaterial: HandbookBiomaterialResource & {
                    laboratory_department: LaboratoryDepartmentResource;
                  };
                };
                studys: (StudyResource & {
                  analysis: AnalysisResource;
                  referencesValue: ReferencesValueResource & {
                    referencesGroup: ReferencesGroupResource;
                  };
                })[];
              })[];
            };
          })[];
        },
        ErrorResource
      >({
        path: `/patients/${userId}/print-data`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name GetMedicalCases
     * @summary Получить список обращений пациента
     * @request GET:/patients/{patientId}/medical-cases
     * @secure
     */
    getMedicalCases: ({ patientId, ...query }: GetMedicalCasesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: MedicalCaseListResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/patients/${patientId}/medical-cases`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Скрининги
     * @name ListPatientsScreenings
     * @summary Получить список скринингов пациента
     * @request GET:/patients/{patientId}/screenings
     * @secure
     */
    listPatientsScreenings: (patientId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: ScreeningStatusResource[];
        },
        any
      >({
        path: `/patients/${patientId}/screenings`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Скрининги
     * @name GetPatientsCvdScreening
     * @summary Получить последний скрининг ССЗ
     * @request GET:/patients/{patientId}/screenings/cvd
     * @secure
     */
    getPatientsCvdScreening: (patientId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: CVDScreeningResource & {
            anthropometry: AnthropometryResource;
          };
        },
        any
      >({
        path: `/patients/${patientId}/screenings/cvd`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Скрининги
     * @name UpdatePatientsCvdScreening
     * @summary Обновить скрининг ССЗ
     * @request POST:/patients/{patientId}/screenings/cvd
     * @secure
     */
    updatePatientsCvdScreening: (
      patientId: number,
      data: UpdatePatientsCvdScreeningRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: CVDScreeningResource & {
            anthropometry: AnthropometryResource;
          };
        },
        any
      >({
        path: `/patients/${patientId}/screenings/cvd`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Скрининги
     * @name CalculateCvdScreeningResult
     * @summary Рассчитать результат скрининга ССЗ
     * @request GET:/patients/{patientId}/screenings/cvd/result
     * @secure
     */
    calculateCvdScreeningResult: (
      { patientId, ...query }: CalculateCvdScreeningResultParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          result: number;
          risk_group: CVDScreeningRiskGroupEnum;
        },
        any
      >({
        path: `/patients/${patientId}/screenings/cvd/result`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Скрининги
     * @name GetPatientsEndocrineScreening
     * @summary Получить последний скрининг сахарного диабета
     * @request GET:/patients/{patientId}/screenings/endocrine
     * @secure
     */
    getPatientsEndocrineScreening: (patientId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: EndocrineScreeningResource & {
            anthropometry: AnthropometryResource;
          };
        },
        any
      >({
        path: `/patients/${patientId}/screenings/endocrine`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Скрининги
     * @name UpdatePatientsEndocrineScreening
     * @summary Обновить скрининг сахарного диабета
     * @request POST:/patients/{patientId}/screenings/endocrine
     * @secure
     */
    updatePatientsEndocrineScreening: (
      patientId: number,
      data: UpdatePatientsEndocrineScreeningRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: EndocrineScreeningResource & {
            anthropometry: AnthropometryResource;
          };
        },
        any
      >({
        path: `/patients/${patientId}/screenings/endocrine`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Скрининги
     * @name CalculateEndocrineScreeningResult
     * @summary Рассчитать результат скрининга сахарного диабета
     * @request GET:/patients/{patientId}/screenings/endocrine/result
     * @secure
     */
    calculateEndocrineScreeningResult: (
      { patientId, ...query }: CalculateEndocrineScreeningResultParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          result: number;
          risk_group: EndocrineScreeningRiskGroupEnum;
        },
        any
      >({
        path: `/patients/${patientId}/screenings/endocrine/result`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Протокол родов
     * @name GetNewbornChildrenResponse
     * @summary Получить список Новорожденных
     * @request GET:/patients/{hospitalizationId}/newborn-children
     * @secure
     */
    getNewbornChildrenResponse: (hospitalizationId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: NewbornChildrenResource[];
        },
        any
      >({
        path: `/patients/${hospitalizationId}/newborn-children`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  sickLeaves = {
    /**
     * No description
     *
     * @tags Пациенты, Больничные
     * @name CreateSickLeave
     * @summary Создать больничный лист
     * @request POST:/sick-leaves
     * @secure
     */
    createSickLeave: (data: CreateSickLeaveRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: SickLeaveResource & {
            disease_code: DiseaseCodeResource;
            leave_periods: (SickLeavePeriodResource & {
              doctor: DoctorNameResource;
            })[];
            files: FileResource[];
          };
        },
        any
      >({
        path: `/sick-leaves`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Больничные
     * @name GetSickLeave
     * @summary Получить больничный лист
     * @request GET:/sick-leaves/{sickLeaveId}
     * @secure
     */
    getSickLeave: (sickLeaveId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: SickLeaveResource & {
            disease_code: DiseaseCodeResource;
            leave_periods: (SickLeavePeriodResource & {
              doctor: DoctorNameResource;
            })[];
            files: FileResource[];
          };
        },
        any
      >({
        path: `/sick-leaves/${sickLeaveId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Больничные
     * @name UpdateSickLeave
     * @summary Редактировать больничный лист
     * @request PATCH:/sick-leaves/{sickLeaveId}
     * @secure
     */
    updateSickLeave: (
      sickLeaveId: number,
      data: UpdateSickLeaveRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: SickLeaveResource & {
            disease_code: DiseaseCodeResource;
            leave_periods: (SickLeavePeriodResource & {
              doctor: DoctorNameResource;
            })[];
            files: FileResource[];
          };
        },
        ErrorResource
      >({
        path: `/sick-leaves/${sickLeaveId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Больничные
     * @name CloseSickLeave
     * @summary Закрыть больничный
     * @request POST:/sick-leaves/{sickLeaveId}/close
     * @secure
     */
    closeSickLeave: (
      sickLeaveId: number,
      data: CloseSickLeaveRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: SickLeaveResource & {
            disease_code: DiseaseCodeResource;
            leave_periods: (SickLeavePeriodResource & {
              doctor: DoctorNameResource;
            })[];
            files: FileResource[];
          };
        },
        ErrorResource
      >({
        path: `/sick-leaves/${sickLeaveId}/close`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  clinics = {
    /**
     * No description
     *
     * @tags Клиники
     * @name ClinicListForSelect
     * @summary Получить список привязанных клиник
     * @request GET:/clinics/select
     * @secure
     */
    clinicListForSelect: (query: ClinicListForSelectParams, params: RequestParams = {}) =>
      this.request<
        {
          data?: ClinicTitleResponse[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/clinics/select`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  doctors = {
    /**
     * No description
     *
     * @tags Врачи
     * @name ListDoctorsForSelect
     * @summary Получить список врачей для селекта
     * @request GET:/doctors/select
     * @secure
     */
    listDoctorsForSelect: (query: ListDoctorsForSelectParams, params: RequestParams = {}) =>
      this.request<
        {
          data?: DoctorNameResource[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/doctors/select`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  treatmentCourses = {
    /**
     * No description
     *
     * @tags Курс лечения
     * @name GetTreatmentCourses
     * @summary Получить список курсов лечения
     * @request GET:/treatment-courses
     * @secure
     */
    getTreatmentCourses: (query: GetTreatmentCoursesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (TreatmentCourseResource & {
            doctor: DoctorNameResource;
            clinic: ClinicTitleResponse;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/treatment-courses`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения
     * @name CreateTreatmentCourse
     * @summary Создать курс лечения
     * @request POST:/treatment-courses
     * @secure
     */
    createTreatmentCourse: (data: CreateTreatmentCourseRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: TreatmentCourseResource & {
            doctor: DoctorNameResource & {
              specialties: SpecialtyResource[];
            };
          };
        },
        any
      >({
        path: `/treatment-courses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения
     * @name GetTreatmentCourse
     * @summary Получить курс лечения
     * @request GET:/treatment-courses/{treatmentCourseId}
     * @secure
     */
    getTreatmentCourse: (treatmentCourseId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: TreatmentCourseResource & {
            doctor: DoctorNameResource & {
              specialties: SpecialtyResource[];
            };
            invoice?: InvoiceStatusResource;
          };
        },
        any
      >({
        path: `/treatment-courses/${treatmentCourseId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения
     * @name UpdateTreatmentCourse
     * @summary Редактировать курс лечения
     * @request PUT:/treatment-courses/{treatmentCourseId}
     * @secure
     */
    updateTreatmentCourse: (
      treatmentCourseId: number,
      data: UpdateTreatmentCourseRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: TreatmentCourseResource & {
            doctor: DoctorNameResource & {
              specialties: SpecialtyResource[];
            };
          };
        },
        any
      >({
        path: `/treatment-courses/${treatmentCourseId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения
     * @name GetTreatmentCourseInvoice
     * @summary Получить счёт курса лечения
     * @request GET:/treatment-courses/{treatmentCourseId}/invoice
     * @secure
     */
    getTreatmentCourseInvoice: (treatmentCourseId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: InvoiceStatusResource;
        },
        ErrorResource
      >({
        path: `/treatment-courses/${treatmentCourseId}/invoice`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения
     * @name FinishTreatmentCourse
     * @summary Завершить курс лечения
     * @request POST:/treatment-courses/{treatmentCourseId}/finish
     * @secure
     */
    finishTreatmentCourse: (
      treatmentCourseId: number,
      data: FinishTreatmentCourseRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: TreatmentCourseResource & {
            doctor: DoctorNameResource & {
              specialties: SpecialtyResource[];
            };
          };
        },
        any
      >({
        path: `/treatment-courses/${treatmentCourseId}/finish`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения
     * @name CancelTreatmentCourse
     * @summary Отменить курс лечения
     * @request POST:/treatment-courses/{treatmentCourseId}/cancel
     * @secure
     */
    cancelTreatmentCourse: (treatmentCourseId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: TreatmentCourseResource & {
            doctor: DoctorNameResource & {
              specialties: SpecialtyResource[];
            };
          };
        },
        any
      >({
        path: `/treatment-courses/${treatmentCourseId}/cancel`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения, Назначения
     * @name ListTreatmentAppointments
     * @summary Получить список назначений со слотами
     * @request GET:/treatment-courses/{treatmentCourseId}/appointments
     * @secure
     */
    listTreatmentAppointments: (treatmentCourseId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: TreatmentAppointmentWithSlotsResource[];
          dates: string[];
        },
        any
      >({
        path: `/treatment-courses/${treatmentCourseId}/appointments`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения, Назначения
     * @name CreateTreatmentAppointment
     * @summary Создать назначение
     * @request POST:/treatment-courses/{treatmentCourseId}/appointments
     * @secure
     */
    createTreatmentAppointment: (
      treatmentCourseId: number,
      data: CreateTreatmentAppointmentRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: TreatmentAppointmentResource & {
            group_service: GroupServiceTitleResource;
          };
        },
        any
      >({
        path: `/treatment-courses/${treatmentCourseId}/appointments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения, Назначения
     * @name GetTreatmentAppointment
     * @summary Получить назначение
     * @request GET:/treatment-courses/{treatmentCourseId}/appointments/{treatmentAppointmentId}
     * @secure
     */
    getTreatmentAppointment: (
      treatmentCourseId: number,
      treatmentAppointmentId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: TreatmentAppointmentResource & {
            group_service: GroupServiceTitleResource;
          };
        },
        any
      >({
        path: `/treatment-courses/${treatmentCourseId}/appointments/${treatmentAppointmentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения, Назначения
     * @name UpdateTreatmentAppointment
     * @summary Редактировать назначение
     * @request PUT:/treatment-courses/{treatmentCourseId}/appointments/{treatmentAppointmentId}
     * @secure
     */
    updateTreatmentAppointment: (
      treatmentCourseId: number,
      treatmentAppointmentId: number,
      data: UpdateTreatmentAppointmentRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: TreatmentAppointmentResource & {
            group_service: GroupServiceTitleResource;
          };
        },
        any
      >({
        path: `/treatment-courses/${treatmentCourseId}/appointments/${treatmentAppointmentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения, Назначения
     * @name CancelTreatmentAppointment
     * @summary Отменить назначение
     * @request POST:/treatment-courses/{treatmentCourseId}/appointments/{treatmentAppointmentId}/cancel
     * @secure
     */
    cancelTreatmentAppointment: (
      treatmentCourseId: number,
      treatmentAppointmentId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: TreatmentAppointmentResource & {
            group_service: GroupServiceTitleResource;
          };
        },
        any
      >({
        path: `/treatment-courses/${treatmentCourseId}/appointments/${treatmentAppointmentId}/cancel`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Курс лечения, Назначения
     * @name ExecuteTreatmentAppointment
     * @summary Отметить назначение выполненым
     * @request POST:/treatment-courses/{treatmentCourseId}/appointments/{treatmentAppointmentId}/execute
     * @secure
     */
    executeTreatmentAppointment: (
      treatmentCourseId: number,
      treatmentAppointmentId: number,
      data: ExecuteTreatmentAppointmentRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<ExecuteTreatmentAppointmentData, any>({
        path: `/treatment-courses/${treatmentCourseId}/appointments/${treatmentAppointmentId}/execute`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  chambers = {
    /**
     * No description
     *
     * @tags Палаты
     * @name ListWithOccupiedBeds
     * @summary Получить палаты с занятыми койка-местами
     * @request GET:/chambers/with-occupied-beds
     * @secure
     */
    listWithOccupiedBeds: (query: ListWithOccupiedBedsParams, params: RequestParams = {}) =>
      this.request<ChamberWithOccupiedBedsResource, any>({
        path: `/chambers/with-occupied-beds`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Палаты
     * @name ListAvailableChamberByDate
     * @summary Получить список дней с статусом занятости палат
     * @request GET:/chambers/available-by-date
     * @secure
     */
    listAvailableChamberByDate: (
      query: ListAvailableChamberByDateParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @format date */
          date: string;
          is_available: boolean;
        }[],
        ErrorResource
      >({
        path: `/chambers/available-by-date`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  groupServices = {
    /**
     * No description
     *
     * @tags Услуги
     * @name ListServices
     * @summary listServices
     * @request GET:/group-services
     * @secure
     */
    listServices: (query: ListServicesParams, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupServiceTitleResource[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/group-services`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  ichi = {
    /**
     * @description Эндпоин для получения справочника ICHI
     *
     * @tags Справочник ICHI, Хирургические вмешательства
     * @name ListIchi
     * @summary Получить справочник ICHI
     * @request GET:/ichi
     * @secure
     */
    listIchi: (query: ListIchiParams, params: RequestParams = {}) =>
      this.request<
        {
          data: ICHIResource[];
        },
        any
      >({
        path: `/ichi`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  hospitalizations = {
    /**
     * @description Одно из полей type_list, patient_id обязательно
     *
     * @tags Госпитализация
     * @name ListHospitalizations
     * @summary Получить список госпитализаций/ПП
     * @request GET:/hospitalizations
     * @secure
     */
    listHospitalizations: (query: ListHospitalizationsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse;
            planned_department: DepartmentResource;
            department?: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorNameResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/hospitalizations`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name CreateHospitalization
     * @summary Создать госпитализацию
     * @request POST:/hospitalizations
     * @secure
     */
    createHospitalization: (data: HospitalizationCreateRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse & {
              logo: FileResource;
            };
            planned_department: DepartmentResource;
            department: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
            income_epicrisis: HospitalizationEpicrisisResource;
            medical_case: MedicalCaseResource;
            files: FileResource[];
            clinical_diagnoses?: HospitalizationClinicalDiagnosisResource[];
            redirections?: HospitalizationRedirectionsResource[];
          };
        },
        any
      >({
        path: `/hospitalizations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name ApproveHospitalization
     * @summary Метод одобрения направления
     * @request POST:/hospitalizations/{hospitalizationId}/approve-hospitalization
     * @secure
     */
    approveHospitalization: (hospitalizationId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse & {
              logo: FileResource;
            };
            planned_department: DepartmentResource;
            department: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
            income_epicrisis: HospitalizationEpicrisisResource;
            medical_case: MedicalCaseResource;
            files: FileResource[];
            clinical_diagnoses?: HospitalizationClinicalDiagnosisResource[];
            redirections?: HospitalizationRedirectionsResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/approve-hospitalization`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name RedirectToHospitalization
     * @summary Метод перенаправления в госпитализации
     * @request POST:/hospitalizations/{hospitalizationId}/redirect-to-hospitalization
     * @secure
     */
    redirectToHospitalization: (
      hospitalizationId: number,
      data: RedirectToHospitalizationRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse & {
              logo: FileResource;
            };
            planned_department: DepartmentResource;
            department: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
            income_epicrisis: HospitalizationEpicrisisResource;
            medical_case: MedicalCaseResource;
            files: FileResource[];
            clinical_diagnoses?: HospitalizationClinicalDiagnosisResource[];
            redirections?: HospitalizationRedirectionsResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/redirect-to-hospitalization`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name ExportHospitalizations
     * @summary Получить список госпитализаций/ПП в Excel
     * @request GET:/hospitalizations/export
     * @secure
     */
    exportHospitalizations: (query: ExportHospitalizationsParams, params: RequestParams = {}) =>
      this.request<ExportHospitalizationsData, any>({
        path: `/hospitalizations/export`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetHospitalizationMedicalCase
     * @summary Получить эпизод для ПП
     * @request GET:/hospitalizations/{hospitalizationId}/medical-case
     * @secure
     */
    getHospitalizationMedicalCase: (hospitalizationId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: MedicalCaseWithContentResource & {
            appointments: (MedicalCaseAppointmentResource & {
              appointment_data?: AppointmentDataResource;
              doctor: DoctorResource & {
                specialties: SpecialtyResource[];
              };
            })[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/medical-case`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name CreateHospitalizationWithoutDirection
     * @summary Создать ПП без направления
     * @request POST:/hospitalizations/without-direction
     * @secure
     */
    createHospitalizationWithoutDirection: (
      data: CreateHospitalizationWithoutDirectionRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse & {
              logo: FileResource;
            };
            planned_department: DepartmentResource;
            department: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
            income_epicrisis: HospitalizationEpicrisisResource;
            medical_case: MedicalCaseResource;
            files: FileResource[];
            clinical_diagnoses?: HospitalizationClinicalDiagnosisResource[];
            redirections?: HospitalizationRedirectionsResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/without-direction`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name InProgressStatusHospitalization
     * @summary Оформить приемный покой
     * @request PUT:/hospitalizations/{hospitalizationId}/in-progress
     * @secure
     */
    inProgressStatusHospitalization: (
      hospitalizationId: number,
      data: InProgressStatusHospitalizationRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse & {
              logo: FileResource;
            };
            planned_department: DepartmentResource;
            department: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
            income_epicrisis: HospitalizationEpicrisisResource;
            medical_case: MedicalCaseResource;
            files: FileResource[];
            clinical_diagnoses?: HospitalizationClinicalDiagnosisResource[];
            redirections?: HospitalizationRedirectionsResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/in-progress`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name ListUpdateHistoryHospitalization
     * @summary Получить историю обновления госпитализации
     * @request GET:/hospitalizations/{hospitalizationId}/update-history
     * @secure
     */
    listUpdateHistoryHospitalization: (hospitalizationId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: (HospitalizationHistoryResource & {
            creator: CreatorResource;
          })[];
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/update-history`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetHospitalization
     * @summary Получить госпитализацию по id
     * @request GET:/hospitalizations/{hospitalizationId}
     * @secure
     */
    getHospitalization: (hospitalizationId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse & {
              logo: FileResource;
            };
            planned_department: DepartmentResource;
            department: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
            income_epicrisis: HospitalizationEpicrisisResource;
            medical_case: MedicalCaseResource;
            files: FileResource[];
            clinical_diagnoses?: HospitalizationClinicalDiagnosisResource[];
            redirections?: HospitalizationRedirectionsResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name UpdateHospitalization
     * @summary Обновить госпитализацию
     * @request PUT:/hospitalizations/{hospitalizationId}
     * @secure
     */
    updateHospitalization: (
      hospitalizationId: number,
      data: HospitalizationUpdateRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse & {
              logo: FileResource;
            };
            planned_department: DepartmentResource;
            department: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
            income_epicrisis: HospitalizationEpicrisisResource;
            medical_case: MedicalCaseResource;
            files: FileResource[];
            clinical_diagnoses?: HospitalizationClinicalDiagnosisResource[];
            redirections?: HospitalizationRedirectionsResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name OptionalUpdateHospitalization
     * @summary Частично обновить госпитализацию
     * @request PATCH:/hospitalizations/{hospitalizationId}
     * @secure
     */
    optionalUpdateHospitalization: (
      hospitalizationId: number,
      data: OptionalUpdateHospitalizationRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse & {
              logo: FileResource;
            };
            planned_department: DepartmentResource;
            department: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
            income_epicrisis: HospitalizationEpicrisisResource;
            medical_case: MedicalCaseResource;
            files: FileResource[];
            clinical_diagnoses?: HospitalizationClinicalDiagnosisResource[];
            redirections?: HospitalizationRedirectionsResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name RejectHospitalization
     * @summary Отменить госпитализацию
     * @request PUT:/hospitalizations/{hospitalizationId}/reject
     * @secure
     */
    rejectHospitalization: (
      hospitalizationId: number,
      data: RejectHospitalizationRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse & {
              logo: FileResource;
            };
            planned_department: DepartmentResource;
            department: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
            income_epicrisis: HospitalizationEpicrisisResource;
            medical_case: MedicalCaseResource;
            files: FileResource[];
            clinical_diagnoses?: HospitalizationClinicalDiagnosisResource[];
            redirections?: HospitalizationRedirectionsResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/reject`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name ProvideHospitalization
     * @summary Завершить госпитализацию
     * @request PUT:/hospitalizations/{hospitalizationId}/provide
     * @secure
     */
    provideHospitalization: (
      hospitalizationId: number,
      data: ProvideHospitalizationRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationResource & {
            sending_clinic: ClinicTitleResponse;
            clinic: ClinicTitleResponse & {
              logo: FileResource;
            };
            planned_department: DepartmentResource;
            department: DepartmentResource;
            patient: PatientResource;
            creator: CreatorResource;
            doctor: DoctorResource;
            disease_codes: HospitalizationDiseaseCodeResource[];
            income_epicrisis: HospitalizationEpicrisisResource;
            medical_case: MedicalCaseResource;
            files: FileResource[];
            clinical_diagnoses?: HospitalizationClinicalDiagnosisResource[];
            redirections?: HospitalizationRedirectionsResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/provide`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name ListMedicalCasesForHospitalization
     * @summary Получить список эпизодов для создания госпитализации
     * @request GET:/hospitalizations/medical-cases
     * @secure
     */
    listMedicalCasesForHospitalization: (
      query: ListMedicalCasesForHospitalizationParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: (HospitalizationMedicalCaseListResource & {
            appointments: (MedicalCaseAppointmentResource & {
              doctor: DoctorResource & {
                specialties: SpecialtyResource[];
              };
            })[];
            orders: HospitalizationOrderResource[];
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/hospitalizations/medical-cases`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetHospitalizationTitlePage
     * @summary Получить титульный лист госпитализации
     * @request GET:/hospitalizations/{hospitalization}/title-page
     * @secure
     */
    getHospitalizationTitlePage: (hospitalization: number, params: RequestParams = {}) =>
      this.request<
        {
          data: HospitalizationTitlePageResource;
        },
        any
      >({
        path: `/hospitalizations/${hospitalization}/title-page`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name UpdateHospitalizationTitlePage
     * @summary Обновить титульный лист госпитализации
     * @request POST:/hospitalizations/{hospitalization}/title-page
     * @secure
     */
    updateHospitalizationTitlePage: (
      hospitalization: number,
      data: UpdateHospitalizationTitlePageRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationTitlePageResource;
        },
        any
      >({
        path: `/hospitalizations/${hospitalization}/title-page`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetHospitalizationStatisticsCard
     * @summary Получить статистическую карту госпитализации (форма 066)
     * @request GET:/hospitalizations/{hospitalization}/statistics-card
     * @secure
     */
    getHospitalizationStatisticsCard: (hospitalization: number, params: RequestParams = {}) =>
      this.request<
        {
          data: HospitalizationStatisticsCardResource;
        },
        any
      >({
        path: `/hospitalizations/${hospitalization}/statistics-card`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name UpdateHospitalizationStatisticsCard
     * @summary Обновить статистическую карту госпитализации (форма 066)
     * @request POST:/hospitalizations/{hospitalization}/statistics-card
     * @secure
     */
    updateHospitalizationStatisticsCard: (
      hospitalization: number,
      data: UpdateHospitalizationStatisticsCardRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationStatisticsCardResource;
        },
        any
      >({
        path: `/hospitalizations/${hospitalization}/statistics-card`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name ListHospitalizationTreatmentCourseAppointments
     * @summary Получить список назначений из курсов лечения, привязанных к госпитализации
     * @request GET:/hospitalizations/{hospitalization}/treatment-course-appointments
     * @secure
     */
    listHospitalizationTreatmentCourseAppointments: (
      hospitalization: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: TreatmentAppointmentTitleResource[];
        },
        ErrorResource
      >({
        path: `/hospitalizations/${hospitalization}/treatment-course-appointments`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация, Хирургические вмешательства
     * @name CreateHospitalizationSurgicalInterventions
     * @summary Создать историю хирургических вмешательств
     * @request POST:/hospitalizations/{hospitalizationId}/surgical-interventions
     * @secure
     */
    createHospitalizationSurgicalInterventions: (
      hospitalizationId: number,
      data: HospitalizationSurgicalInterventionsRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: SurgicalInterventionDetailResource & {
            surgical_team: (SurgicalInterventionDoctorResource & {
              doctor: DoctorNameResource;
            })[];
            medical_supplies: (SurgicalInterventionMedicalSupplyResource & {
              product: ProductResource;
              doctor: DoctorNameResource;
            })[];
            ichi: SurgicalInterventionICHIResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/surgical-interventions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация, Хирургические вмешательства
     * @name GetListHospitalizationSurgicalInterventions
     * @summary Получить историю хирургических вмешательств
     * @request GET:/hospitalizations/{hospitalizationId}/surgical-interventions
     * @secure
     */
    getListHospitalizationSurgicalInterventions: (
      hospitalizationId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: (HospitalizationSurgicalInterventionResource & {
            doctor: DoctorNameResource;
            ichi: SurgicalInterventionICHIResource[];
          })[];
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/surgical-interventions`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация, Хирургические вмешательства
     * @name GetHospitalizationSurgicalInterventionsDetails
     * @summary Получить детали хирургических вмешательств
     * @request GET:/hospitalizations/{hospitalizationId}/surgical-interventions/{surgicalInterventionId}
     * @secure
     */
    getHospitalizationSurgicalInterventionsDetails: (
      hospitalizationId: number,
      surgicalInterventionId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: SurgicalInterventionDetailResource & {
            surgical_team: (SurgicalInterventionDoctorResource & {
              doctor: DoctorNameResource;
            })[];
            medical_supplies: (SurgicalInterventionMedicalSupplyResource & {
              product: ProductResource;
              doctor: DoctorNameResource;
            })[];
            ichi: SurgicalInterventionICHIResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/surgical-interventions/${surgicalInterventionId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация, Хирургические вмешательства
     * @name DeleteHospitalizationSurgicalInterventions
     * @summary Удалить историю хирургических вмешательств
     * @request DELETE:/hospitalizations/{hospitalizationId}/surgical-interventions/{surgicalInterventionId}
     * @secure
     */
    deleteHospitalizationSurgicalInterventions: (
      hospitalizationId: number,
      surgicalInterventionId: number,
      params: RequestParams = {}
    ) =>
      this.request<DeleteHospitalizationSurgicalInterventionsData, any>({
        path: `/hospitalizations/${hospitalizationId}/surgical-interventions/${surgicalInterventionId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация, Хирургические вмешательства
     * @name UpdateHospitalizationSurgicalInterventions
     * @summary Обновить историю хирургических вмешательств
     * @request PUT:/hospitalizations/{hospitalizationId}/surgical-interventions/{surgicalInterventionId}
     * @secure
     */
    updateHospitalizationSurgicalInterventions: (
      hospitalizationId: number,
      surgicalInterventionId: number,
      data: HospitalizationSurgicalInterventionsRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: SurgicalInterventionDetailResource & {
            surgical_team: (SurgicalInterventionDoctorResource & {
              doctor: DoctorNameResource;
            })[];
            medical_supplies: (SurgicalInterventionMedicalSupplyResource & {
              product: ProductResource;
              doctor: DoctorNameResource;
            })[];
            ichi: SurgicalInterventionICHIResource[];
          };
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/surgical-interventions/${surgicalInterventionId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name ListHospitalizationPrintingData
     * @summary Получить контент госпитализации для распечатки
     * @request GET:/hospitalizations/{hospitalizationId}/printing-data
     * @secure
     */
    listHospitalizationPrintingData: (hospitalizationId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: (HospitalizationPrintingDataResource & {
            child_printing_data: HospitalizationPrintingDataResource[] | null;
          })[];
        },
        any
      >({
        path: `/hospitalizations/${hospitalizationId}/printing-data`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetHospitalizationPrinting
     * @summary Получить карты осмотра, анализы который выбрали для распечатки
     * @request POST:/hospitalizations/printing-data
     * @secure
     */
    getHospitalizationPrinting: (
      data: GetHospitalizationPrintingDataRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationShowPrintingDataResource;
        },
        any
      >({
        path: `/hospitalizations/printing-data`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetAppointmentsForIncludingInHospitalizationAppointment
     * @summary Получить данные приёмов, которые должны быть включены в приём
     * @request GET:/hospitalizations/{hospitalization}/include-appointments
     * @secure
     */
    getAppointmentsForIncludingInHospitalizationAppointment: (
      hospitalization: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: (HospitalizationAppointmentForInclusionResource & {
            services: GroupServiceTitleResource[];
          })[];
        },
        any
      >({
        path: `/hospitalizations/${hospitalization}/include-appointments`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetTreatmentAppointmentsForIncludingInHospitalizationAppointment
     * @summary Получить данные назначений, которые должны быть включены в приём
     * @request GET:/hospitalizations/{hospitalization}/include-treatment-appointments
     * @secure
     */
    getTreatmentAppointmentsForIncludingInHospitalizationAppointment: (
      hospitalization: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationTreatmentAppointmentForInclusionResource[];
        },
        any
      >({
        path: `/hospitalizations/${hospitalization}/include-treatment-appointments`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name HospitalizationTransferPatient
     * @summary Перевод пациента
     * @request POST:/hospitalizations/{hospitalization}/transfer-patient
     * @secure
     */
    hospitalizationTransferPatient: (
      hospitalization: number,
      data: HospitalizationTransferPatientRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<HospitalizationTransferPatientData, any>({
        path: `/hospitalizations/${hospitalization}/transfer-patient`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetHospitalizationTransferPatientHistory
     * @summary Получить историю перевода пациентов
     * @request GET:/hospitalizations/{hospitalization}/transfer-patient-history
     * @secure
     */
    getHospitalizationTransferPatientHistory: (
      hospitalization: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: (TransferPatientHistoryResource & {
            chamber: ChamberResource & {
              department: DepartmentResource;
            };
            doctor: DoctorNameResource;
          })[];
        },
        any
      >({
        path: `/hospitalizations/${hospitalization}/transfer-patient-history`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name ListHospitalizationPreviousChambers
     * @summary Получить предыдущие отделении
     * @request GET:/hospitalizations/{hospitalization}/previous-chambers
     * @secure
     */
    listHospitalizationPreviousChambers: (hospitalization: number, params: RequestParams = {}) =>
      this.request<
        {
          data: (TransferPatientHistoryResource & {
            chamber: ChamberResource & {
              department: DepartmentResource;
            };
            doctor: DoctorNameResource;
          })[];
        },
        any
      >({
        path: `/hospitalizations/${hospitalization}/previous-chambers`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  hospitalizationEpicrises = {
    /**
     * No description
     *
     * @tags Госпитализация
     * @name ListHospitalizationEpicrises
     * @summary Получить список эпикризов для госпитализации
     * @request GET:/hospitalization-epicrises
     * @secure
     */
    listHospitalizationEpicrises: (
      query: ListHospitalizationEpicrisesParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: (HospitalizationEpicrisisResource & {
            creator: CreatorResource;
          })[];
        },
        any
      >({
        path: `/hospitalization-epicrises`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name CreateHospitalizationEpicrisis
     * @summary Создать эпикриз для госпитализации
     * @request POST:/hospitalization-epicrises
     * @secure
     */
    createHospitalizationEpicrisis: (
      data: CreateHospitalizationEpicrisisRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationEpicrisisResource & {
            creator: CreatorResource;
            inspection_card_template?: InspectionCardTemplateTitleResource;
            files: FileResource[];
          };
        },
        any
      >({
        path: `/hospitalization-epicrises`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetAppointmentsForHospitalizationEpicrisis
     * @summary Получить данные приёмов, которые должны быть включены в эпикриз
     * @request GET:/hospitalization-epicrises/appointments
     * @secure
     */
    getAppointmentsForHospitalizationEpicrisis: (
      query: GetAppointmentsForHospitalizationEpicrisisParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationEpicrisisAppointmentResource[];
        },
        any
      >({
        path: `/hospitalization-epicrises/appointments`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetOrdersForHospitalizationEpicrisis
     * @summary Получить данные анализов, которые должны быть включены в эпикриз
     * @request GET:/hospitalization-epicrises/orders
     * @secure
     */
    getOrdersForHospitalizationEpicrisis: (
      query: GetOrdersForHospitalizationEpicrisisParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: (OrderResource & {
            biomaterials: (BiomaterialResource & {
              abstract_biomaterial: AbstractBiomaterialResource & {
                handbook_biomaterial: HandbookBiomaterialResource & {
                  laboratory_department: LaboratoryDepartmentResource;
                };
              };
              studys: (StudyResource & {
                analysis: AnalysisResource;
                referencesValue: ReferencesValueResource & {
                  referencesGroup: ReferencesGroupResource;
                };
              })[];
            })[];
          })[];
        },
        any
      >({
        path: `/hospitalization-epicrises/orders`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name GetHospitalizationEpicrisis
     * @summary Получить эпикриз
     * @request GET:/hospitalization-epicrises/{epicrisisId}
     * @secure
     */
    getHospitalizationEpicrisis: (epicrisisId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: HospitalizationEpicrisisResource & {
            creator: CreatorResource;
            inspection_card_template?: InspectionCardTemplateTitleResource;
            files: FileResource[];
          };
        },
        any
      >({
        path: `/hospitalization-epicrises/${epicrisisId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name UpdateHospitalizationEpicrisis
     * @summary Обновить эпикриз для госпитализации
     * @request PUT:/hospitalization-epicrises/{epicrisisId}
     * @secure
     */
    updateHospitalizationEpicrisis: (
      epicrisisId: number,
      data: UpdateHospitalizationEpicrisisRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: HospitalizationEpicrisisResource & {
            creator: CreatorResource;
            inspection_card_template?: InspectionCardTemplateTitleResource;
            files: FileResource[];
          };
        },
        any
      >({
        path: `/hospitalization-epicrises/${epicrisisId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация
     * @name DeleteHospitalizationEpicrisis
     * @summary Удалить эпикриз
     * @request DELETE:/hospitalization-epicrises/{epicrisisId}
     * @secure
     */
    deleteHospitalizationEpicrisis: (epicrisisId: number, params: RequestParams = {}) =>
      this.request<DeleteHospitalizationEpicrisisData, any>({
        path: `/hospitalization-epicrises/${epicrisisId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  serviceCategories = {
    /**
     * No description
     *
     * @tags Категории услуг
     * @name ListServiceCategories
     * @summary Получить список категорий услуг
     * @request GET:/service-categories
     * @secure
     */
    listServiceCategories: (query: ListServiceCategoriesParams, params: RequestParams = {}) =>
      this.request<
        {
          data?: ServiceCategoryResource[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/service-categories`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  analytics = {
    /**
     * No description
     *
     * @tags Аналитика
     * @name IndexDoctorsWithReferralCount
     * @summary Получить листинг пользователей с количеством их направлений
     * @request GET:/analytics/doctors-referrals
     * @secure
     */
    indexDoctorsWithReferralCount: (
      query: IndexDoctorsWithReferralCountParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: (UserCreatedAppointmentCountResource & {
            doctor: DoctorNameResource & {
              specialties: SpecialtyResource[];
            };
          })[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/analytics/doctors-referrals`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name ShowDoctorsReferrals
     * @summary Получить записи на которые направлял пользователь
     * @request GET:/analytics/show-doctors-referrals/{userId}
     * @secure
     */
    showDoctorsReferrals: (
      { userId, ...query }: ShowDoctorsReferralsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: (AppointmentResource & {
            doctor: DoctorNameResource & {
              specialties: SpecialtyResource[];
            };
            user: UserNameResource;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/analytics/show-doctors-referrals/${userId}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetEmployeesStatistics
     * @summary Получить статистику по сотрудникам
     * @request GET:/analytics/employees/statistics
     * @secure
     */
    getEmployeesStatistics: (params: RequestParams = {}) =>
      this.request<
        {
          /** @min 0 */
          total_count: number;
          /** @min 0 */
          family_doctor_count: number;
          /** @min 0 */
          narrow_specialist_count: number;
          /** @min 0 */
          nurse_count: number;
          /** @min 0 */
          registry_count: number;
          /** @min 0 */
          administrative_staff_count: number;
        },
        any
      >({
        path: `/analytics/employees/statistics`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetAppointmentsStatistics
     * @summary Получить статистику по приемам для клиники
     * @request GET:/analytics/appointments/statistics
     * @secure
     */
    getAppointmentsStatistics: (
      query: GetAppointmentsStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @min 0 */
          total_appointment_count: number;
          /** @min 0 */
          prepare_appointment_count: number;
          /** @min 0 */
          waiting_prepare_appointment_count: number;
          /** @min 0 */
          family_doctor_appointment_count: number;
          /** @min 0 */
          nurse_appointment_count: number;
          /** @min 0 */
          narrow_specialist_appointment_count: number;
          /** @min 0 */
          man_appointment_count: number;
          /** @min 0 */
          woman_specialist_appointment_count: number;
        },
        any
      >({
        path: `/analytics/appointments/statistics`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetAppointmentCountByGender
     * @summary Получить количество приемов по дням с разделением по полу
     * @request GET:/analytics/appointments/count-by-gender
     * @secure
     */
    getAppointmentCountByGender: (
      query: GetAppointmentCountByGenderParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @format date */
          date: string;
          /** @min 0 */
          count_man: number;
          /** @min 0 */
          count_woman: number;
        }[],
        any
      >({
        path: `/analytics/appointments/count-by-gender`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetAppointmentCountOnTime
     * @summary Получить количество приемов по дням назначенных на время
     * @request GET:/analytics/appointments/count-on-time
     * @secure
     */
    getAppointmentCountOnTime: (
      query: GetAppointmentCountOnTimeParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @format date */
          date: string;
          /** @min 0 */
          all_count: number;
          /** @min 0 */
          canceled_count: number;
        }[],
        any
      >({
        path: `/analytics/appointments/count-on-time`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetAppointmentCountByAge
     * @summary Получить количество приемов по дням с разделением по возрасту
     * @request GET:/analytics/appointments/count-by-age
     * @secure
     */
    getAppointmentCountByAge: (query: GetAppointmentCountByAgeParams, params: RequestParams = {}) =>
      this.request<
        {
          range: string;
          /** @min 0 */
          count_man: number;
          /** @min 0 */
          count_woman: number;
        }[],
        any
      >({
        path: `/analytics/appointments/count-by-age`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetAppointmentCountByReason
     * @summary Получить количество приемов по причине обращения
     * @request GET:/analytics/appointments/count-by-reason
     * @secure
     */
    getAppointmentCountByReason: (
      query: GetAppointmentCountByReasonParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @min 0 */
          disease_count: number;
          /** @min 0 */
          medical_examination_count: number;
        },
        any
      >({
        path: `/analytics/appointments/count-by-reason`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name ListDiseaseCodesWithCount
     * @summary Получить количество поставленных диагнозов
     * @request GET:/analytics/disease-codes
     * @secure
     */
    listDiseaseCodesWithCount: (
      query: ListDiseaseCodesWithCountParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          code: string;
          title: string;
          /** @min 0 */
          count: number;
        }[],
        any
      >({
        path: `/analytics/disease-codes`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetDiseaseCodesCount
     * @summary Получить количество поставленных диагнозов
     * @request GET:/analytics/disease-codes/count
     * @secure
     */
    getDiseaseCodesCount: (query: GetDiseaseCodesCountParams, params: RequestParams = {}) =>
      this.request<
        {
          /** @min 0 */
          count_man: number;
          /** @min 0 */
          count_woman: number;
          /** @min 0 */
          count_children: number;
        },
        any
      >({
        path: `/analytics/disease-codes/count`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetDiseaseCodesCountByAge
     * @summary Получить количество поставленных диагнозов в разрезе возрастов
     * @request GET:/analytics/disease-codes/count-by-age
     * @secure
     */
    getDiseaseCodesCountByAge: (
      query: GetDiseaseCodesCountByAgeParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          range: string;
          count: number;
        }[],
        any
      >({
        path: `/analytics/disease-codes/count-by-age`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetDiseaseCodesCountByGenderByAge
     * @summary Получить количество поставленных диагнозов в разрезе возрастов по полу
     * @request GET:/analytics/disease-codes/count-by-gender-by-age
     * @secure
     */
    getDiseaseCodesCountByGenderByAge: (
      query: GetDiseaseCodesCountByGenderByAgeParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          range: string;
          /** @min 0 */
          count_man: number;
          /** @min 0 */
          count_woman: number;
        }[],
        any
      >({
        path: `/analytics/disease-codes/count-by-gender-by-age`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetDiseaseCodesCountByGender
     * @summary Получить количество поставленных диагнозов по полу
     * @request GET:/analytics/disease-codes/count-by-gender
     * @secure
     */
    getDiseaseCodesCountByGender: (
      query: GetDiseaseCodesCountByGenderParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @min 0 */
          count_man: number;
          /** @min 0 */
          count_woman: number;
        },
        any
      >({
        path: `/analytics/disease-codes/count-by-gender`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name ListDoctorsWithAppointmentsCount
     * @summary Получить список врачей с кол-во их приемов
     * @request GET:/analytics/doctors/with-appointments-count
     * @secure
     */
    listDoctorsWithAppointmentsCount: (
      query: ListDoctorsWithAppointmentsCountParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: (DoctorWithCountAppointmentsResource & {
            specialties: SpecialtyResource[];
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/analytics/doctors/with-appointments-count`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name ListSpecialtiesWithAppointmentsCount
     * @summary Получить специальности со статистикой
     * @request GET:/analytics/specialties/with-appointments-count
     * @secure
     */
    listSpecialtiesWithAppointmentsCount: (
      query: ListSpecialtiesWithAppointmentsCountParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: SpecialtiesWithAppointmentsCountResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        any
      >({
        path: `/analytics/specialties/with-appointments-count`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetAttachmentsStatistics
     * @summary Получить статистику по прикреплениям
     * @request GET:/analytics/attachments/statistics
     * @secure
     */
    getAttachmentsStatistics: (params: RequestParams = {}) =>
      this.request<
        {
          /** @min 0 */
          total_count: number;
          /** @min 0 */
          count_man: number;
          /** @min 0 */
          count_woman: number;
          /** @min 0 */
          count_newborns: number;
          /** @min 0 */
          count_pregnancies: number;
          /** @min 0 */
          count_disabilities: number;
          /** @min 0 */
          count_dispensaries: number;
          /** @min 0 */
          count_fourth_group: number;
        },
        any
      >({
        path: `/analytics/attachments/statistics`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetAttachmentsCountByAge
     * @summary Получить статистику по прикреплениям в разрезе возраста
     * @request GET:/analytics/attachments/count-by-age
     * @secure
     */
    getAttachmentsCountByAge: (params: RequestParams = {}) =>
      this.request<
        {
          range: string;
          /** @min 0 */
          count_man: number;
          /** @min 0 */
          count_woman: number;
        }[],
        any
      >({
        path: `/analytics/attachments/count-by-age`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetCvdScreeningCompletionStatistics
     * @summary Получить статистику по прохождению опросника ССЗ
     * @request GET:/analytics/screenings/cvd/completion-statistics
     * @secure
     */
    getCvdScreeningCompletionStatistics: (
      query: GetCvdScreeningCompletionStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          filled: number;
          not_filled: number;
        },
        any
      >({
        path: `/analytics/screenings/cvd/completion-statistics`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetCvdScreeningRiskGroupStatistics
     * @summary Получить статистику по группам риска ССЗ
     * @request GET:/analytics/screenings/cvd/risk-group-statistics
     * @secure
     */
    getCvdScreeningRiskGroupStatistics: (
      query: GetCvdScreeningRiskGroupStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          risk_group_total: {
            /** @min 0 */
            low: number;
            /** @min 0 */
            moderate: number;
            /** @min 0 */
            medium: number;
            /** @min 0 */
            high: number;
            /** @min 0 */
            very_high: number;
          };
          risk_group_by_gender: {
            man: {
              /** @min 0 */
              low: number;
              /** @min 0 */
              moderate: number;
              /** @min 0 */
              medium: number;
              /** @min 0 */
              high: number;
              /** @min 0 */
              very_high: number;
            };
            woman: {
              /** @min 0 */
              low: number;
              /** @min 0 */
              moderate: number;
              /** @min 0 */
              medium: number;
              /** @min 0 */
              high: number;
              /** @min 0 */
              very_high: number;
            };
          };
        },
        any
      >({
        path: `/analytics/screenings/cvd/risk-group-statistics`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetCvdScreeningCompletionByDate
     * @summary Получить количество пройденных скринингов ССЗ по датам
     * @request GET:/analytics/screenings/cvd/completion-by-date
     * @secure
     */
    getCvdScreeningCompletionByDate: (
      query: GetCvdScreeningCompletionByDateParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @format date */
          date: string;
          /** @min 0 */
          count: number;
        }[],
        any
      >({
        path: `/analytics/screenings/cvd/completion-by-date`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetEndocrineScreeningCompletionStatistics
     * @summary Получить статистику по прохождению опросника на диабет
     * @request GET:/analytics/screenings/endocrine/completion-statistics
     * @secure
     */
    getEndocrineScreeningCompletionStatistics: (
      query: GetEndocrineScreeningCompletionStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          filled: number;
          not_filled: number;
        },
        any
      >({
        path: `/analytics/screenings/endocrine/completion-statistics`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetEndocrineScreeningRiskGroupStatistics
     * @summary Получить статистику по группам риска диабета
     * @request GET:/analytics/screenings/endocrine/risk-group-statistics
     * @secure
     */
    getEndocrineScreeningRiskGroupStatistics: (
      query: GetEndocrineScreeningRiskGroupStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          risk_group_total: {
            /** @min 0 */
            low: number;
            /** @min 0 */
            medium: number;
            /** @min 0 */
            high: number;
            /** @min 0 */
            very_high: number;
          };
          risk_group_by_gender: {
            man: {
              /** @min 0 */
              low: number;
              /** @min 0 */
              medium: number;
              /** @min 0 */
              high: number;
              /** @min 0 */
              very_high: number;
            };
            woman: {
              /** @min 0 */
              low: number;
              /** @min 0 */
              medium: number;
              /** @min 0 */
              high: number;
              /** @min 0 */
              very_high: number;
            };
          };
        },
        any
      >({
        path: `/analytics/screenings/endocrine/risk-group-statistics`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Аналитика
     * @name GetEndocrineScreeningCompletionByDate
     * @summary Получить количество пройденных скринингов на диабет по датам
     * @request GET:/analytics/screenings/endocrine/completion-by-date
     * @secure
     */
    getEndocrineScreeningCompletionByDate: (
      query: GetEndocrineScreeningCompletionByDateParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @format date */
          date: string;
          /** @min 0 */
          count: number;
        }[],
        any
      >({
        path: `/analytics/screenings/endocrine/completion-by-date`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  analyses = {
    /**
     * No description
     *
     * @tags Анализы
     * @name ListAnalyses
     * @summary Получить список анализов которые использует клиника
     * @request GET:/analyses
     * @secure
     */
    listAnalyses: (query: ListAnalysesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: AnalysisWithOrderResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/analyses`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Анализы
     * @name UpdateAnalysisOrder
     * @summary Обновить порядок анализа
     * @request PUT:/analyses/{analysisId}/order
     * @secure
     */
    updateAnalysisOrder: (
      analysisId: number,
      data: UpdateAnalysisOrderFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: AnalysisWithOrderResource;
        },
        ErrorResource
      >({
        path: `/analyses/${analysisId}/order`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Анализы
     * @name UpdateAnalysisActive
     * @summary Активировать или деактивировать анализ
     * @request PUT:/analyses/{analysisId}/active
     * @secure
     */
    updateAnalysisActive: (
      analysisId: number,
      data: UpdateAnalysisActiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: AnalysisWithOrderResource;
        },
        ErrorResource
      >({
        path: `/analyses/${analysisId}/active`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  medicalCases = {
    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name CreateMedicalCase
     * @summary Создать эпизод обращения
     * @request POST:/medical-cases
     * @secure
     */
    createMedicalCase: (data: CreateMedicalCaseFormRequest, params: RequestParams = {}) =>
      this.request<
        {
          data: MedicalCaseResource;
        },
        ErrorResource
      >({
        path: `/medical-cases`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name GetMedicalCase
     * @summary Получить эпизод обращения
     * @request GET:/medical-cases/{medicalCase}
     * @secure
     */
    getMedicalCase: (medicalCase: number, params: RequestParams = {}) =>
      this.request<
        {
          data: MedicalCaseWithContentResource & {
            appointments: (MedicalCaseAppointmentResource & {
              appointment_data?: AppointmentDataResource;
              doctor: DoctorResource & {
                specialties: SpecialtyResource[];
              };
            })[];
          };
        },
        any
      >({
        path: `/medical-cases/${medicalCase}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name UpdateMedicalCase
     * @summary Обновить эпизод обращения
     * @request PUT:/medical-cases/{medicalCase}
     * @secure
     */
    updateMedicalCase: (
      medicalCase: number,
      data: UpdateMedicalCaseFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: MedicalCaseResource;
        },
        ErrorResource
      >({
        path: `/medical-cases/${medicalCase}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name AttachAppointmentToMedicalCase
     * @summary Привязать приём к эпизоду обращения
     * @request PUT:/medical-cases/{medicalCase}/attach-appointment
     * @secure
     */
    attachAppointmentToMedicalCase: (
      medicalCase: number,
      data: AttachAppointmentToMedicalCaseFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<AttachAppointmentToMedicalCaseData, ErrorResource>({
        path: `/medical-cases/${medicalCase}/attach-appointment`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name ListAnalysesMedicalCase
     * @summary Получить список анализов привязанных к эпизоду обращения
     * @request GET:/medical-cases/{medicalCase}/orders
     * @secure
     */
    listAnalysesMedicalCase: (medicalCase: number, params: RequestParams = {}) =>
      this.request<
        {
          data: (OrderResource & {
            biomaterials: (BiomaterialResource & {
              abstract_biomaterial: AbstractBiomaterialResource;
            })[];
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/medical-cases/${medicalCase}/orders`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name ListMedicalCaseSickLeaves
     * @summary Получить список больничных привязанных к эпизоду обращения
     * @request GET:/medical-cases/{medicalCase}/sick-leaves
     * @secure
     */
    listMedicalCaseSickLeaves: (medicalCase: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: SickLeaveListResource[];
        },
        ErrorResource
      >({
        path: `/medical-cases/${medicalCase}/sick-leaves`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name ListMedicalCaseTreatmentCourses
     * @summary Получить список курсов лечения привязанных к эпизоду обращения
     * @request GET:/medical-cases/{medicalCase}/treatment-courses
     * @secure
     */
    listMedicalCaseTreatmentCourses: (medicalCase: number, params: RequestParams = {}) =>
      this.request<
        {
          data: (TreatmentCourseResource & {
            doctor: DoctorNameResource;
            clinic: ClinicTitleResponse;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/medical-cases/${medicalCase}/treatment-courses`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name ListMedicalCaseDiseaseCodes
     * @summary Получить список диагнозов для эпизода обращения
     * @request GET:/medical-cases/{medicalCase}/disease-codes
     * @secure
     */
    listMedicalCaseDiseaseCodes: (medicalCase: number, params: RequestParams = {}) =>
      this.request<
        {
          data: MedicalCaseDiseaseCodeResource[];
        },
        ErrorResource
      >({
        path: `/medical-cases/${medicalCase}/disease-codes`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name AttachDiseaseCodeToMedicalCase
     * @summary Прикрепить диагноз к эпизоду
     * @request POST:/medical-cases/{medicalCase}/disease-codes
     * @secure
     */
    attachDiseaseCodeToMedicalCase: (
      medicalCase: number,
      data: AttachDiseaseCodeToMedicalCaseFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: MedicalCaseDiseaseCodeResource[];
        },
        ErrorResource
      >({
        path: `/medical-cases/${medicalCase}/disease-codes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name DetachDiseaseCodeFromMedicalCase
     * @summary Открепить диагноз от эпизода
     * @request DELETE:/medical-cases/{medicalCase}/disease-codes
     * @secure
     */
    detachDiseaseCodeFromMedicalCase: (
      medicalCase: number,
      data: DetachDiseaseCodeFromMedicalCaseFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: MedicalCaseDiseaseCodeResource[];
        },
        ErrorResource
      >({
        path: `/medical-cases/${medicalCase}/disease-codes`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name ListMedicalCaseDiseaseCodesSelect
     * @summary Получить для селекта список диагнозов для эпизода обращения
     * @request GET:/medical-cases/{medicalCase}/disease-codes/select
     * @secure
     */
    listMedicalCaseDiseaseCodesSelect: (medicalCase: number, params: RequestParams = {}) =>
      this.request<
        {
          data: AppointmentDiseaseCodeForSelectResource[];
        },
        ErrorResource
      >({
        path: `/medical-cases/${medicalCase}/disease-codes/select`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения
     * @name GetMedicalCaseFreeformDiagnoses
     * @summary Получить диагнозы, заполненные вручную в приёмах
     * @request GET:/medical-cases/{medicalCase}/freeform-diagnoses
     * @secure
     */
    getMedicalCaseFreeformDiagnoses: (medicalCase: number, params: RequestParams = {}) =>
      this.request<
        {
          data: {
            preliminary: {
              main: string | null;
              background: string | null;
              additional: string | null;
              critical: string | null;
              competing: string | null;
            };
            final: {
              main: string | null;
              background: string | null;
              additional: string | null;
              critical: string | null;
              competing: string | null;
            };
          };
        },
        ErrorResource
      >({
        path: `/medical-cases/${medicalCase}/freeform-diagnoses`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Пациенты, Приемы
     * @name GetPatientSurveyPlanAppointments
     * @summary Получить планы обследования
     * @request GET:/medical-cases/{medicalCase}/survey-plan
     * @secure
     */
    getPatientSurveyPlanAppointments: (medicalCase: number, params: RequestParams = {}) =>
      this.request<
        {
          data: AppointmentWithSurveyPlanResource[];
        },
        void
      >({
        path: `/medical-cases/${medicalCase}/survey-plan`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Эпизод обращения, Рецепты
     * @name ListMedicalCasePrescriptions
     * @summary Получить рецепты
     * @request GET:/medical-cases/{medicalCase}/prescriptions
     * @secure
     */
    listMedicalCasePrescriptions: (medicalCase: number, params: RequestParams = {}) =>
      this.request<
        {
          data: (PrescriptionResource & {
            appointment?: AppointmentResource & {
              disease_codes?: DiseaseCodeResource[];
            };
          })[];
        },
        void
      >({
        path: `/medical-cases/${medicalCase}/prescriptions`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  dispensaries = {
    /**
     * No description
     *
     * @tags Д-учет
     * @name ListDispensaries
     * @summary Получить д-учеты пациента
     * @request GET:/dispensaries
     * @secure
     */
    listDispensaries: (query: ListDispensariesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (NewDispensaryResource & {
            creator: CreatorResource;
            disease_code: DiseaseCodeResource;
          })[];
        },
        ErrorResource
      >({
        path: `/dispensaries`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  treatmentDiaries = {
    /**
     * No description
     *
     * @tags Госпитализация, Дневник лечения
     * @name GetTreatmentDiaries
     * @summary Получить дневник лечения
     * @request GET:/treatment-diaries
     * @secure
     */
    getTreatmentDiaries: (query: GetTreatmentDiariesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (TreatmentDiaryResource & {
            creator: CreatorResource;
            inspection_card_template?: InspectionCardResource;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        void
      >({
        path: `/treatment-diaries`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация, Дневник лечения
     * @name CreateTreatmentDiary
     * @summary Создать дневник лечения
     * @request POST:/treatment-diaries
     * @secure
     */
    createTreatmentDiary: (data: CreateTreatmentDiaryRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: TreatmentDiaryResource & {
            creator: CreatorResource;
            inspection_card_template?: InspectionCardResource;
          };
        },
        any
      >({
        path: `/treatment-diaries`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация, Дневник лечения
     * @name GetTreatmentDiary
     * @summary Получить дневник лечения
     * @request GET:/treatment-diaries/{treatmentDiaryId}
     * @secure
     */
    getTreatmentDiary: (treatmentDiaryId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: TreatmentDiaryResource & {
            creator: CreatorResource;
            inspection_card_template?: InspectionCardResource;
          };
        },
        any
      >({
        path: `/treatment-diaries/${treatmentDiaryId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Госпитализация, Дневник лечения
     * @name UpdateTreatmentDiary
     * @summary Редактировать дневник лечения
     * @request PATCH:/treatment-diaries/{treatmentDiaryId}
     * @secure
     */
    updateTreatmentDiary: (
      treatmentDiaryId: number,
      data: UpdateTreatmentDiaryRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: TreatmentDiaryResource & {
            creator: CreatorResource;
            inspection_card_template?: InspectionCardResource;
          };
        },
        ErrorResource
      >({
        path: `/treatment-diaries/${treatmentDiaryId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  clinicalDiagnoses = {
    /**
     * No description
     *
     * @tags Клинические диагнозы
     * @name ListClinicalDiagnoses
     * @summary Получить клинические диагнозы пациента
     * @request GET:/clinical-diagnoses
     * @secure
     */
    listClinicalDiagnoses: (query: ListClinicalDiagnosesParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (ClinicalDiagnosisResource & {
            creator: CreatorResource;
          })[];
        },
        any
      >({
        path: `/clinical-diagnoses`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Клинические диагнозы
     * @name CreateClinicalDiagnosis
     * @summary Создать клинический диагноз для пациента
     * @request POST:/clinical-diagnoses
     * @secure
     */
    createClinicalDiagnosis: (
      data: CreateClinicalDiagnosisFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: ClinicalDiagnosisResource & {
            creator: CreatorResource;
          };
        },
        any
      >({
        path: `/clinical-diagnoses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Клинические диагнозы
     * @name StoreClinicalDiagnoses
     * @summary Сохранить список клинических диагнозов для пациента
     * @request POST:/clinical-diagnoses/store-many
     * @secure
     */
    storeClinicalDiagnoses: (data: StoreClinicalDiagnosesRequest, params: RequestParams = {}) =>
      this.request<
        {
          data: (ClinicalDiagnosisResource & {
            creator: CreatorResource;
          })[];
        },
        any
      >({
        path: `/clinical-diagnoses/store-many`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Клинические диагнозы
     * @name ListClinicalDiagnosesByType
     * @summary Получить клинические диагнозы пациента, сгруппированные по типу
     * @request GET:/clinical-diagnoses/by-type
     * @secure
     */
    listClinicalDiagnosesByType: (
      query: ListClinicalDiagnosesByTypeParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          type: ClinicalDiagnosisTypeEnum;
          diagnoses: ClinicalDiagnosisResource[];
        }[],
        any
      >({
        path: `/clinical-diagnoses/by-type`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Клинические диагнозы
     * @name DeregistrationClinicalDiagnosis
     * @summary Снять с учета клинический диагноз для пациента
     * @request PUT:/clinical-diagnoses/{clinicalDiagnosis}/deregistration
     * @secure
     */
    deregistrationClinicalDiagnosis: (clinicalDiagnosis: number, params: RequestParams = {}) =>
      this.request<
        {
          data: ClinicalDiagnosisResource & {
            creator: CreatorResource;
          };
        },
        any
      >({
        path: `/clinical-diagnoses/${clinicalDiagnosis}/deregistration`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Клинические диагнозы
     * @name ListClinicalDiagnosesForSelect
     * @summary Получить все клинические диагнозы пациента для селектов
     * @request GET:/clinical-diagnoses/{patient}/select
     * @secure
     */
    listClinicalDiagnosesForSelect: (patient: number, params: RequestParams = {}) =>
      this.request<
        {
          data: ClinicalDiagnosisForSelectResource[];
        },
        any
      >({
        path: `/clinical-diagnoses/${patient}/select`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  documents = {
    /**
     * No description
     *
     * @tags Документы
     * @name ExportF39
     * @summary Получить отчёта 039
     * @request GET:/documents/f39/export
     * @secure
     */
    exportF39: (query: ExportF39Params, params: RequestParams = {}) =>
      this.request<ExportF39Data, any>({
        path: `/documents/f39/export`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  employees = {
    /**
     * No description
     *
     * @tags Cотрудники
     * @name UpdateEmployeeStatus
     * @summary активация и деактивация сотрудника
     * @request POST:/employees/{userId}/change-status
     * @secure
     */
    updateEmployeeStatus: (
      userId: number,
      data: UpdateEmployeeStatusRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<UpdateEmployeeStatusData, ErrorResource>({
        path: `/employees/${userId}/change-status`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cотрудники
     * @name SearchEmployeeByPinflOrPassport
     * @summary Поиск сотрудика по pinfl или по медкарте/паспортным данным
     * @request GET:/employees/search
     * @secure
     */
    searchEmployeeByPinflOrPassport: (
      query: SearchEmployeeByPinflOrPassportParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: SearchEmployeeResource;
        },
        ErrorResource
      >({
        path: `/employees/search`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cотрудники
     * @name CreateEmployee
     * @summary Создание сотрудника
     * @request POST:/employees
     * @secure
     */
    createEmployee: (data: CreateEmployeeRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: EmployeeResource;
        },
        ErrorResource
      >({
        path: `/employees`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cотрудники
     * @name GetEmployee
     * @summary Получение сотрудника
     * @request GET:/employees/{userId}
     * @secure
     */
    getEmployee: (userId: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: EmployeeResource;
        },
        ErrorResource
      >({
        path: `/employees/${userId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cотрудники
     * @name EditEmployee
     * @summary Редактирование сотрудника
     * @request PUT:/employees/{userId}
     * @secure
     */
    editEmployee: (userId: number, data: UpdateEmployeeRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: EmployeeResource;
        },
        ErrorResource
      >({
        path: `/employees/${userId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags График отсутствия
     * @name ListVacations
     * @summary Получить График отсутствия
     * @request GET:/employees/{userId}/vacations
     * @secure
     */
    listVacations: ({ userId, ...query }: ListVacationsParams, params: RequestParams = {}) =>
      this.request<
        {
          data: (VacationResource & {
            user: UserNameResource;
          })[];
          /** Метаданные для ответов с пагинацией */
          meta?: PaginationMeta;
        },
        void
      >({
        path: `/employees/${userId}/vacations`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags График отсутствия
     * @name CreateVacation
     * @summary Создать График отсутствия
     * @request POST:/employees/vacations
     * @secure
     */
    createVacation: (data: CreateVacationRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: VacationResource & {
            user: UserNameResource;
          };
        },
        any
      >({
        path: `/employees/vacations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags График отсутствия
     * @name UpdateVacation
     * @summary Редактировать График отсутствия
     * @request PUT:/employees/vacations/{vacationId}
     * @secure
     */
    updateVacation: (
      vacationId: number,
      data: UpdateVacationRequestBody,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: VacationResource & {
            user: UserNameResource;
          };
        },
        ErrorResource
      >({
        path: `/employees/vacations/${vacationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags График отсутствия
     * @name DeleteVacation
     * @summary Удалить График отсутствия
     * @request DELETE:/employees/vacations/{vacationId}
     * @secure
     */
    deleteVacation: (vacationId: number, params: RequestParams = {}) =>
      this.request<DeleteVacationData, ErrorResource>({
        path: `/employees/vacations/${vacationId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags График работы
     * @name SetWorkingTime
     * @summary Редактировать График работы
     * @request PUT:/employees/{userId}/working-times
     * @secure
     */
    setWorkingTime: (userId: number, data: SetWorkingTimeRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: WorkingTimeResource;
        },
        ErrorResource
      >({
        path: `/employees/${userId}/working-times`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  birthProtocol = {
    /**
     * No description
     *
     * @tags Протокол родов
     * @name CreateBirthProtocol
     * @summary Создание протокола родов
     * @request POST:/birth-protocol
     * @secure
     */
    createBirthProtocol: (data: CreateBirthProtocolRequestBody, params: RequestParams = {}) =>
      this.request<
        {
          data: (BirthProtocolResource & {
            newborn_children: NewbornChildrenResource[] | null;
          })[];
        },
        any
      >({
        path: `/birth-protocol`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Протокол родов
     * @name GetBirthProtocolResponse
     * @summary Получить протокол родов и список новорожденных
     * @request GET:/birth-protocol/{hospitalizationId}
     * @secure
     */
    getBirthProtocolResponse: (hospitalizationId: number, params: RequestParams = {}) =>
      this.request<
        {
          data: BirthProtocolResource & {
            newborn_children: NewbornChildrenResource[] | null;
          };
        },
        any
      >({
        path: `/birth-protocol/${hospitalizationId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  motherChild = {
    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildListBlocks
     * @summary Получить список блоков в разделе мать и дитя
     * @request GET:/mother-child/{pregnancyId}/blocks
     * @secure
     */
    motherChildListBlocks: (pregnancyId: number, params: RequestParams = {}) =>
      this.request<
        {
          blocks: {
            name: MotherChildBlockEnum | MotherChildPregnancyTargetWeekEnum | number;
            /** @format date-time */
            updated_at?: string | null;
            children?: {
              id?: number | null;
              name?: string | number;
              /** @format date-time */
              updated_at?: string | null;
            }[];
          }[];
        },
        any
      >({
        path: `/mother-child/${pregnancyId}/blocks`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildCreateOrUpdateGynecologistExamination
     * @summary Создать или обновить осмотр гинеколога
     * @request POST:/mother-child/{pregnancyId}/gynecologist-examinations
     * @secure
     */
    motherChildCreateOrUpdateGynecologistExamination: (
      pregnancyId: number,
      data: MotherChildCreateOrUpdateGynecologistExaminationRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          pregnancy_complications?: DiseaseCodeResource;
          diagnosis: DiseaseCodeResource;
        } & MotherChildGynecologistExaminationResource,
        ErrorResource
      >({
        path: `/mother-child/${pregnancyId}/gynecologist-examinations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildGetGynecologistExamination
     * @summary Получить информацию об осмотре гинеколога
     * @request GET:/mother-child/{pregnancyId}/gynecologist-examinations
     * @secure
     */
    motherChildGetGynecologistExamination: (pregnancyId: number, params: RequestParams = {}) =>
      this.request<
        {
          pregnancy_complications?: DiseaseCodeResource;
          diagnosis: DiseaseCodeResource;
        } & MotherChildGynecologistExaminationResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/gynecologist-examinations`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildCreatePreviousPregnancy
     * @summary Создать запись предыдущей беременности
     * @request POST:/mother-child/{pregnancyId}/previous-pregnancies
     * @secure
     */
    motherChildCreatePreviousPregnancy: (
      pregnancyId: number,
      data: MotherChildCreateOrUpdatePreviousPregnancyRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** ID прошлой беременности */
          id: number;
          complications?: DiseaseCodeResource;
        } & MotherChildPreviousPregnancyResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/previous-pregnancies`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildUpdatePreviousPregnancy
     * @summary Обновить запись предыдущей беременности
     * @request PUT:/mother-child/{pregnancyId}/previous-pregnancies/{previousPregnancyId}
     * @secure
     */
    motherChildUpdatePreviousPregnancy: (
      pregnancyId: number,
      previousPregnancyId: number,
      data: MotherChildCreateOrUpdatePreviousPregnancyRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** ID прошлой беременности */
          id: number;
          complications?: DiseaseCodeResource;
        } & MotherChildPreviousPregnancyResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/previous-pregnancies/${previousPregnancyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildGetPreviousPregnancy
     * @summary Получить запись предыдущей беременности
     * @request GET:/mother-child/{pregnancyId}/previous-pregnancies/{previousPregnancyId}
     * @secure
     */
    motherChildGetPreviousPregnancy: (
      pregnancyId: number,
      previousPregnancyId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** ID прошлой беременности */
          id: number;
          complications?: DiseaseCodeResource;
        } & MotherChildPreviousPregnancyResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/previous-pregnancies/${previousPregnancyId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildCreateOrUpdateFamilySexualHistory
     * @summary Создать или обновить запись семейной и сексуальной истории
     * @request POST:/mother-child/{pregnancyId}/family-sexual-history
     * @secure
     */
    motherChildCreateOrUpdateFamilySexualHistory: (
      pregnancyId: number,
      data: MotherChildCreateOrUpdateFamilySexualHistoryRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * Unique identifier for the record
           * @format int64
           */
          id: number;
          gynecological_diseases?: DiseaseCodeResource;
        } & MotherChildFamilySexualHistoryResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/family-sexual-history`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildGetFamilySexualHistory
     * @summary Получить запись семейно и сексуальной истории
     * @request GET:/mother-child/{pregnancyId}/family-sexual-history
     * @secure
     */
    motherChildGetFamilySexualHistory: (pregnancyId: number, params: RequestParams = {}) =>
      this.request<
        {
          /**
           * Unique identifier for the record
           * @format int64
           */
          id: number;
          gynecological_diseases?: DiseaseCodeResource;
        } & MotherChildFamilySexualHistoryResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/family-sexual-history`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildCreateOrUpdateFirstPregnancyExamination
     * @summary Создать или обновить запись об обследовании беременной
     * @request POST:/mother-child/{pregnancyId}/pregnancy-examinations
     * @secure
     */
    motherChildCreateOrUpdateFirstPregnancyExamination: (
      pregnancyId: number,
      data: MotherChildCreateOrUpdateFirstPregnancyExaminationRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * Unique identifier for the record
           * @format int64
           */
          id: number;
        } & MotherChildFirstPregnancyExaminationResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/pregnancy-examinations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildGetFirstPregnancyExamination
     * @summary Получить запись об обследовании беременной
     * @request GET:/mother-child/{pregnancyId}/pregnancy-examinations
     * @secure
     */
    motherChildGetFirstPregnancyExamination: (pregnancyId: number, params: RequestParams = {}) =>
      this.request<
        {
          /**
           * Unique identifier for the record
           * @format int64
           */
          id: number;
        } & MotherChildFirstPregnancyExaminationResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/pregnancy-examinations`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildCreateOrUpdateFirstGynecologicalExamination
     * @summary Создать или обновить запись первого гинекологического обследования
     * @request POST:/mother-child/{pregnancyId}/gynecological-examinations
     * @secure
     */
    motherChildCreateOrUpdateFirstGynecologicalExamination: (
      pregnancyId: number,
      data: MotherChildCreateOrUpdateFirstGynecologicalExaminationRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * Unique identifier for the record
           * @format int64
           */
          id: number;
          diagnosis_icd10?: DiseaseCodeResource;
        } & MotherChildFirstGynecologicalExaminationResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/gynecological-examinations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildGetFirstGynecologicalExamination
     * @summary Получить запись первого гинекологического обследования
     * @request GET:/mother-child/{pregnancyId}/gynecological-examinations
     * @secure
     */
    motherChildGetFirstGynecologicalExamination: (
      pregnancyId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * Unique identifier for the record
           * @format int64
           */
          id: number;
          diagnosis_icd10?: DiseaseCodeResource;
        } & MotherChildFirstGynecologicalExaminationResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/gynecological-examinations`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildCreateOrUpdatePregnancyLaboratoryAnalysis
     * @summary Создать или обновить анализы
     * @request POST:/mother-child/{pregnancyId}/analyses
     * @secure
     */
    motherChildCreateOrUpdatePregnancyLaboratoryAnalysis: (
      pregnancyId: number,
      data: MotherChildCreateOrUpdatePregnancyLaboratoryAnalysisPayload,
      params: RequestParams = {}
    ) =>
      this.request<MotherChildCreateOrUpdatePregnancyLaboratoryAnalysisData, any>({
        path: `/mother-child/${pregnancyId}/analyses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildGetPregnancyLaboratoryAnalysis
     * @summary Получить анализы
     * @request GET:/mother-child/{pregnancyId}/analyses
     * @secure
     */
    motherChildGetPregnancyLaboratoryAnalysis: (pregnancyId: number, params: RequestParams = {}) =>
      this.request<MotherChildGetPregnancyLaboratoryAnalysisData, any>({
        path: `/mother-child/${pregnancyId}/analyses`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildCreatePregnancyProgress
     * @summary Создание записи прогресс беременности с конкретной неделей
     * @request POST:/mother-child/{pregnancyId}/pregnancy-progress
     * @secure
     */
    motherChildCreatePregnancyProgress: (
      pregnancyId: number,
      data: MotherChildCreateOrUpdatePregnancyProgressRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * Unique identifier for the record
           * @format int64
           */
          id: number;
          icd10_diagnosis?: DiseaseCodeResource;
          functional_icd10_diagnosis?: DiseaseCodeResource;
        } & MotherChildPregnancyProgressResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/pregnancy-progress`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildUpdatePregnancyProgress
     * @summary Обновление записи прогресс беременности с конкретной неделей
     * @request PUT:/mother-child/{pregnancyId}/pregnancy-progress/{pregnancyProgressId}
     * @secure
     */
    motherChildUpdatePregnancyProgress: (
      pregnancyId: number,
      pregnancyProgressId: number,
      data: MotherChildCreateOrUpdatePregnancyProgressRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * Unique identifier for the record
           * @format int64
           */
          id: number;
          icd10_diagnosis?: DiseaseCodeResource;
          functional_icd10_diagnosis?: DiseaseCodeResource;
        } & MotherChildPregnancyProgressResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/pregnancy-progress/${pregnancyProgressId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildGetPregnancyProgress
     * @summary Получение записи прогресс беременности с конкретной неделей
     * @request GET:/mother-child/{pregnancyId}/pregnancy-progress/{pregnancyProgressId}
     * @secure
     */
    motherChildGetPregnancyProgress: (
      pregnancyId: number,
      pregnancyProgressId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /**
           * Unique identifier for the record
           * @format int64
           */
          id: number;
          icd10_diagnosis?: DiseaseCodeResource;
          functional_icd10_diagnosis?: DiseaseCodeResource;
        } & MotherChildPregnancyProgressResource,
        any
      >({
        path: `/mother-child/${pregnancyId}/pregnancy-progress/${pregnancyProgressId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Мать и дитя
     * @name MotherChildUploadPregnancyProgressFiles
     * @summary Загрузить файлы функциональных диагностик для конкретной недели беременности
     * @request POST:/mother-child/{pregnancyId}/pregnancy-progress/{pregnancyProgressId}
     * @secure
     */
    motherChildUploadPregnancyProgressFiles: (
      pregnancyId: number,
      pregnancyProgressId: number,
      data: MotherChildUploadPregnancyProgressFilesPayload,
      params: RequestParams = {}
    ) =>
      this.request<MotherChildUploadPregnancyProgressFilesData, any>({
        path: `/mother-child/${pregnancyId}/pregnancy-progress/${pregnancyProgressId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
  };
  diseaseCodes = {
    /**
     * No description
     *
     * @tags МКБ
     * @name GetDiseaseCodeParentGroup
     * @summary Получить родительскую группу диагноза
     * @request GET:/disease-codes/{diseaseCode}/parent-group
     * @secure
     */
    getDiseaseCodeParentGroup: (diseaseCode: string, params: RequestParams = {}) =>
      this.request<
        {
          data: DiseaseCode;
        },
        any
      >({
        path: `/disease-codes/${diseaseCode}/parent-group`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  products = {
    /**
     * No description
     *
     * @tags Медикаменты
     * @name GetProductsList
     * @summary Получить список медикаментов
     * @request GET:/products
     * @secure
     */
    getProductsList: (query: GetProductsListParams, params: RequestParams = {}) =>
      this.request<
        {
          data: ProductShortResource[];
          /** Метаданные для ответов с пагинацией */
          meta: PaginationMeta;
        },
        ErrorResource
      >({
        path: `/products`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  aiAssistant = {
    /**
     * No description
     *
     * @tags ИИ Ассистент
     * @name AiAssistantProcessCall
     * @summary Обработать обращение к ИИ Ассистенту
     * @request POST:/ai-assistant/calls
     * @secure
     */
    aiAssistantProcessCall: (data: AIAssistantProcessCallRequestBody, params: RequestParams = {}) =>
      this.request<AiAssistantProcessCallData, any>({
        path: `/ai-assistant/calls`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ИИ Ассистент
     * @name AiAssistantRetrieveCallResults
     * @summary Получить результаты обращения к ИИ ассистенту
     * @request GET:/ai-assistant/calls/{callId}
     * @secure
     */
    aiAssistantRetrieveCallResults: (callId: string, params: RequestParams = {}) =>
      this.request<
        {
          results: {
            inspection_card: string;
            diagnosis: string;
            is_disease: boolean;
          };
        },
        any
      >({
        path: `/ai-assistant/calls/${callId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ИИ Ассистент
     * @name AiAssistantSetCompletedAiAssistantTutorialFlag
     * @summary Переключить флаг прошел текущий ли пользователь обучение по ИИ ассистенту
     * @request POST:/ai-assistant/completed-ai-tutorial-flag
     * @secure
     */
    aiAssistantSetCompletedAiAssistantTutorialFlag: (
      data: AiAssistantSetCompletedAiAssistantTutorialFlagPayload,
      params: RequestParams = {}
    ) =>
      this.request<AiAssistantSetCompletedAiAssistantTutorialFlagData, any>({
        path: `/ai-assistant/completed-ai-tutorial-flag`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ИИ Ассистент
     * @name AiAssistantGetCompletedAiAssistantTutorialFlag
     * @summary Получить флаг прошел ли текущий пользователь обучение по ИИ ассистенту
     * @request GET:/ai-assistant/completed-ai-tutorial-flag
     * @secure
     */
    aiAssistantGetCompletedAiAssistantTutorialFlag: (params: RequestParams = {}) =>
      this.request<AiAssistantGetCompletedAiAssistantTutorialFlagData, any>({
        path: `/ai-assistant/completed-ai-tutorial-flag`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
}
